import dayjs from "dayjs";
import { TokenStorage } from "../core/http-axios-react/http/Token";

export const FromStringToDate = (
  dateParam: string,
  startWithYear?: boolean
) => {
  const tempDateString = dateParam.split("T");
  let temp = new Date(tempDateString[0]);
  if (startWithYear) {
    return `${temp.getFullYear()}-${temp.getMonth() + 1}-${temp.getDate()}`;
  }
  return `${temp.getDate()}-${temp.getMonth() + 1}-${temp.getFullYear()}`;
};

export const FromStringToDateTime = (dateParam: string) => {
  if (!dateParam) {
    return "";
  }
  const tempDateString = dateParam.split("T");
  // Handle Split Time
  const tempDateTimeString = tempDateString[1].split(":");
  const H = tempDateTimeString[0];
  const M = tempDateTimeString[1];
  const S = 0;
  // Parse Back to date
  let temp = new Date(`${tempDateString[0]} ${H}:${M}:${S}`);
  return `${temp.getDate().toString().padStart(2, "0")}-${(temp.getMonth() + 1)
    .toString()
    .padStart(
      2,
      "0"
    )}-${temp.getFullYear()}  ${temp.getHours()}:${temp.getMinutes()}`;
};

export const CreditToString = (credit: string) => {
  let temp = credit.split(".")[0];
  temp = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (temp[0] === "-") {
    temp = temp.substr(1, temp.length);
    return `-Rp ${temp}`;
  }
  return `+Rp ${temp}`;
};

export const ToMoneyFormat = (credit: string | number | undefined) => {
  if (!credit) {
    return 0;
  }
  const temp = credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return temp;
};

export const MaskingText = (text: string) => {
  if (!text) {
    return text;
  }
  return `***${text.substring(5)}`;
};

export const GetCurrentDate = (date?: string) => {
  return date ? dayjs(date) : dayjs();
};

export const DownloadBlobAsFile = (source: string) => {
  // Replace 'your_token' with your actual token
  const token = TokenStorage.get();

  // Fetch the file with the token included in the headers
  fetch(source, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      // Create a link element and set its properties
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "file.csv";

      // Append the link to the body and click it
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
): ((...args: Parameters<F>) => void) => {
  let timer: NodeJS.Timeout | null;

  return (...args: Parameters<F>) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, delay);
  };
};

export const getUrlQuery = (path: string) => {
  return new URLSearchParams(path);
};
