import { Box, Modal } from "@mui/material";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import { IProductPreOrderData } from "../ProdukPreOrder.type";
import { useGetProductPreOrderDetail } from "../networks/resolver";
import TableFC from "../../../components/table/Table";
import { ACTION_URL } from "../../../Constant";

interface IModalProductPreOrder {
  product: IProductPreOrderData | null;
  visible: boolean;
  onClose(): void;
}

export default function ModalProductPreOrder({
  product,
  visible,
  onClose,
}: IModalProductPreOrder) {
  const { data: dataRaw, isLoading } = useGetProductPreOrderDetail({
    pre_order_product_id: product?.id || 1,
    enabled: product !== null,
  });
  const data = dataRaw?.data.data;
  const bodyTable = [
    { key: "invoice_number" },
    {
      key: "",
      newComponent: (e: any) => {
        return <Box>{e.pre_order_number || "-"}</Box>;
      },
    },
    { key: "invoice_date" },
  ];

  function handleOnClick(e: any) {
    window.open(`/invoice/pre-order/${e.id}?action=${ACTION_URL.openModal}`);
  }

  return (
    <Modal open={visible} onClose={onClose}>
      <MainContainerModal style={{ maxHeight: 600 }}>
        <MainContainerWrapper>
          <TableFC
            loading={isLoading}
            body={bodyTable}
            data={data?.invoice}
            head={["No", "Nomor Invoice", "Nomor PO", "Tanggal"]}
            showIndex
            perColumns={10}
            onCellClick={(e) => handleOnClick(e)}
          />
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
