import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { INote } from "../note.type";

export const getNotes = async ({
  page,
  due_date,
  keyword,
  status,
  perPage,
}: {
  page: number;
  keyword: string;
  due_date: string;
  status: number;
  perPage: number;
}) => {
  const results = await Http.get<HttpBaseResponse<INote>>({
    url: "/note",
    params: {
      page,
      due_date,
      keyword,
      status,
      perPage,
    },
  });
  return results;
};

export const postNotes = async ({
  due_date,
  message,
  targeted_user,
}: {
  message: string;
  due_date: string;
  targeted_user: number;
}) => {
  const results = await Http.post({
    url: "/note/insert",
    data: {
      status: 1,
      due_date,
      message,
      targeted_user,
    },
  });
  return results;
};

export const updateNotes = async ({
  note_id,
  status,
}: {
  note_id: number;
  status: number;
}) => {
  const results = await Http.post({
    url: "/note/status",
    data: {
      note_id,
      status,
    },
  });
  return results;
};

export const deleteNote = async ({ note_id }: { note_id: number }) => {
  const results = await Http.post({
    url: "/note/delete",
    data: {
      note_id,
    },
  });
  return results;
};
