import { HttpBaseReq } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { IUser, IUserRole } from "../type/user";

export const getUsers = async ({ keyword, page, perPage }: HttpBaseReq) => {
  const results = await Http.get<HttpBaseResponse<IUser>>({
    url: "/user",
    params: {
      keyword,
      page,
      perPage: perPage,
    },
  });
  return results;
};

export const getUserRoles = async () => {
  const results = await Http.get<HttpBaseResponse<IUserRole>>({
    url: "/user/role",
  });
  return results;
};

export const postAddUser = async ({
  name,
  email,
  role,
  password,
}: {
  name: string;
  email: string;
  role: number;
  password: string;
}) => {
  const results = await Http.post<HttpBaseResponse<any>>({
    url: "/user/insert",
    data: {
      name,
      email,
      role,
      password,
    },
  });
  return results;
};

export const patchUser = async ({
  name,
  email,
  role,
  password,
  user_id,
}: {
  name: string;
  email: string;
  role: number;
  password?: string;
  user_id: number;
}) => {
  const results = await Http.post<HttpBaseResponse<any>>({
    url: "/user/update",
    ignoreFalsy: true,
    data: {
      name,
      email,
      role,
      password,
      user_id,
    },
  });
  return results;
};

export const deleteUser = async ({ user_id }: { user_id: number }) => {
  const results = await Http.delete({
    url: "/user/delete",
    useFormMultiPart: false,
    data: {
      user_id,
    },
  });
  return results;
};
