import { Snackbar } from "@mui/material";
import React, { useState } from "react";

interface ToastContextProps {
  show: (message: string) => void;
}

const toastContextDefault: ToastContextProps = {
  show: () => {},
};

export const ToastStateContext =
  React.createContext<ToastContextProps>(toastContextDefault);

const ToastContextFC: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");

  function show(message: string) {
    setMessage(message);
    setVisible(true);
  }

  function handleClose() {
    setVisible(false);
  }

  return (
    <ToastStateContext.Provider value={{ show }}>
      {children}
      <Snackbar
        open={visible}
        autoHideDuration={1500}
        onClose={handleClose}
        message={message}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      />
    </ToastStateContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastStateContext);

export default ToastContextFC;
