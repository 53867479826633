import { useQuery } from "react-query";
import { HttpBaseRequest } from "../../../core/HttpRequest";
import { getProductPreOrder, getProductPreOrderDetail } from "./service";

export const useProductPreOrder = ({
  keyword,
  page,
  perPage,
}: HttpBaseRequest) => {
  return useQuery({
    queryKey: `productPreorder${keyword}${page}${perPage}`,
    queryFn: () => getProductPreOrder({ keyword, page, perPage }),
  });
};

export const useGetProductPreOrderDetail = ({
  pre_order_product_id,
  enabled,
}: {
  pre_order_product_id: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: `productPreOrderDetail${pre_order_product_id}`,
    queryFn: () => getProductPreOrderDetail({ pre_order_product_id }),
    enabled,
  });
};
