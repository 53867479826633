import { AddCircleOutlineRounded } from "@mui/icons-material";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  InputAutocompleteOptions,
  NewAutoComplete,
} from "../../../components/input/input";
import React, { useEffect, useState } from "react";
import { useGetProduct } from "../../produk/network/resolver";
import { useToast } from "../../../context/toast/ToastContext";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";
import Spinner from "../../../components/spinner/Spinner";
import { ToMoneyFormat } from "../../../utils/Helper";

export interface IListProductPrice {
  name: string;
  price: number;
  quantity: number;
  product_id: number;
  stock: number;
  unitType: string;
  input?: number | null;
}

interface IAddedProduct {
  name: string;
  product_id: number;
  price: number;
  quantity: number | null;
  stock: number;
  unitType: string;
  input?: number | null;
}

interface IProductPrice {
  customerTypeId: number;
  invoice?: IListProductPrice[];
  onChange?(item: IAddedProduct[]): void;
  disabled?: boolean;
  type:
    | "Add"
    | "Confirm"
    | "Purchase"
    | "ConfirmPurchase"
    | "FinishPurchase"
    | "ConfirmRekap"
    | "FinishInvoice";
}

export default function ProductPrice({
  invoice,
  customerTypeId,
  type,
  disabled,
  onChange,
}: IProductPrice) {
  const toast = useToast();
  const [valueText, setValueText] = useState<string | null>(null);
  const [productQty, setProductQty] = useState<number>();
  const [keywordProduct, setKeywordProduct] = useState("");
  const [listAddedProduct, setListAddedProduct] = useState<IAddedProduct[]>([]);
  const { data: dataRawProduct, isLoading: loadingProduct } = useGetProduct({
    keyword: keywordProduct,
    customer_type_id: customerTypeId,
    enabledQuery: customerTypeId !== undefined,
    is_under_threshold: 0,
  });
  const dataProduct = dataRawProduct?.data?.data?.data;
  const [dataProdcutOptions, setDataProductOptions] = useState<
    InputAutocompleteOptions[]
  >([]);
  const [selectedProduct, setSelectedProduct] =
    useState<InputAutocompleteOptions | null>(null);

  useEffect(() => {
    if (type !== "Add" && invoice) {
      const temp: IAddedProduct[] = [];
      invoice.forEach((item) => {
        temp.push({
          name: item.name,
          price: item.price,
          quantity: item.quantity || 0,
          product_id: item.product_id,
          stock: item.stock,
          unitType: item.unitType,
          input: item.input,
        });
      });
      setListAddedProduct(temp);
    }
  }, [type, invoice]);

  useEffect(() => {
    if (dataProduct) {
      const temp: InputAutocompleteOptions[] = [];
      dataProduct.forEach((item) => {
        temp.push({
          id: item.id,
          label: item.name,
        });
      });
      setDataProductOptions(temp);
    }
  }, [dataProduct]);

  useEffect(() => {
    if (onChange && listAddedProduct.length > 0) {
      onChange(listAddedProduct);
    }
  }, [listAddedProduct]);

  function addProductList() {
    if (!selectedProduct || !productQty) {
      toast.show("Mohon memilih barang");
      return;
    }
    const temp = [...listAddedProduct];
    const isExist = temp.filter(
      (item) => item.product_id === selectedProduct.id
    );
    if (isExist.length > 0) {
      toast.show("Barang ini sudah ada");
      return;
    }
    const tempProduct = dataProduct?.filter(
      (item) => item.id === selectedProduct.id
    )[0];
    temp.push({
      product_id: selectedProduct.id,
      quantity: productQty,
      name: selectedProduct.label,
      price: (tempProduct && tempProduct.price) || 0,
      stock: (tempProduct && tempProduct.stock) || 0,
      unitType: tempProduct?.unit_type || "",
      input: 0,
    });
    setListAddedProduct(temp);
    setSelectedProduct(null);
    setProductQty(0);
    setValueText(null);
  }

  function handleChangeProductListValue(
    idProduct: number,
    value: number | null,
    key: string
  ) {
    setListAddedProduct((prev) =>
      prev.map((item: any) => {
        if (item.product_id === idProduct) {
          item[key] = value;
        }
        return item;
      })
    );
  }

  function removeProductList(id: number) {
    setListAddedProduct((prev) =>
      prev.filter((item) => item.product_id !== id)
    );
  }

  const TableHeader = (
    type:
      | "Add"
      | "Confirm"
      | "Purchase"
      | "ConfirmPurchase"
      | "FinishPurchase"
      | "ConfirmRekap"
      | "FinishInvoice"
  ) => {
    switch (type) {
      case "Add":
        return (
          <React.Fragment>
            <TableCell>Hapus</TableCell>
            <TableCell>Nama Barang</TableCell>
            <TableCell>Satuan</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Harga</TableCell>
            <TableCell>Jumlah</TableCell>
          </React.Fragment>
        );
      case "Confirm":
        return (
          <React.Fragment>
            <TableCell>Nama Barang</TableCell>
            <TableCell>Satuan</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Harga</TableCell>
            <TableCell>Pesanan</TableCell>
            <TableCell>Konfirmasi Pesanan</TableCell>
          </React.Fragment>
        );
      case "Purchase":
        return (
          <React.Fragment>
            <TableCell>Hapus</TableCell>
            <TableCell>Nama Barang</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Satuan</TableCell>
            <TableCell>Jumlah</TableCell>
          </React.Fragment>
        );
      case "ConfirmPurchase":
        return (
          <React.Fragment>
            <TableCell>Nama Barang</TableCell>
            <TableCell>Satuan</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Pesanan</TableCell>
            <TableCell>Harga</TableCell>
          </React.Fragment>
        );
      case "FinishPurchase":
        return (
          <React.Fragment>
            <TableCell>Nama Barang</TableCell>
            <TableCell>Satuan</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Pesanan</TableCell>
            <TableCell>Harga</TableCell>
          </React.Fragment>
        );
      case "ConfirmRekap":
        return (
          <React.Fragment>
            <TableCell>Nama Barang</TableCell>
            <TableCell>Satuan</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Harga</TableCell>
            <TableCell>Pesanan</TableCell>
          </React.Fragment>
        );
      case "FinishInvoice":
        return (
          <React.Fragment>
            <TableCell>Nama Barang</TableCell>
            <TableCell>Satuan</TableCell>
            <TableCell>Harga</TableCell>
            <TableCell>Pesanan</TableCell>
          </React.Fragment>
        );
    }
  };

  const TableData = (
    type:
      | "Add"
      | "Confirm"
      | "Purchase"
      | "ConfirmPurchase"
      | "FinishPurchase"
      | "ConfirmRekap"
      | "FinishInvoice",
    item: IAddedProduct,
    index: number
  ) => {
    switch (type) {
      case "Add":
        return (
          <React.Fragment>
            <TableCell>
              <Box
                marginLeft={1}
                display={"flex"}
                style={{ cursor: "pointer" }}
                onClick={() => removeProductList(item.product_id)}
              >
                <RemoveCircleOutlineOutlined fontSize="medium" color="error" />
              </Box>
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.unitType}</TableCell>
            <TableCell>{item.stock}</TableCell>
            <TableCell>{ToMoneyFormat(item.price)}</TableCell>
            <TableCell>
              <TextField
                size="small"
                placeholder={"Jumlah"}
                type="number"
                variant="standard"
                value={item?.quantity}
                onChange={(e) => {
                  const value = e.target.value
                    ? parseFloat(e.target.value)
                    : null;
                  handleChangeProductListValue(
                    item.product_id,
                    value,
                    "quantity"
                  );
                }}
              />
            </TableCell>
          </React.Fragment>
        );
      case "Confirm":
        return (
          <React.Fragment>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.unitType}</TableCell>
            <TableCell>{item.stock}</TableCell>
            <TableCell>{ToMoneyFormat(item.price)}</TableCell>
            <TableCell>{invoice && invoice[index].quantity}</TableCell>
            <TableCell>
              <TextField
                size="small"
                placeholder={"Konfirmasi Pesanan"}
                type="number"
                variant="standard"
                defaultValue={0}
                onChange={(e) => {
                  const value = e.target.value
                    ? parseFloat(e.target.value)
                    : null;
                  handleChangeProductListValue(item.product_id, value, "input");
                }}
              />
            </TableCell>
          </React.Fragment>
        );
      case "Purchase":
        return (
          <React.Fragment>
            <TableCell>
              <Box
                marginLeft={1}
                display={"flex"}
                style={{ cursor: "pointer" }}
                onClick={() => removeProductList(item.product_id)}
              >
                <RemoveCircleOutlineOutlined fontSize="medium" color="error" />
              </Box>
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.stock}</TableCell>
            <TableCell>{item.unitType || "22"}</TableCell>
            <TableCell>
              <TextField
                size="small"
                placeholder={"Jumlah"}
                type="number"
                variant="standard"
                value={item.quantity}
                onChange={(e) => {
                  const value = e.target.value
                    ? parseFloat(e.target.value)
                    : null;
                  handleChangeProductListValue(
                    item.product_id,
                    value,
                    "quantity"
                  );
                }}
              />
            </TableCell>
          </React.Fragment>
        );
      case "ConfirmPurchase":
        return (
          <React.Fragment>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.unitType}</TableCell>
            <TableCell>{item.stock}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>
              <TextField
                size="small"
                placeholder={"Harga"}
                type="number"
                variant="standard"
                value={item.price}
                onChange={(e) => {
                  const value = e.target.value
                    ? parseFloat(e.target.value)
                    : null;
                  handleChangeProductListValue(item.product_id, value, "price");
                }}
              />
            </TableCell>
          </React.Fragment>
        );
      case "FinishPurchase":
        return (
          <React.Fragment>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.unitType}</TableCell>
            <TableCell>{item.stock}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>{item.price}</TableCell>
          </React.Fragment>
        );
      case "ConfirmRekap":
        return (
          <React.Fragment>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.unitType}</TableCell>
            <TableCell>{item.stock}</TableCell>
            <TableCell>{ToMoneyFormat(item.price)}</TableCell>
            <TableCell>{invoice && invoice[index].quantity}</TableCell>
          </React.Fragment>
        );
      case "FinishInvoice":
        return (
          <React.Fragment>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.unitType}</TableCell>
            <TableCell>{ToMoneyFormat(item.price)}</TableCell>
            <TableCell>{invoice && invoice[index].quantity}</TableCell>
          </React.Fragment>
        );
    }
  };

  if (type !== "Add" && !invoice) {
    return <Spinner />;
  }

  const isCreate = type === "Add" || type === "Purchase";

  return (
    <div>
      {isCreate && (
        <Grid container marginTop={2} spacing={1} alignItems={"center"}>
          <Grid item xs={8}>
            <NewAutoComplete
              value={valueText}
              disabled={disabled}
              loading={loadingProduct}
              label="Nama Barang"
              options={dataProdcutOptions}
              onSearch={(e) => setKeywordProduct(e)}
              onChange={(e) => {
                setValueText(e?.label || null);
                setSelectedProduct(e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              onWheel={(e: any) => e.target.blur()}
              value={productQty}
              onChange={(e) => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : undefined;
                setProductQty(value);
              }}
              type="number"
              label="Jumlah"
              placeholder="Jumlah"
            />
          </Grid>
          <Grid item xs={1}>
            <Box style={{ cursor: "pointer" }} onClick={addProductList}>
              <AddCircleOutlineRounded fontSize="large" />
            </Box>
          </Grid>
        </Grid>
      )}
      <TableContainer component={Paper} style={{ marginTop: 15 }}>
        <Table>
          <TableHead>
            <TableRow>{TableHeader(type)}</TableRow>
          </TableHead>
          <TableBody>
            {listAddedProduct.map((item, index) => {
              return (
                <TableRow key={index}>{TableData(type, item, index)}</TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
