import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { IRekap, IRekapDetailData } from "../Rekap.type";

export const getInvoiceRekap = async ({
  customer_type_id,
  page,
  perPage,
  start_date,
  end_date,
  keyword,
}: HttpBaseRequest<{
  customer_type_id?: number;
  start_date?: string;
  end_date?: string;
  keyword?: string;
}>) => {
  const results = await Http.get<HttpBaseResponse<IRekap>>({
    url: `/invoice/recap`,
    params: {
      customer_type_id,
      page,
      start_date,
      end_date,
      keyword,
      perPage,
    },
  });
  return results;
};

export const getDetailInvoiceRekap = async ({
  invoice_recap_id,
}: {
  invoice_recap_id: number;
}) => {
  const results = await Http.get<HttpBaseResponse<IRekapDetailData>>({
    url: "/invoice/recap/detail",
    params: {
      invoice_recap_id,
    },
  });
  return results;
};

export const postConfirmInvoiceRekap = async ({
  invoice_date,
  invoice_recap_details,
  invoice_recap_id,
}: {
  invoice_recap_id: number;
  invoice_date: string;
  invoice_recap_details: string;
}) => {
  const result = await Http.post({
    url: `/invoice/recap/confirm`,
    useFormMultiPart: false,
    data: {
      invoice_date,
      invoice_recap_details,
      invoice_recap_id,
    },
  });
  return result;
};

export const postInvoiceReakp = async ({
  customer_name,
  customer_type_id,
  invoice_recap_date,
  invoice_recap_number,
  pph_percentage,
  productList,
  shop_name,
  tax_percentage,
}: {
  invoice_recap_date: string;
  productList: string;
  customer_type_id: number;
  tax_percentage: number;
  customer_name: string;
  shop_name: string;
  invoice_recap_number: string;
  pph_percentage: number;
}) => {
  const results = await Http.post({
    url: "/invoice/recap/create",
    useFormMultiPart: false,
    data: {
      customer_name,
      customer_type_id,
      invoice_recap_date,
      invoice_recap_number,
      pph_percentage,
      productList,
      shop_name,
      tax_percentage,
    },
  });
  return results;
};

export const postDeleteRekap = async ({
  invoice_recap_id,
}: {
  invoice_recap_id: number;
}) => {
  const results = await Http.delete({
    url: "/invoice/recap/delete",
    useFormMultiPart: false,
    data: {
      invoice_recap_id,
    },
  });
  return results;
};
