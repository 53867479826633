import { Modal } from "@mui/material";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import Form from "./Form";

interface IModalProduk {
  visible: boolean;
  onClose(): void;
}

export default function ModalProduk({ visible, onClose }: IModalProduk) {
  return (
    <Modal open={visible} onClose={onClose}>
      <MainContainerModal>
        <MainContainerWrapper>
          <Form onComplete={() => onClose()} />
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
