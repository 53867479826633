import { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import TableFC, { TableFCBodyProps } from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import { MainPadding } from "../../../styles/Main.styles";
import ExtraFilterDate from "../component/ExtraFilterDate";
import ModalInvoice from "../component/Modal";
import { useDeleteInvoice, useGetInvoice } from "../network/resolver";
import { IOptions, InputSelect } from "../../../components/input/input";
import { useUserInformation } from "../../../context/userInformation/UserInformationContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SelectPrintOptions from "../component/SelectPrintOptions";
import {
  useGenerateInvoices,
  useGenerateTagihan,
  useGenerateZipedInvoices,
} from "../hooks/pdf/GenerateInvoice";
import { IInvoiceData } from "../Invoice.type";

export default function LunasInvoice() {
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [invoiceType] = useState(4);
  const [isSelectMultiple, setIsSelectMultiple] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceData[]>([]);
  const { generate, loading: isLoadingGenerate } = useGenerateInvoices({
    invoice: selectedInvoice,
  });
  const { generate: generateZiped, loading: isLoadingGenerateZipped } =
    useGenerateZipedInvoices({
      invoice: selectedInvoice,
    });
  const [invoiceMultiplePrintType, setInvoiceMultiplePrintType] = useState<
    | "Resmi"
    | "Non-Resmi"
    | "SuratJalan"
    | "Simpel"
    | "Resmi-SuratJalan"
    | "Non-Resmi-SuratJalan"
  >("Simpel");
  const { data: dataRawInvoice, isLoading: loadingInvoice } = useGetInvoice({
    invoice_number: keyword,
    start_date: startDate,
    end_date: endDate,
    status: invoiceType,
    perPage,
    page,
  });
  const dataInvoice = dataRawInvoice?.data?.data?.data;
  const [selectedIdInvoice, setSelectedIdInvoice] = useState<
    number | undefined
  >();
  const INVOICE_PRINT_OPTIONS: IOptions[] = [
    { text: "Simpel", value: 6 },
    { text: "Resmi", value: 1 },
    { text: "Tidak Resmi", value: 2 },
    { text: "Surat Jalan", value: 3 },
    { text: "Resmi & Surat Jalan", value: 4 },
    { text: "Tidak Resmi & Surat Jalan", value: 5 },
  ];
  const userInformation = useUserInformation();
  const { mutate: deleteInvoice } = useDeleteInvoice();
  const [_, setIsAdmin] = useState<boolean | undefined>(undefined);
  const [header, setHeader] = useState<string[]>([
    "No",
    "Nomor Invoice",
    "Nomor PO",
    "Nama Customer",
    "Nama Toko",
    "Tanggal",
    "Total",
  ]);
  const [bodyTable, setBodyTable] = useState<TableFCBodyProps[]>([
    { key: "invoice_number" },
    { key: "pre_order_number" },
    { key: "customer_name" },
    { key: "shop_name" },
    { key: "invoice_date", isDate: true },
    { key: "grand_total", isMoney: true },
  ]);
  const [tagihanInvoiceType, setTagihanInvoiceType] = useState(1);
  const { generate: generateTagihan, isLoading: loadingGenerateTagihan } =
    useGenerateTagihan();

  useEffect(() => {
    if (userInformation.get()) {
      const isAdmin = userInformation.get()?.role === 1;
      if (isAdmin) {
        setIsAdmin(true);
        setHeader((prev: any) => {
          if (prev.filter((item: any) => item === "Hapus").length > 0) {
            return prev;
          }
          return [...prev, "Hapus"];
        });
        setBodyTable((prev) => {
          if (prev.filter((item) => item.key === "action").length > 0) {
            return prev;
          }
          return [
            ...prev,
            {
              key: "action",
              show: isAdmin,
              component: (
                <Box marginLeft={1}>
                  <DeleteForeverIcon />
                </Box>
              ),
              onClick: (e) => {
                if (confirm("Apakah anda yakin ?")) {
                  deleteInvoice({ invoice_id: e.id });
                }
              },
            },
          ];
        });
      }
    }
  }, [userInformation.get()]);

  return (
    <MainPadding>
      <TableHeader
        title="List Invoice Lunas"
        filterLabel="Cari Invoice"
        searchBar
        onSearch={(e) => setKeyword(e)}
        renderFilter={
          <Box>
            <ExtraFilterDate
              onChange={(start, end) => {
                setStartDate(start);
                setEndDate(end);
              }}
            />
            {isSelectMultiple && (
              <Box>
                <Box
                  marginTop={2}
                  display={"flex"}
                  gap={1}
                  alignItems={"center"}
                >
                  <SelectPrintOptions
                    invoicePrintOptions={[
                      { text: "Simpel", value: "Simpel" },
                      { text: "Resmi", value: "Resmi" },
                      { text: "Tidak Resmi", value: "Non-Resmi" },
                      { text: "Surat Jalan", value: "SuratJalan" },
                      {
                        text: "Resmi dan Surat Jalan",
                        value: "Resmi-SuratJalan",
                      },
                      {
                        text: "Tidak Resmi dan Surat Jalan",
                        value: "Non-Resmi-SuratJalan",
                      },
                    ]}
                    onChange={(e) => setInvoiceMultiplePrintType(e.value)}
                  />
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => generate(invoiceMultiplePrintType as any)}
                      disabled={isLoadingGenerate}
                    >
                      {isLoadingGenerate ? (
                        <CircularProgress size={14} />
                      ) : (
                        "Lihat Invoice"
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        generateZiped(invoiceMultiplePrintType as any)
                      }
                      disabled={isLoadingGenerateZipped}
                    >
                      {isLoadingGenerateZipped ? (
                        <CircularProgress size={14} />
                      ) : (
                        "Download Invoice"
                      )}
                    </Button>
                  </Box>
                </Box>
                <Box
                  marginTop={2}
                  display={"flex"}
                  gap={1}
                  alignItems={"center"}
                >
                  <Box width={150}>
                    <InputSelect
                      id={"Tagihan-1"}
                      value={tagihanInvoiceType}
                      label="Tipe Tagihan"
                      onChange={(e) => e && setTagihanInvoiceType(e)}
                      options={[
                        { text: "Kode", value: 1 },
                        { text: "Non-Kode", value: 2 },
                      ]}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        generateTagihan({
                          ids: selectedInvoice.map((item) => item.id),
                          type: tagihanInvoiceType === 1 ? "Kode" : "Non",
                        })
                      }
                      disabled={isLoadingGenerateZipped}
                    >
                      {loadingGenerateTagihan ? (
                        <CircularProgress size={14} />
                      ) : (
                        "Tagihan"
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        }
      />
      <TableFC
        loading={loadingInvoice}
        data={dataInvoice}
        showIndex
        showCheckBox
        body={bodyTable}
        head={header}
        totalRows={dataRawInvoice?.data?.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          setSelectedIdInvoice(e.id);
          setVisible(true);
        }}
        onChecked={(e) => {
          setIsSelectMultiple(e.length > 0);
          setSelectedInvoice(e.map((item) => item.value));
        }}
      />
      {selectedIdInvoice && (
        <ModalInvoice
          isPlacedOrder
          idInvoice={selectedIdInvoice}
          invoicePrintOptions={INVOICE_PRINT_OPTIONS}
          visible={visible}
          onClose={() => {
            setVisible(false);
            setSelectedIdInvoice(undefined);
          }}
        />
      )}
    </MainPadding>
  );
}
