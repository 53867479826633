import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { IInvoice, IInvoiceDetail, ITagihanData } from "../Invoice.type";

export const getInvoice = async ({
  invoice_number,
  page,
  perPage,
  start_date,
  end_date,
  status,
}: HttpBaseRequest<{
  invoice_number?: string;
  start_date?: string;
  end_date?: string;
  status?: number;
}>) => {
  const result = await Http.get<HttpBaseResponse<IInvoice>>({
    url: "/invoice",
    params: {
      invoice_number,
      start_date,
      end_date,
      page,
      perPage: perPage || 10,
      status,
    },
  });
  return result;
};

export const postAddInvoice = async ({
  invoice_date,
  productList,
  customer_type_id,
  tax_percentage,
  customer_name,
  shop_name,
  is_pre_order,
  is_ticket,
  pph_percentage,
  invoice_number,
}: {
  invoice_date: string;
  productList: string;
  customer_type_id: number;
  tax_percentage: number;
  customer_name: string;
  shop_name: string;
  is_ticket?: boolean;
  is_pre_order?: boolean;
  pph_percentage?: number;
  invoice_number: number;
}) => {
  const result = await Http.post({
    url: "/invoice/insert",
    data: {
      invoice_date,
      productList,
      customer_type_id,
      tax_percentage,
      customer_name,
      shop_name,
      is_pre_order,
      is_ticket,
      pph_percentage,
      invoice_number: invoice_number.toString(),
    },
  });
  return result;
};

export const getDetailInvoice = async ({
  invoice_id,
}: {
  invoice_id: number;
}) => {
  const result = await Http.get<HttpBaseResponse<IInvoiceDetail>>({
    url: "/invoice/detail",
    params: {
      invoice_id,
    },
  });
  return result;
};

export const patchInvoiceStatus = async ({
  invoice_number,
  invoice_id,
  status,
}: {
  invoice_number: string;
  invoice_id: number;
  status: number;
}) => {
  const result = await Http.post({
    url: "/invoice/status",
    data: {
      invoice_number,
      invoice_id,
      status,
    },
  });
  return result;
};

export const postSplitInvoice = async ({
  invoice_id,
  available_list,
  all_available,
  all_unavailable,
}: {
  invoice_id: number;
  available_list: string;
  all_available?: boolean;
  all_unavailable?: boolean;
}) => {
  const result = await Http.post({
    url: "/invoice/split",
    data: {
      invoice_id,
      available_list,
      all_available,
      all_unavailable,
    },
  });
  return result;
};

export const deleteInvoice = async ({ invoice_id }: { invoice_id: number }) => {
  const result = await Http.delete({
    url: "/invoice/delete",
    useFormMultiPart: false,
    data: {
      invoice_id,
    },
  });
  return result;
};

export const getDownloadExcel = async ({ ids }: { ids: number[] }) => {
  const result = await Http.get<HttpBaseResponse<ITagihanData[]>>({
    url: `/invoice/export?invoice_ids=${JSON.stringify(ids)}`,
  });
  return result;
};
