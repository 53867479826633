import { useMutation, useQuery, useQueryClient } from "react-query";
import { HttpBaseRequest } from "../../../core/HttpRequest";
import {
  getDetailPurchaseInvoice,
  getPurchaseInvoice,
  postConfirmPurchaseInvoice,
  postDeletePurchaseInvoice,
  postFinishPurchaseInvoice,
  postPurchaseInvoice,
} from "./service";
import { useToast } from "../../../context/toast/ToastContext";
import { useNavigate } from "react-router";

export const useGetPurchaseInvoice = ({
  customer_type_id,
  page,
  perPage,
  start_date,
  end_date,
  keyword,
  status,
}: HttpBaseRequest<{
  customer_type_id?: number;
  start_date?: string;
  end_date?: string;
  keyword?: string;
  status: number;
}>) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: [
      "invoice-purchase",
      keyword,
      customer_type_id,
      page,
      start_date,
      end_date,
      status,
      perPage,
    ],
    queryFn: () =>
      getPurchaseInvoice({
        customer_type_id,
        page,
        perPage,
        start_date,
        end_date,
        keyword,
        status,
      }),
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const useGetDetailPurchaseInvoice = ({
  purchase_invoice_id,
}: {
  purchase_invoice_id?: number;
}) => {
  return useQuery({
    queryKey: ["detail-invoice-purchase", purchase_invoice_id],
    queryFn: () =>
      getDetailPurchaseInvoice({
        purchase_invoice_id: purchase_invoice_id || 0,
      }),
    enabled: purchase_invoice_id !== undefined,
  });
};

export const usePostPurchaseInvoice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      pph_percentage,
      productList,
      purchase_invoice_date,
      purchase_invoice_number,
      seller_name,
      shop_name,
      tax_percentage,
    }: {
      purchase_invoice_date: string;
      productList: string;
      tax_percentage: number;
      seller_name: string;
      shop_name: string;
      purchase_invoice_number: string;
      pph_percentage: number;
    }) =>
      postPurchaseInvoice({
        pph_percentage,
        productList,
        purchase_invoice_date,
        purchase_invoice_number,
        seller_name,
        shop_name,
        tax_percentage,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil menambahkan invoice pembelian");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostConfirmPurchaseInvoice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      purchase_invoice_id,
      purchase_invoice_detail,
      has_tax_invoice,
      pph_percentage,
      tax_percentage,
    }: {
      purchase_invoice_id: number;
      purchase_invoice_detail: string;
      tax_percentage: number;
      pph_percentage: number;
      has_tax_invoice?: boolean;
    }) =>
      postConfirmPurchaseInvoice({
        purchase_invoice_id,
        purchase_invoice_detail,
        has_tax_invoice,
        pph_percentage,
        tax_percentage,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil konfirmasi invoice pembelian");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostFinishPurchaseInvoice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      purchase_invoice_id,
      has_tax_invoice,
    }: {
      purchase_invoice_id: number;
      has_tax_invoice?: boolean;
    }) =>
      postFinishPurchaseInvoice({
        purchase_invoice_id,
        has_tax_invoice,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil menyelesaikan pembelian");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostDeletePurchaseInvoice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ purchase_invoice_id }: { purchase_invoice_id: number }) =>
      postDeletePurchaseInvoice({
        purchase_invoice_id,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil hapus invoice pembelian");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};
