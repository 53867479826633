import { Box, Modal, TextField, Typography } from "@mui/material";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import { useEffect, useState } from "react";
import { LoadingButton } from "../../../components/button/Button";
import { useGetRoles, usePatchUser, usePostAddUser } from "../network/resolver";
import { IOptions, InputSelect } from "../../../components/input/input";
import { IUserData, IUserRole } from "../type/user";
import { useToast } from "../../../context/toast/ToastContext";

interface IModalAdd {
  user?: IUserData;
  visible: boolean;
  onClose(): void;
}

export default function ModalAdd({ user, visible, onClose }: IModalAdd) {
  const toast = useToast();
  const { data: dataRawRole } = useGetRoles();
  const dataRole = dataRawRole?.data.data;
  const { mutateAsync: mutateAddUser, isLoading: loadingAddUser } =
    usePostAddUser();
  const { mutateAsync: mutatePatchUser, isLoading: loadingPatchUser } =
    usePatchUser();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const [selectedRole, setSelectedRole] = useState<number>();
  const loading = loadingAddUser || loadingPatchUser;

  useEffect(() => {
    if (dataRole) {
      const temp: IOptions[] = [];
      Object.keys(dataRole).forEach((item) => {
        temp.push({
          text: item,
          value: dataRole[item as keyof IUserRole],
        });
      });
      setOptions(temp);
      setSelectedRole(temp[0].value);
    }
  }, [dataRole]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(user.name);
    }
  }, [user]);

  useEffect(() => {
    if (!visible) {
      setEmail("");
      setName("");
      setPassword("");
      setRePassword("");
    }
  }, [visible]);

  async function handleSubmit() {
    if (user) {
      // Update user
      if (!selectedRole) {
        toast.show("Mohon lengkapi field");
        return;
      } else if (password !== "" && password !== rePassword) {
        toast.show("password tidak sama");
        return;
      }
      await mutatePatchUser({
        user_id: user.id,
        email,
        name,
        password,
        role: selectedRole,
      });
      onClose();
    } else {
      // Add user
      if (!email || !name || !password || !rePassword || !selectedRole) {
        toast.show("Mohon lengkapi field");
        return;
      } else if (password !== rePassword) {
        toast.show("password tidak sama");
        return;
      }
      await mutateAddUser({
        email,
        name,
        password,
        role: selectedRole,
      });
      onClose();
    }
  }

  return (
    <Modal open={visible} onClose={onClose}>
      <MainContainerModal>
        <MainContainerWrapper>
          <Box width={"100%"}>
            <Box marginBottom={1}>
              <Typography variant="body1" fontWeight={"bold"}>
                {user ? "Update" : "Tambah"} User
              </Typography>
            </Box>
            <Box marginTop={3} marginBottom={1}>
              <InputSelect
                id={"role"}
                label="Role"
                options={options}
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e);
                }}
              />
            </Box>
            <TextField
              placeholder="Email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              placeholder="Nama"
              label="Nama"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              placeholder="Password"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              type="password"
            />
            <TextField
              placeholder="Confirm Password"
              label="Confirm Password"
              onChange={(e) => setRePassword(e.target.value)}
              fullWidth
              margin="normal"
              type="password"
            />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={1}>
              <LoadingButton
                isLoading={loading}
                text="Submit"
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
