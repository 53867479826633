import { Button, CircularProgress, styled } from "@mui/material";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export const LoadingButton = ({
  accept,
  text,
  isLoading,
  style,
  onClick,
  onUpload,
  variant,
  color,
  isUpload,
}: {
  accept?: string;
  text: string;
  isLoading?: boolean;
  style?: any;
  isUpload?: boolean;
  variant?: "text" | "contained" | "outlined";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  onClick?(): void;
  onUpload?(file: FileList | null): void;
}) => {
  return (
    <Button
      component="label"
      onClick={onClick}
      variant={variant || "contained"}
      color={color}
      disabled={isLoading}
      style={style}
    >
      {isLoading ? <CircularProgress size={20} /> : text}
      {isUpload && (
        <VisuallyHiddenInput
          accept={accept}
          onChange={(e) => {
            onUpload && onUpload(e.target.files);
          }}
          type="file"
        />
      )}
    </Button>
  );
};
