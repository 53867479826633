import { useEffect, useState } from "react";
import { InputAutocompleteOptions } from "../input";

interface IuseGenerateOptions {
  dataSource?: any[];
  idKey: string;
  valueKey: string;
}

const useGenerateOptions = ({
  dataSource,
  idKey,
  valueKey,
}: IuseGenerateOptions) => {
  const [dataOptions, setDataOptions] = useState<InputAutocompleteOptions[]>(
    []
  );

  useEffect(() => {
    if (dataSource) {
      const temp: InputAutocompleteOptions[] = [];
      dataSource.forEach((item) => {
        temp.push({
          id: item[idKey],
          label: item[valueKey],
        });
      });
      setDataOptions(temp);
    }
  }, [dataSource]);

  return { data: dataOptions };
};

export default useGenerateOptions;
