import { useMutation, useQueryClient } from "react-query";
import {
  exportFromFileHargaKhusus,
  importFromFileHargaKhusus,
  patchCustomerType,
  postAddCustomerType,
  postCustomerPrice,
} from "./service";
import { useToast } from "../../../context/toast/ToastContext";
import { GetCurrentDate } from "../../../utils/Helper";

export const useAddCustomerType = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      customer_type_name,
      amount,
      amount_type,
    }: {
      customer_type_name: string;
      amount_type: number;
      amount: number;
    }) => postAddCustomerType({ amount, amount_type, customer_type_name }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil tambah customer");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePatchCustomerType = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      customer_type_id,
      customer_type_name,
      amount,
      amount_type,
    }: {
      customer_type_id: number;
      customer_type_name: string;
      amount_type: number;
      amount: number;
    }) =>
      patchCustomerType({
        customer_type_id,
        amount,
        amount_type,
        customer_type_name,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil update customer");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostAddCustomerPrice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      amount,
      amount_type,
      customer_type_id,
      price,
      product_id,
    }: {
      product_id: number;
      customer_type_id: number;
      price: number;
      amount_type: number;
      amount: number;
    }) =>
      postCustomerPrice({
        amount,
        amount_type,
        customer_type_id,
        price,
        product_id,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil tambah customer price");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.data || "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useImportFromFileHargaKhusus = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ file }: { file: File }) =>
      importFromFileHargaKhusus({ file }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil import file");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.data || "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useExportFromFileHargaKhusus = ({
  customer_type_id,
}: {
  customer_type_id: number;
}) => {
  return useMutation({
    mutationFn: () => exportFromFileHargaKhusus({ customer_type_id }),
    onSuccess: (e) => {
      const csv = e.data;
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;

      a.download = `data-product-${GetCurrentDate()}.csv`;
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
  });
};
