export interface ConfigProps {
  docWidth: number;
  docHeight: number;
  currentHeight: number;
  colorBlack: string;
  colorGray: string;
  fieldTextSize: number;
  subLineHeight: number;
  headerTextSize: number;
  header: any[];
  dataTable: any[];
  tdWidth: number;
  lineHeight: number;
  addBorder?: boolean;
}

const addTableHeaderBorder = ({
  doc,
  config,
}: {
  doc: any;
  config: ConfigProps;
}) => {
  let startWidth = 0;
  let h = config.currentHeight - 8;
  for (let i = 0; i < config.header.length; i++) {
    const currentTdWidth = config.header[i]?.style?.width || config.tdWidth;
    if (i === 0) doc.rect(10, h, currentTdWidth, config.lineHeight);
    else {
      const previousTdWidth =
        config.header[i - 1]?.style?.width || config.tdWidth;
      const widthToUse =
        currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
      startWidth += widthToUse;
      doc.rect(startWidth + 10, h, currentTdWidth, config.lineHeight);
    }
  }
};

const addTableBodyBorder = ({
  doc,
  config,
}: {
  doc: any;
  config: ConfigProps;
}) => {
  for (let i = 0; i < config.dataTable.length; i++) {
    let startWidth = 0;
    let h = config.currentHeight + i * config.subLineHeight;
    for (let i = 0; i < config.header.length; i++) {
      const currentTdWidth = config.header[i]?.style?.width || config.tdWidth;
      if (i === 0) doc.rect(10, h, currentTdWidth, config.subLineHeight);
      else {
        const previousTdWidth =
          config.header[i - 1]?.style?.width || config.tdWidth;
        const widthToUse =
          currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
        startWidth += widthToUse;
        doc.rect(startWidth + 10, h, currentTdWidth, config.subLineHeight);
      }
    }
  }
};

const addTableHeader = ({
  doc,
  config,
  center,
}: {
  doc: any;
  config: ConfigProps;
  center?: boolean;
}) => {
  config.currentHeight += config.subLineHeight;
  doc.setTextColor(config.colorBlack);
  doc.setFontSize(config.fieldTextSize);
  //border color
  doc.setDrawColor(config.colorBlack);
  config.currentHeight += 2;

  let startWidth = 0;
  config.header.forEach(function (row: any, index: any) {
    if (index == 0) doc.text(row.title, 11, config.currentHeight);
    else {
      const currentTdWidth = row?.style?.width || config.tdWidth;
      const previousTdWidth =
        config.header[index - 1]?.style?.width || config.tdWidth;
      const widthToUse =
        currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
      startWidth += widthToUse;
      const x = row.posX ? row.posX : startWidth + 11;
      doc.text(row.title, x, config.currentHeight);
    }
  });

  config.currentHeight += config.subLineHeight - 1;
  doc.setTextColor(config.colorBlack);
};

const drawBody = ({ doc, config }: { doc: any; config: ConfigProps }) => {
  var tableBodyLength = config.dataTable.length;
  config.dataTable.forEach(function (row: any[], index: number) {
    // Utils
    const splitTextAndGetHeight = (text: string, size: number) => {
      var lines = doc.splitTextToSize(text, size);
      return {
        text: lines,
        height: doc.getTextDimensions(lines).h,
      };
    };

    //get max height for the current row
    const getRowsHeight = function () {
      let rowsHeight: any[] = [];
      row.forEach(function (
        rr: { toString: () => any },
        index: string | number
      ) {
        const widthToUse =
          config.header[index as any]?.style?.width || config.tdWidth;

        let item = splitTextAndGetHeight(rr.toString(), widthToUse - 1); //minus 1, to fix the padding issue between borders
        rowsHeight.push(item.height);
      });

      return rowsHeight;
    };

    var maxHeight = Math.max(...getRowsHeight());

    // //body borders
    // if (config.addBorder) {
    //   // if (index + 1 < config.dataTable.length) {
    //   //   addTableBodyBorder({ doc, config });
    //   // }
    //   addTableBodyBorder({ doc, config });
    // }

    let startWidth = 0;
    row.forEach(function (
      rr: { toString: () => any; style: { width: number } },
      index: number
    ) {
      const widthToUse = config.header[index]?.style?.width || config.tdWidth;
      let item = splitTextAndGetHeight(rr.toString(), widthToUse - 1); //minus 1, to fix the padding issue between borders

      if (index == 0) doc.text(item.text, 11, config.currentHeight + 4);
      else {
        const currentTdWidth = rr?.style?.width || config.tdWidth;
        const previousTdWidth =
          config.header[index - 1]?.style?.width || config.tdWidth;
        const widthToUse =
          currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
        startWidth += widthToUse;
        doc.text(item.text, 11 + startWidth, config.currentHeight + 4);
      }
    });

    config.currentHeight += maxHeight - 4;

    //td border height
    config.currentHeight += 5;

    //pre-increase currentHeight to check the height based on next row
    if (index + 1 < tableBodyLength) config.currentHeight += maxHeight;

    // if (
    //   options.orientation === "landscape" &&
    //   (currentHeight > 185 ||
    //     (currentHeight > 178 && doc.getNumberOfPages() > 1))
    // ) {
    //   doc.addPage();
    //   currentHeight = 10;
    //   if (index + 1 < tableBodyLength) addTableHeader();
    // }

    // if (
    //   options.orientation === "portrait" &&
    //   (currentHeight > 265 ||
    //     (currentHeight > 255 && doc.getNumberOfPages() > 1))
    // ) {
    //   doc.addPage();
    //   currentHeight = 10;
    //   if (index + 1 < tableBodyLength) addTableHeader();
    //   //else
    //   //currentHeight += pdfConfig.subLineHeight + 2 + pdfConfig.subLineHeight - 1; //same as in addtableHeader
    // }

    //reset the height that was increased to check the next row
    if (index + 1 < tableBodyLength && config.currentHeight > 30)
      // check if new page
      config.currentHeight -= maxHeight;
  });
};

const breakWord = (text: string, doc: any, y: number, x: number) => {
  const textWidth = 20;
  const words = text.split(" ");
  let currentLine = "";
  let lines = [];
  for (const word of words) {
    // Check if adding the current word would exceed the text width
    if (doc.getStringUnitWidth(currentLine + " " + word) > textWidth) {
      // Push the current line to the lines array and reset the current line
      lines.push(currentLine);
      currentLine = "";
    }

    // Add the word to the current line
    if (currentLine !== "") {
      currentLine += " ";
    }
    currentLine += word;
  }
  if (currentLine !== "") {
    lines.push(currentLine);
  }
  let ctr = 0;
  for (const line of lines) {
    doc.text(x, y, line, "right");
    y += 4.5; // Adjust the line spacing
    ctr += 1.5;
  }
  let resCtr = ctr + (lines.length - 1) * 1.5 + (lines.length - 1) * 0.5;
  return resCtr + 1 * lines.length - 1;
};

export {
  addTableHeaderBorder,
  addTableBodyBorder,
  addTableHeader,
  drawBody,
  breakWord,
};
