import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ENV_LISTS } from "../core/EnvTranspiler";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useUserInformation } from "../context/userInformation/UserInformationContext";

interface IGuard {
  children: ReactJSXElement | ReactJSXElement[];
}

export default function Guard({ children }: IGuard) {
  const navigate = useNavigate();
  const key = ENV_LISTS.KEY_TOKEN;
  const user = ENV_LISTS.KEY_USER_INFO;
  const userInformation = useUserInformation();

  // Make sure auth is present
  useEffect(() => {
    const temp = localStorage.getItem(key);
    const tempUser = localStorage.getItem(user);
    if (!temp || !tempUser) {
      navigate("/login");
      return;
    }
    const userData: any = JSON.parse(tempUser);
    // if (userData && userData.role !== 1) {
    //   const temp = window.location.href.split("/");
    //   console.log(temp);
    //   navigate("/note/list");
    // }
    userInformation.set(userData);
  }, []);

  return <>{children}</>;
}
