import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { InputMoney } from "../../../components/input/input";
import { LoadingButton } from "../../../components/button/Button";
import { usePatchProduct, usePostAddProduct } from "../network/resolver";
import { useToast } from "../../../context/toast/ToastContext";
import { IProductDetail } from "../Produk.type";
import HargaKhusus, { ICustomeList } from "./HargaKhusus";

interface IModalProduk {
  product?: IProductDetail;
  onComplete(): void;
}

export default function Form({ product, onComplete }: IModalProduk) {
  const toast = useToast();
  const [name, setName] = useState("");
  const [customerList, setCustomerList] = useState<ICustomeList[]>([]);
  const [buyingPrice, setBuyingPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [unitType, setUnitType] = useState("");
  const [stock, setStock] = useState<number | undefined>(0);
  const [stockThreshold, setstockThreshold] = useState<number | undefined>(0);
  const { mutateAsync: mutateAddProduct, isLoading: loadingAdd } =
    usePostAddProduct();
  const { mutateAsync: mutatePatchProduct, isLoading: loadingPatch } =
    usePatchProduct();
  const loading = loadingAdd || loadingPatch;

  useEffect(() => {
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setUnitType(product.unit_type);
      setStock(product.stock);
      setBuyingPrice(product.buying_price);
      setstockThreshold(product.stock_threshold);
    }
  }, [product]);

  async function handleSubmit() {
    if (!name || !unitType) {
      toast.show("Mohon lengkapi field");
      return;
    }
    let priceList: any = [];
    if (customerList.length > 0) {
      customerList.forEach((item) => {
        priceList.push({
          customer_type_id: item.id,
          amount_type: item.discountType,
          amount: item.amount,
          price: item.amount,
        });
      });
    }
    if (product) {
      // Do Update
      await mutatePatchProduct({
        product_id: product.id,
        name,
        price,
        buying_price: buyingPrice,
        stock: stock || 0,
        unit_type: unitType,
        stock_threshold: stockThreshold || 0,
      });
    } else {
      // Do Insert
      await mutateAddProduct({
        name,
        price,
        buying_price: buyingPrice,
        stock: stock || 0,
        unit_type: unitType,
        priceList: JSON.stringify(priceList) as any,
        stock_threshold: stockThreshold || 0,
      });
    }
    onComplete();
  }

  return (
    <Box>
      <TextField
        label="Nama"
        placeholder="Nama"
        value={name}
        onChange={(e) => setName(e.target.value)}
        margin="normal"
        fullWidth
      />
      <Box marginTop={2} marginBottom={1}>
        <InputMoney label="Harga" value={price} onChange={(e) => setPrice(e)} />
      </Box>
      <TextField
        label="Satuan"
        placeholder="Satuan"
        value={unitType}
        onChange={(e) => setUnitType(e.target.value)}
        margin="normal"
        fullWidth
      />
      <Box>
        <TextField
          label="Stock"
          placeholder="Stock"
          value={stock}
          type="number"
          onChange={(e) => {
            const value = e.target.value
              ? parseFloat(e.target.value)
              : undefined;
            setStock(value);
          }}
          margin="normal"
          fullWidth
        />
      </Box>
      <Box>
        <TextField
          label="Stock Threshold"
          placeholder="Stock Threshold"
          value={stockThreshold}
          type="number"
          onChange={(e) => {
            const value = e.target.value ? parseInt(e.target.value) : undefined;
            setstockThreshold(value);
          }}
          margin="normal"
          fullWidth
        />
      </Box>
      <Box marginTop={2}>
        <InputMoney
          label="Harga Beli"
          value={buyingPrice}
          onChange={(e) => setBuyingPrice(e)}
        />
      </Box>
      {!product && (
        <Box marginTop={3}>
          <HargaKhusus onChange={(e) => setCustomerList(e)} />
        </Box>
      )}
      <Box marginTop={3} display={"flex"} justifyContent={"flex-end"}>
        <LoadingButton
          text="Submit"
          onClick={handleSubmit}
          isLoading={loading}
        />
      </Box>
    </Box>
  );
}
