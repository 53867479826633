import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import {
  IProductPreOrder,
  IProductPreOrderDetail,
} from "../ProdukPreOrder.type";

export const getProductPreOrder = async ({
  keyword,
  page,
  perPage,
}: HttpBaseRequest) => {
  const results = await Http.get<HttpBaseResponse<IProductPreOrder>>({
    url: "/product/pre_order",
    params: {
      keyword,
      page,
      perPage,
    },
  });
  return results;
};

export const getProductPreOrderDetail = async ({
  pre_order_product_id,
}: {
  pre_order_product_id: number;
}) => {
  const results = await Http.get<HttpBaseResponse<IProductPreOrderDetail>>({
    url: "/product/pre_order/detail",
    params: {
      pre_order_product_id,
    },
  });
  return results;
};
