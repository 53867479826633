import { Box, Typography, Button, debounce } from "@mui/material";
import { ButtonContainer } from "../../styles/Main.styles";
import FilterGroup from "../filter/Filter";
import { useCallback } from "react";

interface TableHeaderProps {
  title: string;
  filterLabel?: string;
  searchBar?: boolean;
  addButton?: boolean;
  renderRight?: JSX.Element | JSX.Element[];
  renderFilter?: JSX.Element | JSX.Element[];
  onSearch?(text: string): void;
  onClickAdd?(): void;
}

export default function TableHeader({
  title,
  filterLabel,
  searchBar,
  addButton,
  renderFilter,
  renderRight,
  onClickAdd,
  onSearch,
}: TableHeaderProps) {
  const handleSearch = useCallback(
    debounce((value) => {
      if (onSearch) {
        onSearch(value);
      }
    }, 500),
    []
  );

  function handleOnChange(text: string) {
    handleSearch(text);
  }

  return (
    <Box
      bgcolor={"white"}
      padding={2}
      height={"100%"}
      display={"flex"}
      borderBottom={3}
      borderColor={"#dedede"}
      borderRadius={1}
    >
      <Box flexBasis={"80%"}>
        <Typography variant="h6" fontWeight={"bold"}>
          {title}
        </Typography>
        <Box flexBasis={"100%"} marginTop={2} marginBottom={1}>
          {searchBar && (
            <FilterGroup
              serachInputLabel={filterLabel || ""}
              onFiltered={(e) => {
                handleOnChange(e);
              }}
            />
          )}
          {renderFilter && renderFilter}
        </Box>
      </Box>
      <Box flexBasis={"20%"}>
        {addButton && (
          <ButtonContainer>
            <Button
              variant="contained"
              onClick={() => {
                if (onClickAdd) {
                  onClickAdd();
                }
              }}
            >
              Tambah
            </Button>
          </ButtonContainer>
        )}
        {renderRight && renderRight}
      </Box>
    </Box>
  );
}
