import React from "react";
import { QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router";
import ToastContextFC from "./context/toast/ToastContext";
import router from "./router/Route";
import queryClient from "./utils/queryClient";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UserInformationContextFC from "./context/userInformation/UserInformationContext";

function App() {
  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <UserInformationContextFC>
            <ToastContextFC>
              <RouterProvider router={router} />
            </ToastContextFC>
          </UserInformationContextFC>
        </QueryClientProvider>
      </LocalizationProvider>
    </React.Fragment>
  );
}

export default App;
