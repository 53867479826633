import { useMutation, useQuery, useQueryClient } from "react-query";
import { HttpBaseReq } from "../../../core/HttpRequest";
import {
  deleteUser,
  getUserRoles,
  getUsers,
  patchUser,
  postAddUser,
} from "./service";
import { useToast } from "../../../context/toast/ToastContext";
import { useNavigate } from "react-router";

export const useGetUsers = ({ keyword, page, perPage }: HttpBaseReq) => {
  const navigate = useNavigate();

  return useQuery({
    queryKey: `users${page}${keyword || ""}${perPage}`,
    queryFn: () => getUsers({ keyword, page, perPage }),
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const useGetRoles = () => {
  return useQuery({
    queryKey: `userRole`,
    queryFn: () => getUserRoles(),
  });
};

export const usePostAddUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: `addUser`,
    mutationFn: ({
      name,
      email,
      role,
      password,
    }: {
      name: string;
      email: string;
      role: number;
      password: string;
    }) => postAddUser({ name, email, role, password }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil menambahkan user baru");
        queryClient.invalidateQueries();
        window.location.reload();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePatchUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: `patchUser`,
    mutationFn: ({
      name,
      email,
      role,
      password,
      user_id,
    }: {
      name: string;
      email: string;
      role: number;
      password?: string;
      user_id: number;
    }) => patchUser({ name, email, role, password, user_id }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil update user");
        queryClient.invalidateQueries();
        window.location.reload();
      } else if (e.data.statusCode === 400 || e.data.statusCode === 500) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useDeleteUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: "deleteUser",
    mutationFn: ({ user_id }: { user_id: number }) => deleteUser({ user_id }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil delete user");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400 || e.data.statusCode === 500) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};
