import { useEffect, useState } from "react";
import TableFC, { TableFCBodyProps } from "../../../components/table/Table";
import { MainPadding } from "../../../styles/Main.styles";
import {
  useGetPurchaseInvoice,
  usePostFinishPurchaseInvoice,
} from "../network/resolver";
import TableHeader from "../../../components/table/TableHeader";
import { ModalFC } from "../../../components/modal/Modal";
import FormPembelian from "../form/FormPurchaseInvoice";
import { IPurchaseInvoiceData } from "../pembelian.type";

export default function ListPembelianSudahKonfirmasi() {
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showmodal, setShowModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<IPurchaseInvoiceData>();
  const { data: dataRawPurchase, isLoading: loadingPurchase } =
    useGetPurchaseInvoice({ keyword, page, status: 2, perPage });
  const dataPurchase = dataRawPurchase?.data.data.data;
  const {
    mutate: mutateFinish,
    isLoading: loadingFinish,
    isSuccess: successFinish,
  } = usePostFinishPurchaseInvoice();
  const [header] = useState<string[]>([
    "No",
    "No Invoice Pembelian",
    "Nama Penjual",
    "Nama Toko",
    "Tanggal",
    "Total",
  ]);
  const [bodyTable] = useState<TableFCBodyProps[]>([
    { key: "purchase_invoice_number" },
    { key: "seller_name" },
    { key: "shop_name" },
    { key: "purchase_invoice_date", isDate: true },
    { key: "grand_total", isMoney: true },
  ]);
  const isLoading = loadingPurchase || loadingFinish;

  useEffect(() => {
    if (successFinish) {
      setShowModal(false);
    }
  }, [successFinish]);

  const handleSubmit = (form: any) => {
    if (selectedInvoice) {
      const hasFaktur =
        form?.has_tax_invoice !== null && form?.has_tax_invoice?.id !== null;
      const bodyForm = {
        purchase_invoice_id: selectedInvoice?.id,
        has_tax_invoice: hasFaktur,
      };
      mutateFinish(bodyForm);
    }
  };

  return (
    <MainPadding>
      <TableHeader
        title="List Pembelian Sudah di Konfirmasi"
        searchBar
        onSearch={(e) => setKeyword(e)}
        filterLabel="Cari Pembelian"
      />
      <TableFC
        loading={loadingPurchase}
        data={dataPurchase}
        showIndex
        body={bodyTable}
        head={header}
        totalRows={dataRawPurchase?.data?.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          setSelectedInvoice(e);
          setShowModal(true);
        }}
      />
      <ModalFC
        open={showmodal}
        onClose={() => {
          setSelectedInvoice(undefined);
          setShowModal(false);
        }}
      >
        <FormPembelian
          type="FinishPurchase"
          id={selectedInvoice?.id}
          isLoading={isLoading}
          onSubmit={(e) => handleSubmit(e)}
        />
      </ModalFC>
    </MainPadding>
  );
}
