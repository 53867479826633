import Http from "../../../core/http-axios-react/http/Http";

export const postAddCustomerType = async ({
  customer_type_name,
  amount,
  amount_type,
}: {
  customer_type_name: string;
  amount_type: number;
  amount: number;
}) => {
  const result = await Http.post({
    url: "/customer-type/insert",
    data: {
      customer_type_name,
      amount_type,
      amount,
    },
  });
  return result;
};

export const patchCustomerType = async ({
  customer_type_id,
  customer_type_name,
  amount,
  amount_type,
}: {
  customer_type_id: number;
  customer_type_name: string;
  amount_type: number;
  amount: number;
}) => {
  const result = await Http.post({
    url: "/customer-type/update",
    data: {
      customer_type_id,
      customer_type_name,
      amount,
      amount_type,
    },
  });
  return result;
};

export const postCustomerPrice = async ({
  amount,
  amount_type,
  customer_type_id,
  price,
  product_id,
}: {
  product_id: number;
  customer_type_id: number;
  price: number;
  amount_type: number;
  amount: number;
}) => {
  const result = await Http.post({
    url: "/product-price/insert",
    data: {
      amount,
      amount_type,
      customer_type_id,
      price,
      product_id,
    },
  });
  return result;
};

export const exportFromFileHargaKhusus = async ({
  customer_type_id,
}: {
  customer_type_id: number;
}) => {
  const result = await Http.get({
    url: "/customer-type/price/export",
    params: {
      customer_type_id,
    },
  });
  return result;
};

export const importFromFileHargaKhusus = async ({ file }: { file: File }) => {
  const result = await Http.post({
    url: "/customer-type/price/import",
    data: {
      file,
    },
  });
  return result;
};
