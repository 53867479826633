import styled from "@emotion/styled";

const ContainerPaggination = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
  padding-block: 15px;
  border-top: 2px solid #dedede;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
`;

export { ContainerPaggination };
