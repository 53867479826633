import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ToMoneyFormat, debounce } from "../../utils/Helper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface InputMoneyProps {
  label: string;
  value?: number;
  disabled?: boolean;
  onChange(value: number): void;
}

export interface IOptions {
  text: string;
  value: any;
}

interface InputAutocompleteProps {
  options: InputAutocompleteOptions[];
  label: string;
  loading?: boolean;
  onChange(item: InputAutocompleteOptions | null): void;
  onSearch?(text: string): void;
  defaultValue?: any;
  value?: any;
  disabled?: boolean;
  width?: number;
  clearAfterInput?: boolean;
}

export interface InputAutocompleteOptions {
  label: string;
  id: any;
}

const InputMoney = ({ value, onChange, label, disabled }: InputMoneyProps) => {
  const [amount, setAmount] = useState<string>("");

  useEffect(() => {
    if (value) {
      const target = value.toString().replaceAll(".", "");
      const temp = ToMoneyFormat(target);
      setAmount(temp || "");
    } else {
      setAmount("");
    }
  }, [value]);

  function handleOnChage(e: string) {
    const target = e.replaceAll(".", "");
    const temp = ToMoneyFormat(target);
    setAmount(temp || "");
    onChange(target ? parseInt(target) : 0);
  }

  return (
    <TextField
      value={amount}
      label={label}
      fullWidth
      onChange={(e) => handleOnChage(e.target.value)}
      disabled={disabled}
    />
  );
};

const InputSelect = ({
  id,
  label,
  value,
  options,
  onChange,
}: {
  id: string | number;
  label: string;
  value: any;
  options: IOptions[];
  onChange(item: any, collection: IOptions): void;
}) => {
  function handleChange(e: any) {
    const temp = options.filter((item) => item.value === e);
    onChange(e, temp[0]);
  }

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id={`select-input-${id}`}>{label}</InputLabel>
      <Select
        value={value}
        labelId={`select-input-${id}`}
        id={`select-input-${id}`}
        fullWidth
        label={label}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const NewAutoComplete = ({
  width,
  options,
  label,
  onChange,
  defaultValue,
  onSearch,
  value,
  loading,
  disabled,
  clearAfterInput,
}: InputAutocompleteProps) => {
  const [defaultValueState, setDefaultValueState] = useState<any>(options[0]);

  useEffect(() => {
    if (defaultValue && options) {
      setDefaultValueState(defaultValue);
    }
  }, [defaultValue, options]);

  const handleOnChange = debounce((value: string) => {
    if (onSearch) {
      onSearch(value);
    }
  }, 250);

  return (
    <Box>
      <Autocomplete
        defaultValue={defaultValueState}
        disabled={disabled}
        loading={loading}
        fullWidth
        disablePortal
        options={options}
        sx={{ width: width }}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onChange={(e) => handleOnChange(e.target.value)}
          />
        )}
        onChange={(e, v) => {
          onChange(v);
        }}
      />
    </Box>
  );
};

const AutoCompleteSelect = ({
  width,
  options,
  label,
  onChange,
  onSearch,
  value,
  loading,
  disabled,
}: InputAutocompleteProps) => {
  return (
    <Box>
      <Autocomplete
        defaultValue={value}
        disabled={disabled}
        loading={loading}
        fullWidth
        disablePortal
        options={options}
        sx={{ width: width }}
        value={value === null ? { id: null, label: "" } : value}
        renderInput={(params) => (
          <TextField
            {...params}
            value={"baru"}
            label={label}
            onChange={(e) => onSearch && onSearch(e.target.value)}
          />
        )}
        onChange={(e, v) => onChange(v)}
      />
    </Box>
  );
};

interface IInputRangeData {
  startDate?: string;
  endDate?: string;
  startLabel?: string;
  endLabel?: string;
  onChange(startDate: string, endDate: string): void;
}

const InputRangeDate = ({
  startLabel,
  startDate,
  endLabel,
  endDate,
  onChange,
}: IInputRangeData) => {
  const [startDateValue, setStartDateValue] = useState<any>("");
  const [endDateValue, setEndDateValue] = useState<string>("");

  useEffect(() => {
    if (startDateValue || endDateValue) {
      onChange(startDateValue, endDateValue);
    }
  }, [startDateValue, endDateValue]);

  function handleChangeInput(e: any, type: "start" | "end") {
    const date = new Date(e.$d).toISOString();
    if (type === "start") {
      setStartDateValue(date);
    } else {
      setEndDateValue(date);
    }
  }

  return (
    <Box display={"flex"} gap={1}>
      <DatePicker
        label={startLabel || "Start"}
        onChange={(e) => handleChangeInput(e, "start")}
        format="D-MM-YYYY"
        // value={new Date()}
        // value={startDateValue}
      />
      <DatePicker
        label={endLabel || "End"}
        onChange={(e) => handleChangeInput(e, "end")}
        format="D-MM-YYYY"
        // value={endDate}
      />
    </Box>
  );
};

export {
  InputMoney,
  InputSelect,
  AutoCompleteSelect,
  InputRangeDate,
  NewAutoComplete,
};
