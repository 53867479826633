import { AddCircleOutlineRounded } from "@mui/icons-material";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  AutoCompleteSelect,
  InputAutocompleteOptions,
} from "../../../components/input/input";
import { useEffect, useState } from "react";
import { useGetProduct } from "../../produk/network/resolver";
import { useToast } from "../../../context/toast/ToastContext";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { IInvoiceDetail } from "../Invoice.type";
import Spinner from "../../../components/spinner/Spinner";
import { ToMoneyFormat } from "../../../utils/Helper";

interface IProductPrice {
  customerTypeId: number;
  invoice?: IInvoiceDetail;
  isOrdered?: boolean;
  isAdd?: boolean;
  isPreOrder?: boolean;
  isPlacedOrder?: boolean;
  onChange?(item: IAddedProduct[]): void;
}

interface IAddedProduct {
  name: string;
  product_id: number;
  price: number;
  quantity: number | null;
  stock: number;
  unitType: string;
}

export default function ProductPrice({
  invoice,
  customerTypeId,
  isAdd,
  isPlacedOrder,
  onChange,
}: IProductPrice) {
  const toast = useToast();
  const [productQty, setProductQty] = useState<number>();
  const [keywordProduct, setKeywordProduct] = useState("");
  const [listAddedProduct, setListAddedProduct] = useState<IAddedProduct[]>([]);
  const { data: dataRawProduct, isLoading: loadingProduct } = useGetProduct({
    keyword: keywordProduct,
    customer_type_id: customerTypeId,
    enabledQuery: customerTypeId !== undefined,
    is_under_threshold: 0,
  });
  const dataProduct = dataRawProduct?.data?.data?.data;
  const [dataProdcutOptions, setDataProductOptions] = useState<
    InputAutocompleteOptions[]
  >([]);
  const [selectedProduct, setSelectedProduct] =
    useState<InputAutocompleteOptions | null>(null);

  useEffect(() => {
    if (!isAdd) {
      if (invoice) {
        const temp: IAddedProduct[] = [];
        invoice.invoice_detail.forEach((item) => {
          temp.push({
            name: item.product.name,
            price: item.price,
            //quantity: item.quantity,
            quantity: 0,
            product_id: item.product_id,
            stock: item.product.stock,
            unitType: item.unit_type,
          });
        });
        setListAddedProduct(temp);
      }
    }
  }, [isAdd, invoice]);

  useEffect(() => {
    if (dataProduct) {
      const temp: InputAutocompleteOptions[] = [];
      dataProduct.forEach((item) => {
        temp.push({
          id: item.id,
          label: item.name,
        });
      });
      setDataProductOptions(temp);
    }
  }, [dataProduct]);

  useEffect(() => {
    if (onChange && listAddedProduct.length > 0) {
      onChange(listAddedProduct);
    }
  }, [listAddedProduct]);

  function addProductList() {
    if (!selectedProduct || !productQty) {
      toast.show("Mohon memilih barang");
      return;
    }
    const temp = [...listAddedProduct];
    const isExist = temp.filter(
      (item) => item.product_id === selectedProduct.id
    );
    if (isExist.length > 0) {
      toast.show("Barang ini sudah ada");
      return;
    }
    const tempProduct = dataProduct?.filter(
      (item) => item.id === selectedProduct.id
    )[0];
    // if (tempProduct) {
    //   if (!isPreOrder && productQty > tempProduct.stock) {
    //     toast.show(`Stock tidak cukup, tersedia: ${tempProduct.stock}`);
    //     return;
    //   }
    // }
    temp.push({
      product_id: selectedProduct.id,
      quantity: productQty,
      name: selectedProduct.label,
      price: (tempProduct && tempProduct.price) || 0,
      stock: (tempProduct && tempProduct.stock) || 0,
      unitType: tempProduct?.unit_type || "",
    });
    setListAddedProduct(temp);
    setSelectedProduct(null);
    setProductQty(0);
  }

  function handleChangeProductListAmount(
    idProduct: number,
    value: number | null
  ) {
    // if (!isPreOrder && value > originQty) {
    //   const msg = !isAdd
    //     ? "Jumlah Konfirmasi lebih besar dari pesanan"
    //     : "Jumlah melebihi stock";
    //   toast.show(msg);
    // }
    setListAddedProduct((prev) =>
      prev.map((item) => {
        if (item.product_id === idProduct) {
          item.quantity = value;
        }
        return item;
      })
    );
  }

  function removeProductList(id: number) {
    setListAddedProduct((prev) =>
      prev.filter((item) => item.product_id !== id)
    );
  }

  if (!isAdd && !invoice) {
    return <Spinner />;
  }

  return (
    <div>
      {!invoice && (
        <Grid container marginTop={2} spacing={1} alignItems={"center"}>
          <Grid item xs={8}>
            <AutoCompleteSelect
              loading={loadingProduct}
              label="Nama Barang"
              options={dataProdcutOptions}
              value={selectedProduct}
              onSearch={(e) => setKeywordProduct(e)}
              onChange={(e) => setSelectedProduct(e)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              onWheel={(e: any) => e.target.blur()}
              value={productQty}
              onChange={(e) => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : undefined;
                setProductQty(value);
              }}
              type="number"
              label="Jumlah"
              placeholder="Jumlah"
            />
          </Grid>
          <Grid item xs={1}>
            <Box style={{ cursor: "pointer" }} onClick={addProductList}>
              <AddCircleOutlineRounded fontSize="large" />
            </Box>
          </Grid>
        </Grid>
      )}
      <TableContainer component={Paper} style={{ marginTop: 15 }}>
        <Table>
          <TableHead>
            <TableRow>
              {!invoice && <TableCell>Hapus</TableCell>}
              <TableCell>Nama Barang</TableCell>
              <TableCell>Satuan</TableCell>
              {!isPlacedOrder && <TableCell>Stock</TableCell>}
              <TableCell>Harga</TableCell>
              {invoice && <TableCell>Pesanan</TableCell>}
              {!isPlacedOrder && (
                <TableCell>
                  {invoice ? "Konfirmasi Pesanan" : "Jumlah"}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {listAddedProduct.map((item, index) => {
              return (
                <TableRow key={index}>
                  {!invoice && (
                    <TableCell>
                      <Box
                        marginLeft={1}
                        display={"flex"}
                        style={{ cursor: "pointer" }}
                        onClick={() => removeProductList(item.product_id)}
                      >
                        <RemoveCircleOutlineOutlined
                          fontSize="medium"
                          color="error"
                        />
                      </Box>
                    </TableCell>
                  )}
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.unitType}</TableCell>
                  {!isPlacedOrder && <TableCell>{item.stock}</TableCell>}
                  <TableCell>{ToMoneyFormat(item.price)}</TableCell>
                  {invoice && (
                    <TableCell>
                      {invoice.invoice_detail[index].quantity}
                    </TableCell>
                  )}
                  {!isPlacedOrder && (
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder={invoice ? "Konfirmasi Pesanan" : "Jumlah"}
                        disabled={
                          invoice?.status === 4 || invoice?.status === 3
                        }
                        type="number"
                        variant="standard"
                        value={item.quantity}
                        onChange={(e) => {
                          const value = e.target.value
                            ? parseFloat(e.target.value)
                            : null;
                          handleChangeProductListAmount(item.product_id, value);
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
