import { MainPadding } from "../../styles/Main.styles";
import {
  useDeleteProduct,
  useExportFromFile,
  useGetCustomerType,
  useGetProduct,
  useImportFromFile,
} from "./network/resolver";
import { useEffect, useState } from "react";
import TableFC, { TableFCBodyProps } from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import ModalProduk from "./components/Modal";
import { IProductData } from "./Produk.type";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { IOptions, InputSelect } from "../../components/input/input";
import ModalDetail from "./components/ModalDetail";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useUserInformation } from "../../context/userInformation/UserInformationContext";
import { LoadingButton } from "../../components/button/Button";

export default function Produk() {
  const userInformation = useUserInformation();
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState<"Add" | "Detail" | null>(
    null
  );
  const [optionsCustomerType, setOptionsCustomerType] = useState<IOptions[]>(
    []
  );
  const [selectedCustomer, setSelectedCustomer] = useState<IOptions | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] = useState<IProductData>();
  const [productThreshold, setProductThreshold] = useState(0);
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
  const { data: dataRawProduct, isLoading: loadingProduct } = useGetProduct({
    keyword,
    page,
    customer_type_id: selectedCustomer?.value,
    enabledQuery: selectedCustomer !== undefined,
    is_under_threshold: productThreshold,
    perPage,
  });
  const dataProduct = dataRawProduct?.data?.data?.data;
  const { data: dataRawCustomerType } = useGetCustomerType({
    keyword: "",
  });
  const dataCustomerType = dataRawCustomerType?.data?.data?.data;
  const { mutate: mutateImportFile, isLoading: loadingImport } =
    useImportFromFile();
  const { mutate: mutateDelete } = useDeleteProduct();
  const { mutate: mutateExport, isLoading: loadingExport } =
    useExportFromFile();
  const [header, setHeader] = useState<string[]>([
    "No",
    "Nama",
    "Harga",
    "Satuan",
    "Stock",
  ]);
  const [bodyTable, setBodyTable] = useState<TableFCBodyProps[]>([
    { key: "name" },
    { key: "price", isMoney: true },
    { key: "unit_type" },
    { key: "stock" },
  ]);

  useEffect(() => {
    if (userInformation.get()) {
      const isAdmin = userInformation.get()?.role === 1;
      if (isAdmin) {
        setIsAdmin(true);
        setHeader((prev: any) => {
          if (prev.filter((item: any) => item === "Hapus").length > 0) {
            return prev;
          }
          return [...prev, "Hapus"];
        });
        setBodyTable((prev) => {
          if (prev.filter((item) => item.key === "action").length > 0) {
            return prev;
          }
          return [
            ...prev,
            {
              key: "action",
              show: isAdmin,
              component: (
                <Box marginLeft={1}>
                  <DeleteForeverIcon />
                </Box>
              ),
              onClick: (e) => {
                if (confirm("Apakah anda yakin ?")) {
                  mutateDelete({ id_product: e.id });
                }
              },
            },
          ];
        });
      }
    }
  }, [userInformation.get()]);

  useEffect(() => {
    if (dataCustomerType) {
      const temp: IOptions[] = [];
      dataCustomerType.map((item) => {
        temp.push({
          value: item.id,
          text: item.customer_type_name,
        });
      });
      setOptionsCustomerType(temp);
    }
  }, [dataCustomerType]);

  const RenderSelect = () => {
    return (
      <Box marginTop={2}>
        <InputSelect
          label="Pilih Customer"
          options={optionsCustomerType}
          value={selectedCustomer?.value}
          onChange={(e, item) => setSelectedCustomer(item)}
          id={"customer-type-search"}
        />
        <Box marginTop={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={productThreshold === 1}
                onChange={(e, v) => {
                  const value = v ? 1 : 0;
                  setProductThreshold(value);
                }}
              />
            }
            label="Tampilkan Barang yang akan habis"
          />
        </Box>
      </Box>
    );
  };

  const RenderButton = () => {
    return (
      <Box marginTop={2} display={"flex"} gap={1}>
        <LoadingButton
          isLoading={loadingImport}
          isUpload
          accept=".csv"
          text="Upload"
          variant="contained"
          color="success"
          onUpload={(files) => {
            if (files) {
              mutateImportFile({ file: files[0] });
            }
          }}
        />
        <LoadingButton
          isLoading={loadingExport}
          variant="contained"
          color="secondary"
          text="Download"
          onClick={() => mutateExport()}
        />
      </Box>
    );
  };

  return (
    <MainPadding>
      <TableHeader
        title="List Produk"
        searchBar
        addButton={isAdmin}
        onClickAdd={() => isAdmin && setModalVisible("Add")}
        onSearch={(e) => {
          setPage(1);
          setKeyword(e);
        }}
        filterLabel="Cari Nama Produk"
        renderFilter={
          <Box>
            <RenderSelect />
            <RenderButton />
          </Box>
        }
      />
      <TableFC
        loading={loadingProduct}
        body={bodyTable}
        data={dataProduct}
        head={header}
        showIndex
        totalRows={dataRawProduct?.data?.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        pagginationAt={page}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          if (isAdmin) {
            setSelectedProduct(e);
            setModalVisible("Detail");
          }
        }}
      />
      <ModalProduk
        visible={modalVisible === "Add"}
        onClose={() => {
          setSelectedProduct(undefined);
          setModalVisible(null);
        }}
      />
      {selectedProduct && (
        <ModalDetail
          product={selectedProduct}
          visible={modalVisible == "Detail"}
          onClose={() => {
            setSelectedProduct(undefined);
            setModalVisible(null);
          }}
        />
      )}
    </MainPadding>
  );
}
