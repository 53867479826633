function getValueFromEnv(key: string) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    const temp = `VITE_${key}`;
    return import.meta.env[`${temp}`];
  } else {
    const temp = `REACT_APP_${key}`;
    return process.env[`${temp}`];
  }
}

export const ENV_LISTS = {
  API_URL: getValueFromEnv("API_URL"),
  KEY_TOKEN: getValueFromEnv("KEY_TOKEN"),
  KEY_USER_INFO: getValueFromEnv('KEY_USER_INFO')
};
