import { Box, Modal, TextField, Typography } from "@mui/material";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import { useGetCustomerType } from "../../produk/network/resolver";
import {
  IOptions,
  InputAutocompleteOptions,
} from "../../../components/input/input";
import { useCallback, useEffect, useState } from "react";
import { AutoCompleteSelect } from "../../../components/input/input";
import { useToast } from "../../../context/toast/ToastContext";
import { LoadingButton } from "../../../components/button/Button";
import { useGetDetailInvoice } from "../network/resolver";
import { FromStringToDate, ToMoneyFormat } from "../../../utils/Helper";
import { IInvoiceData } from "../Invoice.type";
import ProductPrice from "./ProductPrice";
import { STATUS_INVOICE_OPTIONS } from "./SelectStatusInvoice";
import SelectPrintType from "./SelectPrintType";
import useSubmitInvoice, { IAddedProduct } from "../hooks/SubmitInvoice";
import Spinner from "../../../components/spinner/Spinner";
import { useNavigate } from "react-router";

interface IModalInvoice {
  visible: boolean;
  invoice?: IInvoiceData;
  idInvoice?: number;
  idInvoiceParam?: number;
  invoicePrintOptions: IOptions[];
  isAdd?: boolean;
  isPreOrder?: boolean;
  isPlacedOrder?: boolean;
  hidePrint?: boolean;
  onClose(): void;
}

export default function ModalInvoice({
  visible,
  invoice,
  idInvoice,
  idInvoiceParam,
  invoicePrintOptions,
  isAdd,
  isPlacedOrder,
  hidePrint,
  onClose,
}: IModalInvoice) {
  const navigate = useNavigate();
  const toast = useToast();
  const [dataCustomerTypeOptions, setDataCustomerTypeOptions] = useState<
    InputAutocompleteOptions[]
  >([]);
  const [selectedCustomerType, setSelectedCustomerType] =
    useState<InputAutocompleteOptions | null>(null);
  const [listAddedProduct, setListAddedProduct] = useState<IAddedProduct[]>([]);
  const [namaToko, setNamaToko] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tax, setTax] = useState(0);
  const [pph, setPph] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState<number>();
  const [invoiceType] = useState<IOptions>(STATUS_INVOICE_OPTIONS[0]);
  const { data: dataRawInvoice } = useGetDetailInvoice({
    invoice_id: idInvoice,
  });
  const dataInvoice = dataRawInvoice?.data?.data;
  const { data: dataRawCustomerType, isLoading: loadingCustomerType } =
    useGetCustomerType({});
  const dataCustomerType = dataRawCustomerType?.data?.data?.data;
  const { addInvoice, updateStatus, splitInvoice, loading } =
    useSubmitInvoice();
  const isTicketOrPreOrder =
    invoice?.status === 1 || invoice?.status == 2 || !invoice;
  const shouldDisabled = dataInvoice?.status === 4 || dataInvoice?.status === 3;

  useEffect(() => {
    if (dataCustomerType) {
      const temp: InputAutocompleteOptions[] = [];
      dataCustomerType.forEach((item) => {
        temp.push({
          id: item.id,
          label: item.customer_type_name,
        });
      });
      setDataCustomerTypeOptions(temp);
    }
  }, [dataCustomerType]);

  useEffect(() => {
    setNamaToko(dataInvoice?.shop_name || "");
    setNamaCustomer(dataInvoice?.customer_name || "");
    setTax(dataInvoice?.tax_percentage || 0);
    setPph(dataInvoice?.pph_percentage || 0);
    const temp: IAddedProduct[] = [];
    dataInvoice?.invoice_detail.map((item) => {
      temp.push({
        name: item.product.name,
        price: item.price,
        product_id: item.product_id,
        quantity: item.quantity,
      });
    });
    setListAddedProduct(temp);
  }, [dataInvoice]);

  useEffect(() => {
    if (dataInvoice && !loadingCustomerType) {
      const target = dataCustomerTypeOptions.filter(
        (item) => item.id === dataInvoice.customer_type_id
      );
      setSelectedCustomerType(target[0]);
    }
  }, [dataCustomerTypeOptions, dataInvoice, loadingCustomerType]);

  const callbackTotalRp = useCallback(() => {
    let temp = 0;
    listAddedProduct.forEach((item) => {
      if (item.quantity) {
        temp += item.price * item.quantity;
      }
    });
    return temp;
  }, [listAddedProduct]);

  const callbackTotalTax = useCallback(() => {
    let temp = 0;
    listAddedProduct.forEach((item) => {
      if (item.quantity) {
        temp += item.price * item.quantity;
      }
    });
    return Math.round(temp * (tax / 100));
  }, [listAddedProduct, tax]);

  const calculateGrandTotal = () => {
    let total = callbackTotalRp();
    const totalWithPph = Math.round(total - total * (pph / 100));
    const temp = total - totalWithPph;
    return {
      total: totalWithPph,
      pph: temp,
    };
  };

  async function handleSubmit() {
    if (!selectedCustomerType || !namaCustomer || !namaToko) {
      toast.show("Mohon melengkapi field");
      return;
    }
    // Handle Update
    if (idInvoice && dataInvoice) {
      // if (dataInvoice.status !== 3) {
      //   let isOverConfirm = false;
      //   let msg = "Mohon Periksa Jumlah Konfirmasi Pesanan Kembali";
      //   dataInvoice.invoice_detail.forEach((item) => {
      //     const temp = listAddedProduct.find(
      //       (itemT) => itemT.product_id === item.product_id
      //     );
      //     if (temp?.quantity === null) {
      //       isOverConfirm = true;
      //     } else if (temp && temp.quantity != null) {
      //       if (temp.quantity > item.quantity) {
      //         isOverConfirm = true;
      //       } else if (item.product.stock < temp.quantity) {
      //         msg = "Stock Tidak Cukup";
      //         isOverConfirm = true;
      //       }
      //     }
      //   });
      //   if (isOverConfirm) {
      //     toast.show(msg);
      //     return;
      //   }
      // }
      if (dataInvoice.status === 1 || dataInvoice.status === 2) {
        await splitInvoice({
          dataInvoice: dataInvoice,
          listAddedProduct: listAddedProduct,
          invoice_id: dataInvoice.id,
        });
      } else {
        await updateStatus({
          invoice_number: invoiceNumber?.toString() || "",
          invoice_id: dataInvoice.id,
          status: STATUS_INVOICE_OPTIONS[3].value, // Lunas,
        });
      }
    } else {
      // Handle Add
      await addInvoice({
        customer_name: namaCustomer,
        customer_type_id: selectedCustomerType.id,
        invoice_date: FromStringToDate(new Date().toISOString(), true),
        listAddedProduct: listAddedProduct,
        shop_name: namaToko,
        tax_percentage: tax,
        pph_percentage: pph,
        is_pre_order: invoiceType?.value === 2,
        is_ticket: invoiceType?.value === 1,
        invoice_number: invoiceNumber || 0,
      });
    }
    // remove param, when passed through url and modal is closed
    if (idInvoiceParam) {
      navigate("/invoice/pre-order");
    }
    onClose();
  }

  if (loadingCustomerType) {
    return idInvoiceParam ? <Spinner /> : null;
  }

  return (
    <Modal
      style={{ height: "100%" }}
      open={visible}
      onClose={() => {
        if (idInvoiceParam) {
          navigate("/invoice/pre-order");
        }
        onClose();
      }}
    >
      <MainContainerModal>
        <MainContainerWrapper>
          <div>
            <Typography variant="h6">{dataInvoice?.invoice_number}</Typography>
            {dataInvoice && !hidePrint && (
              <Box marginTop={3}>
                <SelectPrintType
                  invoice={dataInvoice}
                  invoicePrintOptions={invoicePrintOptions}
                />
              </Box>
            )}
            <Box marginTop={2}>
              {dataInvoice?.status === 3 ? (
                <TextField
                  type="number"
                  label="Nomor Invoice (Max: 4 Karakter)"
                  placeholder="Nomor Invoice"
                  value={invoiceNumber}
                  onChange={(e) => {
                    const temp = e.target.value;
                    if (temp.length <= 4) {
                      setInvoiceNumber(parseInt(e.target.value));
                    }
                  }}
                  fullWidth
                />
              ) : null}
            </Box>
            <Box marginTop={3}>
              <AutoCompleteSelect
                label="Tipe Customer"
                options={dataCustomerTypeOptions}
                value={selectedCustomerType}
                onChange={(e) => setSelectedCustomerType(e)}
                disabled={shouldDisabled}
              />
            </Box>
            <Box marginTop={2}>
              <TextField
                label="Nama Toko"
                placeholder="Nama Toko"
                value={namaToko}
                onChange={(e) => setNamaToko(e.target.value)}
                fullWidth
                disabled={shouldDisabled}
              />
            </Box>
            <Box marginTop={2}>
              <TextField
                label="Nama Customer"
                placeholder="Nama Customer"
                value={namaCustomer}
                onChange={(e) => setNamaCustomer(e.target.value)}
                fullWidth
                disabled={shouldDisabled}
              />
            </Box>
            <Box marginTop={2}>
              <TextField
                label="Tax"
                placeholder="Tax"
                value={tax}
                onChange={(e) => {
                  const value = e.target.value ? parseInt(e.target.value) : 0;
                  setTax(value);
                }}
                fullWidth
                disabled={shouldDisabled}
              />
            </Box>
            <Box marginTop={2}>
              <TextField
                label="Pph"
                placeholder="Pph"
                value={pph}
                onChange={(e) => {
                  const value = e.target.value ? parseInt(e.target.value) : 0;
                  setPph(value);
                }}
                fullWidth
                disabled={shouldDisabled}
              />
            </Box>
            <ProductPrice
              isPlacedOrder={isPlacedOrder}
              isPreOrder={invoiceType.value === 2}
              isAdd={isAdd}
              invoice={dataInvoice}
              customerTypeId={selectedCustomerType?.id}
              onChange={(e) => {
                setListAddedProduct(e);
              }}
            />
            <Box
              marginTop={3}
              display={"flex"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <Box marginTop={1} marginBottom={1}>
                <Typography variant="h6">
                  Tax :{" "}
                  {ToMoneyFormat(
                    dataInvoice?.tax ? dataInvoice.tax : callbackTotalTax()
                  )}
                </Typography>
              </Box>
              <Box marginTop={1} marginBottom={1}>
                <Typography variant="h6">
                  Pph :{" "}
                  {ToMoneyFormat(
                    dataInvoice?.pph
                      ? dataInvoice.pph
                      : calculateGrandTotal().pph
                  )}
                </Typography>
              </Box>
              <Box marginTop={1} marginBottom={1}>
                <Typography variant="h6">
                  Total :{" "}
                  {ToMoneyFormat(
                    dataInvoice?.grand_total
                      ? dataInvoice.grand_total
                      : calculateGrandTotal().total + callbackTotalTax()
                  )}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"}>
              {dataInvoice?.status !== 4 && (
                <LoadingButton
                  text={isTicketOrPreOrder ? "Submit" : "Lunas"}
                  isLoading={loading}
                  onClick={handleSubmit}
                />
              )}
            </Box>
          </div>
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
