import { Modal } from "@mui/material";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../styles/Main.styles";

interface IModalFC {
  open: boolean;
  onClose(): void;
  children: JSX.Element | JSX.Element[];
}

export const ModalFC = ({ onClose, open, children }: IModalFC) => {
  return (
    <Modal open={open} onClose={onClose}>
      <MainContainerModal>
        <MainContainerWrapper>{children}</MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
};
