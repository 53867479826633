import { useEffect, useState } from "react";
import TableFC, { TableFCBodyProps } from "../../components/table/Table";
import { MainPadding } from "../../styles/Main.styles";
import TableHeader from "../../components/table/TableHeader";
import { useDeleteUser, useGetUsers } from "./network/resolver";
import ModalAdd from "./components/ModalAdd";
import { IUserData } from "./type/user";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box } from "@mui/material";
import { useUserInformation } from "../../context/userInformation/UserInformationContext";

export default function User() {
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserData | undefined>(
    undefined
  );
  const { data: dataRawUsers, isLoading: loadingUsers } = useGetUsers({
    keyword,
    page,
    perPage,
  });
  const dataUsers = dataRawUsers?.data?.data?.data;
  const userInformation = useUserInformation();
  const [_, setIsAdmin] = useState<boolean | undefined>(undefined);
  const [header, setHeader] = useState<string[]>([
    "No",
    "Username",
    "Name",
    "Email",
    "Role",
  ]);
  const [bodyTable, setBodyTable] = useState<TableFCBodyProps[]>([
    { key: "username" },
    { key: "name" },
    { key: "email" },
    {
      key: "role",
      isMap: [
        { from: 1, to: "Admin" },
        { from: 2, to: "Staff" },
      ],
    },
  ]);
  const { mutate: deleteUser } = useDeleteUser();

  useEffect(() => {
    if (userInformation.get()) {
      const isAdmin = userInformation.get()?.role === 1;
      if (isAdmin) {
        setIsAdmin(true);
        setHeader((prev: any) => {
          if (prev.filter((item: any) => item === "Hapus").length > 0) {
            return prev;
          }
          return [...prev, "Hapus"];
        });
        setBodyTable((prev) => {
          if (prev.filter((item) => item.key === "action").length > 0) {
            return prev;
          }
          return [
            ...prev,
            {
              key: "action",
              show: isAdmin,
              component: (
                <Box marginLeft={1}>
                  <DeleteForeverIcon />
                </Box>
              ),
              onClick: (e) => {
                if (confirm("Apakah anda yakin ?")) {
                  deleteUser({ user_id: e.id });
                }
              },
            },
          ];
        });
      }
    }
  }, [userInformation.get()]);

  return (
    <MainPadding>
      <TableHeader
        title="List Users"
        addButton
        filterLabel="Search User"
        searchBar
        onSearch={(e) => setKeyword(e)}
        onClickAdd={() => setModalVisible(true)}
      />
      <TableFC
        loading={loadingUsers}
        data={dataUsers}
        body={bodyTable}
        showIndex
        totalRows={dataRawUsers?.data.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        head={header}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          setSelectedUser(e);
          setModalVisible(true);
        }}
      />
      <ModalAdd
        user={selectedUser}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          setSelectedUser(undefined);
        }}
      />
    </MainPadding>
  );
}
