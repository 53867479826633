import { Box } from "@mui/material";
import { IOptions, InputSelect } from "../../../components/input/input";
import { useEffect, useState } from "react";

interface ISelectProductDiscount {
  onChange(item: IOptions): void;
  value?: IOptions;
  discountValue?: number;
}

export const PRODUCT_DISCOUNT_OPTIONS: IOptions[] = [
  { text: "Fixed", value: 1 },
  { text: "Percentage", value: 2 },
];

export default function SelectProductDiscount({
  onChange,
  value,
  discountValue,
}: ISelectProductDiscount) {
  const [selected, setSelected] = useState<IOptions>(
    PRODUCT_DISCOUNT_OPTIONS[0]
  );

  useEffect(() => {
    if (value) {
      setSelected(value);
    } else if (discountValue) {
      const temp = PRODUCT_DISCOUNT_OPTIONS.filter(
        (item) => item.value === discountValue
      );
      setSelected(temp[0]);
    }
  }, [value, discountValue]);

  return (
    <Box>
      <InputSelect
        id={"product-discount"}
        label="Tipe"
        options={PRODUCT_DISCOUNT_OPTIONS}
        value={selected.value}
        onChange={(e, item) => {
          setSelected(item);
          onChange(item);
        }}
      />
    </Box>
  );
}
