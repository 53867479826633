import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import {
  ICustomerType,
  IProduct,
  IProductDetail,
  IProductHistory,
} from "../Produk.type";

export interface IPriceList {
  customer_type_id: number;
  price: number;
}

export const getProduct = async ({
  keyword,
  page,
  perPage,
  customer_type_id,
  is_under_threshold,
}: HttpBaseRequest<{
  customer_type_id?: number;
  is_under_threshold: number;
}>) => {
  const results = await Http.get<HttpBaseResponse<IProduct>>({
    url: "/product",
    params: {
      keyword,
      page,
      perPage,
      customer_type_id,
      is_under_threshold,
    },
  });
  return results;
};

export const getProductDetail = async ({
  productId,
}: {
  productId: number;
}) => {
  const result = await Http.get<HttpBaseResponse<IProductDetail>>({
    url: "/product/detail",
    params: {
      product_id: productId,
    },
  });
  return result;
};

export const getCustomerType = async ({ keyword }: HttpBaseRequest) => {
  const results = await Http.get<HttpBaseResponse<ICustomerType>>({
    url: "/customer-type",
    params: {
      keyword,
    },
  });
  return results;
};

export const postAddProduct = async ({
  name,
  price,
  stock,
  unit_type,
  buying_price,
  priceList,
  stock_threshold,
}: {
  name: string;
  price: number;
  stock: number;
  unit_type: string;
  buying_price: number;
  priceList?: IPriceList[];
  stock_threshold: number;
}) => {
  const results = await Http.post({
    url: "/product/insert",
    useFormMultiPart: false,
    data: {
      name,
      price,
      stock,
      unit_type,
      buying_price,
      priceList,
      stock_threshold,
    },
  });
  return results;
};

export const patchProduct = async ({
  product_id,
  name,
  price,
  stock,
  unit_type,
  buying_price,
  priceList,
  stock_threshold,
}: {
  product_id: number;
  name: string;
  price: number;
  stock: number;
  unit_type: string;
  buying_price: number;
  priceList?: IPriceList[];
  stock_threshold: number;
}) => {
  const result = await Http.post({
    url: "/product/update",
    useFormMultiPart: false,
    data: {
      product_id,
      name,
      price,
      stock,
      unit_type,
      buying_price,
      priceList,
      stock_threshold,
    },
  });
  return result;
};

export const deleteProduct = async ({ id_product }: { id_product: number }) => {
  const result = await Http.delete({
    url: "/product/delete",
    useFormMultiPart: false,
    data: {
      product_id: id_product,
    },
  });
  return result;
};

export const patchStockProduct = async ({
  product_id,
  stock,
}: {
  product_id: number;
  stock: number;
}) => {
  const result = await Http.post({
    url: "/product/stock",
    data: {
      product_id,
      stock,
    },
  });
  return result;
};

export const postCustomerPrice = async ({
  amount,
  amount_type,
  customer_type_id,
  product_id,
}: {
  product_id: number;
  customer_type_id: number;
  amount_type: number;
  amount: number;
}) => {
  const result = await Http.post({
    url: "/product-price/insert",
    data: {
      amount,
      amount_type,
      customer_type_id,
      product_id,
      price: amount,
    },
  });
  return result;
};

export const patchCustomerPrice = async ({
  product_price_id,
  amount,
  amount_type,
}: {
  product_price_id: number;
  amount: number;
  amount_type: number;
}) => {
  const result = await Http.post({
    url: "/product-price/update",
    data: {
      product_price_id,
      amount,
      amount_type,
    },
  });
  return result;
};

export const deleteCustomerPrice = async ({
  product_price_id,
}: {
  product_price_id: number;
}) => {
  const result = await Http.delete({
    url: "/product-price/delete",
    useFormMultiPart: false,
    data: {
      product_price_id,
    },
  });
  return result;
};

export const importFromFile = async ({ file }: { file: File }) => {
  const result = await Http.post({
    url: "/product/import",
    data: {
      file,
    },
  });
  return result;
};

export const exportFromFile = async () => {
  const result = await Http.get({
    url: "/product/export",
  });
  return result;
};

export const getProductHistory = async ({
  product_id,
  page,
  perPage,
}: {
  product_id: number;
  page: number;
  perPage: number;
}) => {
  const result = await Http.get<HttpBaseResponse<IProductHistory>>({
    url: "/product/stock",
    params: {
      product_id,
      page,
      perPage,
    },
  });
  return result;
};
