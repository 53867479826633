import { useState } from "react";
import TableFC, { TableFCBodyProps } from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import { MainPadding } from "../../styles/Main.styles";
import { useGetCustomerType } from "../produk/network/resolver";
import ModalCustomer from "./component/Modal";
import { ICustomerTypeData } from "../produk/Produk.type";

export default function Customer() {
  const [keywords, setKeyword] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomerTypeData>();
  const [showModal, setShowModal] = useState(false);
  const { data: dataRaw, isLoading: loading } = useGetCustomerType({
    keyword: keywords,
  });
  const dataCustomer = dataRaw?.data?.data?.data;
  const bodyTable: TableFCBodyProps[] = [{ key: "customer_type_name" }];

  return (
    <MainPadding>
      <TableHeader
        title="List Customer Type"
        addButton
        filterLabel="Cari Customer"
        searchBar
        onSearch={(e) => setKeyword(e)}
        onClickAdd={() => setShowModal(true)}
      />
      <TableFC
        showIndex
        body={bodyTable}
        data={dataCustomer}
        head={["No", "Nama"]}
        loading={loading}
        onCellClick={(e) => {
          setSelectedCustomer(e);
          setShowModal(true);
        }}
      />
      <ModalCustomer
        customer={selectedCustomer}
        visible={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedCustomer(undefined);
        }}
      />
    </MainPadding>
  );
}
