import React from "react";
import { AutoCompleteSelect } from "../../../components/input/input";
import { useGetUsers } from "../../user/network/resolver";
import useGenerateOptions from "../../../components/input/hook/useGenerateOptions";
import Spinner from "../../../components/spinner/Spinner";
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "../../../components/button/Button";
import { usePostNotes } from "../network/resolver";

type FormType = {
  message: string;
  due_date: string;
  targeted_user: number;
  status: number;
};

export default function CreateFormNote({ onClose }: { onClose(): void }) {
  const { control } = useForm<FormType>({
    defaultValues: {
      message: "",
      due_date: "",
      targeted_user: 0,
      status: 1,
    },
  });
  const { data: dataRaw, isLoading: loadingUser } = useGetUsers({});
  const dataUser = dataRaw?.data?.data?.data;
  const { data: dataUserOptions } = useGenerateOptions({
    dataSource: dataUser,
    idKey: "id",
    valueKey: "name",
  });
  const { mutateAsync: mutatePost, isLoading: loadingPost } = usePostNotes();
  const isLoading = loadingUser;

  const onSubmit = async () => {
    const user = control._formValues.targeted_user?.id;
    await mutatePost({
      due_date: control._formValues.due_date,
      message: control._formValues.message,
      targeted_user: user,
    });
    onClose();
  };

  if (isLoading) {
    return (
      <Box p={1} m={3}>
        <Spinner />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight={"bold"} marginBottom={2}>
        Create Note
      </Typography>
      <Controller
        name="targeted_user"
        control={control}
        render={({ field }) => (
          <Box display={"flex"} gap={1} width={"100%"}>
            <AutoCompleteSelect
              width={230}
              label="User"
              options={dataUserOptions}
              onChange={(e) => {
                if (e) {
                  field.onChange(e);
                }
              }}
            />
          </Box>
        )}
      ></Controller>
      <Controller
        name="due_date"
        control={control}
        render={({ field }) => (
          <Box marginTop={2} marginBottom={2}>
            <DatePicker
              label={"Deadline"}
              onChange={(e: any) => {
                const date = `${e.$y}-${e.$M + 1}-${e.$D}`;
                field.onChange(date);
              }}
              format="D-MM-YYYY"
            />
          </Box>
        )}
      ></Controller>
      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <Box marginTop={2}>
            <TextField
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
              label="Message"
              placeholder="Message"
              rows={6}
              multiline
            />
          </Box>
        )}
      ></Controller>
      <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
        <LoadingButton
          text="Submit"
          isLoading={loadingPost}
          onClick={onSubmit}
        />
      </Box>
    </React.Fragment>
  );
}
