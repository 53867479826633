import { useState } from "react";
import { TokenStorage } from "../core/http-axios-react/http/Token";

export const useDownloadBlobAsFile = () => {
  const [isLoading, setIsLoading] = useState(false);

  const download = (url: string) => {
    const date = new Date();
    setIsLoading(true);

    // Replace 'your_token' with your actual token
    const token = TokenStorage.get();

    // Fetch the file with the token included in the headers
    fetch(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a link element and set its properties
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Pre-order_${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}.csv`;

        // Append the link to the body and click it
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    download,
  };
};
