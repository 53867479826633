import { Box, Button } from "@mui/material";
import { useGetProductHistory } from "../network/resolver";
import TableFC from "../../../components/table/Table";
import { useState } from "react";
import { IProductHistoryData } from "../Produk.type";
import { ModalFC } from "../../../components/modal/Modal";
import FormFinishPurchaseInvoice from "../../pembelian/form/FormFinishPurchaseInvoice";
import FormFinishOrder from "../../invoice/form/FormFinishOrder";

interface IHistoryStock {
  productId?: number;
}

export default function HistoryStock({ productId }: IHistoryStock) {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showModal, setShowModal] = useState<"Invoice" | "Purchase">();
  const [selectedIdInvoice, setSelectedIdInvoice] = useState<
    number | undefined
  >();
  const [selectedIdPurchase, setSelectedIdPurchase] = useState<
    number | undefined
  >();
  const { data: dataRawHistoryProduct, isLoading: loadingGetProductHistory } =
    useGetProductHistory({
      product_id: productId,
      page,
      perPage,
    });
  const dataHistoryProduct = dataRawHistoryProduct?.data.data.data;
  const header = [
    "No",
    "Jumlah",
    "Tanggal",
    "Customer",
    "Penjual",
    "Toko",
    "Invoice",
  ];
  const bodyTable = [
    { key: "quantity" },
    { key: "created_at", isDate: true },
    { key: "invoice.customer_name" },
    { key: "purchase_invoice.seller_name" },
    {
      key: "",
      newComponent: (item: IProductHistoryData) => {
        return (
          <Box>
            {item.invoice?.shop_name && <Box>{item.invoice?.shop_name}</Box>}
            {item.purchase_invoice?.shop_name && (
              <Box>{item.purchase_invoice?.shop_name}</Box>
            )}
          </Box>
        );
      },
    },
    {
      key: "",
      newComponent: (item: IProductHistoryData) => {
        return (
          <Box width={"100%"}>
            <Button onClick={() => handleClickInvoice(item)} variant="outlined">
              Lihat
            </Button>
          </Box>
        );
      },
    },
  ];

  function handleClickInvoice(item: IProductHistoryData) {
    if (item.invoice_id) {
      setShowModal("Invoice");
      setSelectedIdInvoice(item.invoice_id);
    } else if (item.purchase_invoice_id) {
      setShowModal("Purchase");
      setSelectedIdPurchase(item.purchase_invoice_id);
    }
  }

  return (
    <Box mt={2}>
      <TableFC
        data={dataHistoryProduct}
        showIndex
        body={bodyTable}
        head={header}
        totalRows={dataRawHistoryProduct?.data?.data?.total}
        onPageChange={(e) => setPage(e)}
        loading={loadingGetProductHistory}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
      />
      {selectedIdInvoice && (
        <ModalFC
          open={showModal === "Invoice"}
          onClose={() => setShowModal(undefined)}
        >
          <FormFinishOrder id={selectedIdInvoice} />
        </ModalFC>
      )}
      {selectedIdPurchase && (
        <ModalFC
          open={showModal === "Purchase"}
          onClose={() => setShowModal(undefined)}
        >
          <FormFinishPurchaseInvoice
            type="FinishPurchase"
            id={selectedIdPurchase}
            isLoading={false}
          />
        </ModalFC>
      )}
    </Box>
  );
}
