import { useEffect, useState } from "react";
import { IListProductPrice } from "../features/invoice/component/ProductPrice2";

export const useGenerateAddedProduct = (lists?: any) => {
  const [data, setData] = useState<IListProductPrice[]>([]);

  useEffect(() => {
    if (lists) {
      const temp: IListProductPrice[] = [];
      if (Array.isArray(lists)) {
        lists.forEach((item: any) => {
          temp.push({
            name: item.product.name,
            price: item.price,
            product_id: item.product.id,
            quantity: item.quantity || 0,
            stock: item.product.stock,
            unitType: item.product.unit_type,
            input: 0,
          });
        });
        setData(temp);
      }
    }
  }, [lists]);

  return { data };
};
