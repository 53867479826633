import { createBrowserRouter } from "react-router-dom";
import User from "../features/user/User";
import Login from "../features/login/Login";
import Layout from "../features/layout/Layout";
import Produk from "../features/produk/Produk";
import Customer from "../features/customer";
import PreOrderInvoice from "../features/invoice/pages/PreOrderInvoice";
import BelumLunasInvoice from "../features/invoice/pages/BelumLunas";
import LunasInvoice from "../features/invoice/pages/Lunas";
import ProdukPreOrder from "../features/produk-preOrder/ProdukPreOrder";
import { ListRekap } from "../features/rekap";
import {
  ListPembelianBelumKonfirmasi,
  ListPembelianSelesai,
  ListPembelianSudahKonfirmasi,
} from "../features/pembelian";
import { ListNote } from "../features/note";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ListNote />,
      },
      {
        path: "/user",
        element: <User />,
      },
      {
        path: "/produk",
        element: <Produk />,
      },
      {
        path: "/produk/pre-order",
        element: <ProdukPreOrder />,
      },
      {
        path: "/invoice/pre-order/:idInvoice?",
        element: <PreOrderInvoice />,
      },
      {
        path: "/invoice/belum-lunas",
        element: <BelumLunasInvoice />,
      },
      {
        path: "/invoice/lunas",
        element: <LunasInvoice />,
      },
      {
        path: "/customer",
        element: <Customer />,
      },
      {
        path: "/rekap",
        element: <ListRekap />,
      },
      {
        path: "/pembelian/belum-konfirmasi",
        element: <ListPembelianBelumKonfirmasi />,
      },
      {
        path: "/pembelian/sudah-konfirmasi",
        element: <ListPembelianSudahKonfirmasi />,
      },
      {
        path: "/pembelian/selesai",
        element: <ListPembelianSelesai />,
      },
      {
        path: "/note/list",
        element: <ListNote />,
      },
    ],
  },
]);

export default router;
