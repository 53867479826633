import { useEffect, useState } from "react";
import { MainPadding } from "../../../styles/Main.styles";
import TableHeader from "../../../components/table/TableHeader";
import { ModalFC } from "../../../components/modal/Modal";
import CreateFormNote from "../form/CreateFormNote";
import { useDeleteNote, useGetNote, useUpdateNotes } from "../network/resolver";
import { INoteData } from "../note.type";
import TableFC, { TableFCBodyProps } from "../../../components/table/Table";
import UpdateFormNote from "../form/UpdateFormNote";
import { Box, Typography } from "@mui/material";
import { InputSelect } from "../../../components/input/input";
import { useUserInformation } from "../../../context/userInformation/UserInformationContext";
import { LoadingButton } from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";
import { FromStringToDate } from "../../../utils/Helper";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ListNote() {
  const { get } = useUserInformation();
  const [showModal, setShowModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [formType, setFormType] = useState<"Create" | "Update" | undefined>(
    undefined
  );
  const [selectedNote, setSelectedNote] = useState<INoteData>();
  const [status, setStatus] = useState(1);
  const [dueDate] = useState("");
  const user = get();
  const { data: dataRawNote, isLoading: loadingNote } = useGetNote({
    due_date: dueDate,
    keyword,
    page,
    status,
    perPage,
  });
  const dataNote: INoteData[] = dataRawNote?.data?.data?.data;
  const { mutate: mutateNote, isLoading: loadingUpdate } = useUpdateNotes();
  const { mutateAsync: mutateDeleteNote, isLoading: loadingDelete } =
    useDeleteNote();
  const header = ["No", "Deadline", "Pesan", "Tujuan", "Action", "Hapus"];
  const [bodyTable, setBodyTable] = useState<TableFCBodyProps[]>([]);

  useEffect(() => {
    if (user) {
      setBodyTable([
        {
          key: "",
          newComponent: (item: INoteData) => {
            return (
              <Box
                onClick={() => {
                  setSelectedNote(item);
                  setFormType("Update");
                  setShowModal(true);
                }}
              >
                <Typography>
                  {item.due_date ? FromStringToDate(item.due_date) : "-"}
                </Typography>
              </Box>
            );
          },
        },
        { key: "message" },
        {
          key: "",
          newComponent: (item: INoteData) => {
            return (
              <Box
                onClick={() => {
                  setSelectedNote(item);
                  setFormType("Update");
                  setShowModal(true);
                }}
              >
                <Typography>
                  {item.targeted_user === null
                    ? "Sendiri"
                    : item.targeted_user.name}
                </Typography>
              </Box>
            );
          },
        },
        {
          key: "",
          newComponent: (item: INoteData) => {
            return <Box>{renderAction(item)}</Box>;
          },
        },
        {
          key: "",
          newComponent: (item: INoteData) => {
            return (
              <Box>
                {user.id === item.created_by.id && (
                  <Box
                    onClick={() => {
                      handleDelete(item.id);
                    }}
                  >
                    <DeleteIcon />
                  </Box>
                )}
              </Box>
            );
          },
        },
      ]);
    }
  }, [user]);

  function renderAction(item: INoteData) {
    if (item.status === 3) return <Typography>Selesai</Typography>;
    else if (
      item.status === 1 &&
      item.created_by?.id === user?.id &&
      item.targeted_user === null
    ) {
      return (
        <LoadingButton
          isLoading={loadingUpdate}
          text={"Selesai"}
          onClick={() => handleUpdateStatus("Selesai", item?.id)}
          variant="outlined"
        ></LoadingButton>
      );
    } else if (item.status === 1 && item.created_by?.id === user?.id) {
      return <Typography>Menunggu</Typography>;
    } else if (item.status === 2 && item.created_by?.id === user?.id) {
      return (
        <LoadingButton
          isLoading={loadingUpdate}
          text={"Selesai"}
          onClick={() => handleUpdateStatus("Selesai", item?.id)}
          variant="outlined"
        ></LoadingButton>
      );
    } else if (item.status === 2 && item.targeted_user?.id === user?.id) {
      return <Typography>Ditanggapi</Typography>;
    } else if (item.status === 1 && item.targeted_user?.id === user?.id) {
      return (
        <LoadingButton
          isLoading={loadingUpdate}
          text={user?.id === item.created_by?.id ? "Selesai" : "Tanggapi"}
          onClick={() =>
            handleUpdateStatus(
              user?.id === item.created_by?.id ? "Selesai" : "Tanggapi",
              item?.id
            )
          }
          variant="outlined"
        ></LoadingButton>
      );
    }
  }

  function handleUpdateStatus(type: "Selesai" | "Tanggapi", id: number) {
    mutateNote({
      note_id: id,
      status: type === "Selesai" ? 3 : 2,
    });
  }

  async function handleDelete(id: number) {
    if (confirm("Apakah anda yakin ?")) {
      await mutateDeleteNote({
        note_id: id,
      });
    }
  }

  if (user === undefined) {
    return <Spinner />;
  }

  return (
    <MainPadding>
      <TableHeader
        title="List Notes"
        filterLabel="Cari Notes"
        searchBar
        addButton
        onSearch={(e) => setKeyword(e)}
        onClickAdd={() => {
          setFormType("Create");
          setShowModal(true);
        }}
        renderFilter={
          <Box marginTop={2}>
            <Box width={250}>
              <InputSelect
                id={"status-filter-33"}
                label="Status"
                options={[
                  { text: "Belum Ditanggapi", value: 1 },
                  { text: "Sudah Ditanggapi", value: 2 },
                  { text: "Selesai", value: 3 },
                ]}
                onChange={(e) => setStatus(e)}
                value={status}
              />
            </Box>
          </Box>
        }
      />
      <TableFC
        loading={loadingNote}
        data={dataNote}
        showIndex
        body={bodyTable as any}
        head={header}
        totalRows={dataRawNote?.data?.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          setSelectedNote(e);
          setFormType("Update");
          setShowModal(true);
        }}
      />
      <ModalFC open={showModal} onClose={() => setShowModal(false)}>
        {formType === "Update" ? (
          <UpdateFormNote
            data={selectedNote}
            onSubmited={() => {
              setShowModal(false);
              setSelectedNote(undefined);
              setFormType("Create");
            }}
          />
        ) : (
          <CreateFormNote onClose={() => setShowModal(false)} />
        )}
      </ModalFC>
    </MainPadding>
  );
}
