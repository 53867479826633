import { Box, TextField } from "@mui/material";
import { usePatchProductStock } from "../network/resolver";
import { LoadingButton } from "../../../components/button/Button";
import { useState } from "react";
import { useToast } from "../../../context/toast/ToastContext";
import { IProductDetail } from "../Produk.type";

interface ITambahStock {
  product?: IProductDetail;
}

export default function TambahStock({ product }: ITambahStock) {
  const toast = useToast();
  const [stock, setStock] = useState<number | undefined>(0);
  const { mutate: mutatePatchStock, isLoading: loadingStock } =
    usePatchProductStock();

  function handleUpdateStock() {
    if (stock === undefined || stock <= 0 || !product) {
      toast.show("Stock harus lebih besar dari 0");
      return;
    }
    mutatePatchStock({
      product_id: product.id,
      stock,
    });
  }

  return (
    <Box>
      <TextField
        label="Stock Saat Ini"
        placeholder="Stock Saat Ini"
        value={product?.stock || 0}
        margin="normal"
        fullWidth
        disabled
      />
      <TextField
        label="Stock"
        placeholder="Stock"
        value={stock}
        onChange={(e) => {
          const value = e.target.value ? parseFloat(e.target.value) : undefined;
          setStock(value);
        }}
        margin="normal"
        fullWidth
        type="number"
      />
      <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
        <LoadingButton
          text="Update"
          onClick={handleUpdateStock}
          style={{ height: 32 }}
          isLoading={loadingStock}
        />
      </Box>
    </Box>
  );
}
