import React, { useEffect } from "react";
import {
  AutoCompleteSelect,
  InputAutocompleteOptions,
} from "../../../components/input/input";
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "../../../components/button/Button";
import { useUpdateNotes } from "../network/resolver";
import { INoteData } from "../note.type";
import { GetCurrentDate } from "../../../utils/Helper";
import { useUserInformation } from "../../../context/userInformation/UserInformationContext";
import Spinner from "../../../components/spinner/Spinner";

interface IUpdateFormNote {
  data?: INoteData;
  onSubmited(): void;
}

type FormType = {
  message: string;
  due_date: any;
  targeted_user: number;
  status: number;
};

export default function UpdateFormNote({ data, onSubmited }: IUpdateFormNote) {
  const { get } = useUserInformation();
  const user = get();
  const { control, setValue } = useForm<FormType>({
    defaultValues: {
      message: "",
      due_date: "",
      targeted_user: 0,
      status: 1,
    },
  });
  const { mutateAsync: mutateUpdate, isLoading: loadingPost } =
    useUpdateNotes();

  useEffect(() => {
    if (data) {
      setValue("due_date", GetCurrentDate(data.due_date));
      setValue("message", data.message);
    }
  }, [data]);

  async function handleUpdateStatus(type: "Selesai" | "Tanggapi", id: number) {
    await mutateUpdate({
      note_id: id,
      status: type === "Selesai" ? 3 : 2,
    });
    onSubmited();
  }

  function renderAction(item: INoteData) {
    if (
      item.status === 1 &&
      item.created_by?.id === user?.id &&
      item.targeted_user === null
    ) {
      return (
        <LoadingButton
          isLoading={loadingPost}
          text={"Selesai"}
          onClick={() => handleUpdateStatus("Selesai", item?.id)}
          variant="outlined"
        ></LoadingButton>
      );
    } else if (item.status === 2 && item.created_by?.id === user?.id) {
      return (
        <LoadingButton
          isLoading={loadingPost}
          text={"Selesai"}
          onClick={() => handleUpdateStatus("Selesai", item?.id)}
          variant="outlined"
        ></LoadingButton>
      );
    } else if (item.status === 1 && item.targeted_user?.id === user?.id) {
      return (
        <LoadingButton
          isLoading={loadingPost}
          text={user?.id === item.created_by?.id ? "Selesai" : "Tanggapi"}
          onClick={() =>
            handleUpdateStatus(
              user?.id === item.created_by?.id ? "Selesai" : "Tanggapi",
              item?.id
            )
          }
          variant="outlined"
        ></LoadingButton>
      );
    }
  }

  if (user === undefined) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Controller
        name="due_date"
        control={control}
        render={({ field }) => (
          <Box marginTop={2} marginBottom={2}>
            <DatePicker
              disabled
              value={field.value}
              label={"Deadline"}
              onChange={(e: any) => {
                const date = `${e.$y}-${e.$M}-${e.$D}`;
                field.onChange(date);
              }}
              format="D-MM-YYYY"
            />
          </Box>
        )}
      ></Controller>
      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <Box marginTop={2}>
            <TextField
              disabled
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
              label="Message"
              placeholder="Message"
              rows={6}
              multiline
            />
          </Box>
        )}
      ></Controller>
      <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
        {data && renderAction(data)}
      </Box>
    </React.Fragment>
  );
}
