import styled from "@emotion/styled";
import { Box } from "@mui/material";

const MainPadding = styled.div`
  padding: 20px;
  @media (max-width: 900px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const MainContainerModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  max-height: 80vh;
  overflow-y: scroll;
`;

const MainContainerWrapper = styled.div`
  background-color: white;
  padding: 20px;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  MainPadding,
  MainContainerModal,
  ButtonContainer,
  MainContainerWrapper,
};
