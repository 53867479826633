import { Box } from "@mui/material";
import { useState } from "react";
import { InputRangeDate } from "../../../components/input/input";
import React from "react";

interface IProps {
  onChange(startDate: string, endDate: string): void;
}

function FilterDate({ onChange }: IProps) {
  const [startLocaleDate, setStartLocaleDate] = useState("");
  const [endLocaleDate, setEndLocaleDate] = useState("");

  function convertToDesireFormat(dateTarget: string) {
    const date = new Date(dateTarget);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  }

  function handleChange(start: string, end: string) {
    const startDate = start ? convertToDesireFormat(start) : "";
    const endDate = end ? convertToDesireFormat(end) : "";
    setStartLocaleDate(start || "");
    setEndLocaleDate(end || "");
    onChange(startDate, endDate);
  }

  return (
    <Box marginTop={2}>
      <InputRangeDate
        startDate={startLocaleDate}
        endDate={endLocaleDate}
        onChange={(start, end) => handleChange(start, end)}
      />
    </Box>
  );
}

const ExtraFilterDate = React.memo(FilterDate)

export default ExtraFilterDate