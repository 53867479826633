import { ENV_LISTS } from "../../core/EnvTranspiler";
import { ContainerImage, ImageStyled } from "./Image.style";

interface ImageProps {
  src: string;
  overideDefault?: boolean;
}

export default function ImageCover({ src, overideDefault }: ImageProps) {
  const serverUrl = ENV_LISTS.API_URL
  const server = overideDefault ? '' : serverUrl
  const imgSrc = `${server}${src}`

  return (
    <ContainerImage>
      <ImageStyled src={imgSrc} />
    </ContainerImage>
  );
}
