import { useCallback } from "react";
import { IAddedProduct } from "./SubmitInvoice";
import { IInvoiceDetail } from "../Invoice.type";
import { ToMoneyFormat } from "../../../utils/Helper";

interface IuseCalculateInvoice {
  listAddedProduct: IAddedProduct[];
  tax: number;
  pph: number;
  dataInvoice?: any;
}

export const useCalculateInvoice = ({
  listAddedProduct,
  pph,
  tax,
  dataInvoice,
}: IuseCalculateInvoice) => {
  const callbackTotalTax = useCallback(() => {
    if (dataInvoice) {
      return dataInvoice.tax;
    }
    let temp = 0;
    listAddedProduct &&
      listAddedProduct.forEach((item) => {
        if (item.quantity) {
          temp += item.price * item.quantity;
        }
      });
    return Math.round(temp * (tax / 100));
  }, [listAddedProduct, tax]);

  const calculateGrandTotal = useCallback(() => {
    if (dataInvoice) {
      return {
        total: dataInvoice.grand_total,
        pph: dataInvoice.pph,
      };
    }
    let total = 0;
    listAddedProduct &&
      listAddedProduct.forEach((item) => {
        if (item.quantity) {
          total += item.price * item.quantity;
        }
      });
    const totalWithPph = Math.round(total - total * (pph / 100));
    const pphTotal = Math.round(total - totalWithPph);
    return {
      total: totalWithPph + callbackTotalTax(),
      pph: pphTotal,
    };
  }, [listAddedProduct, pph, tax]);

  return {
    tax: ToMoneyFormat(callbackTotalTax()),
    pph: ToMoneyFormat(calculateGrandTotal().pph),
    grandTotal: ToMoneyFormat(calculateGrandTotal().total),
  };
};
