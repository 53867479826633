import { Box, Modal, Tab, Tabs } from "@mui/material";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import { useState } from "react";
import HargaKhusus from "./HargaKhusus";
import Form from "./Form";
import { IProductData } from "../Produk.type";
import { useGetProductDetail } from "../network/resolver";
import TambahStock from "./TambahStock";
import HistoryStock from "./HistoryStock";

interface IModalDetail {
  product: IProductData;
  visible: boolean;
  onClose(): void;
}

export default function ModalDetail({
  visible,
  onClose,
  product,
}: IModalDetail) {
  const [selectedTab, setSelectedTab] = useState(0);
  const { data: dataRawProductDetail, isLoading: loadingProductDetail } =
    useGetProductDetail({
      productId: product?.id,
      enabled: product?.id !== undefined,
    });
  const dataProductDetail = dataRawProductDetail?.data?.data;

  return (
    <Modal open={visible} onClose={onClose}>
      <MainContainerModal>
        <MainContainerWrapper>
          <Box width={selectedTab === 2 ? 800 : "100%"}>
            <Tabs
              variant="scrollable"
              value={selectedTab}
              onChange={(e, v) => setSelectedTab(v)}
            >
              <Tab label="Detail Produk" />
              <Tab label="Harga Khusus Customer" />
              <Tab label="History Stock" />
              <Tab label="Tambah Stock" />
            </Tabs>
          </Box>
          {selectedTab === 0 && (
            <Form onComplete={() => {}} product={dataProductDetail} />
          )}
          {selectedTab === 1 && <HargaKhusus product={dataProductDetail} />}
          {selectedTab === 2 && (
            <HistoryStock productId={dataProductDetail?.id} />
          )}
          {selectedTab === 3 && <TambahStock product={dataProductDetail} />}
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
