import { FromStringToDate } from "../../../../../utils/Helper";
import { addTableHeader, breakWord, drawBody } from "../InvoiceGeneratorUtils";
import { IInvoiceDetail } from "../../../Invoice.type";

const PDF_SURAT_JALAN = (data: IInvoiceDetail) => {
  const header = [
    {
      title: "NO",
      style: {
        width: 10,
      },
    },
    {
      title: "NAMA BARANG",
      style: {
        width: 90,
      },
    },
    {
      title: "SAT",
      style: {
        width: 30,
      },
    },
    {
      title: "QTY",
      style: {
        width: 22,
      },
    },
  ];

  // Data Table
  const dataTable: any[] = [];
  data.invoice_detail.forEach((item, index) => {
    let temp: any[] = [];
    temp.push(index + 1); // No
    temp.push(item?.product?.name || "-"); // Nama Barang
    temp.push(item.unit_type); // Satuan
    temp.push(item?.quantity); // Qty
    dataTable.push(temp);
    temp = [];
  });

  // Minimum Rows
  for (let i = dataTable.length; i < 12; i++) {
    let temp: any[] = [];
    temp.push(" ");
    dataTable.push(temp);
  }

  const print = (doc: any) => {
    // Variable
    const docWidth = doc.internal.pageSize.width;
    const docHeight = doc.internal.pageSize.height;
    const currentHeight = 15;
    const colorBlack = "#000000";
    const colorGray = "#4d4e53";
    const fieldTextSize = 10;
    const subLineHeight = 4;
    const tdWidth = (doc.getPageWidth() - 20) / header.length;
    const headerTextSize = 20;
    const labelTextSize = 12;
    const lineHeight = 6;

    const pdfConfig = {
      docWidth,
      docHeight,
      currentHeight,
      colorBlack,
      colorGray,
      fieldTextSize,
      subLineHeight,
      header,
      dataTable,
      tdWidth,
      headerTextSize,
      labelTextSize,
      lineHeight,
    };

    // Header Invoice
    doc.setFontSize(pdfConfig.headerTextSize);
    doc.setTextColor(pdfConfig.colorBlack);
    doc.text(10, currentHeight, "CV. TUNAS JAYA TEKNIK");
    doc.setFontSize(12);
    doc.text(
      pdfConfig.docWidth - 9,
      pdfConfig.currentHeight,
      `TANGGAL : ${FromStringToDate(data?.invoice_date || "")} `,
      "right"
    );
    doc.setFontSize(pdfConfig.fieldTextSize);
    pdfConfig.currentHeight += 5;
    doc.text(10, pdfConfig.currentHeight, "JL DR.RAJIMAN NO. 230 SOLO 57151");
    pdfConfig.currentHeight += 5;
    doc.text(10, pdfConfig.currentHeight, "087812293649");
    doc.setFontSize(12);
    pdfConfig.currentHeight += breakWord(
      data?.customer_name || "",
      doc,
      pdfConfig.currentHeight - 4,
      pdfConfig.docWidth - 12
    );
    if (data?.pre_order_number) {
      doc.text(
        pdfConfig.docWidth - 12,
        pdfConfig.currentHeight,
        data?.pre_order_number || "",
        "right"
      );
      pdfConfig.currentHeight += 5;
    } else {
      pdfConfig.currentHeight += 2;
    }
    doc.setFontSize(pdfConfig.fieldTextSize);
    doc.setFontSize(17);
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight + 2,
      `# ${data.invoice_number}`,
      "right"
    );
    doc.setFontSize(12);
    doc.setTextColor(pdfConfig.colorBlack);
    doc.text(10, pdfConfig.currentHeight + 2, `SURAT JALAN`);
    doc.setFontSize(pdfConfig.fieldTextSize);
    doc.setTextColor(pdfConfig.colorGray);
    pdfConfig.currentHeight += pdfConfig.subLineHeight;
    doc.line(
      10,
      pdfConfig.currentHeight,
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight
    );
    pdfConfig.currentHeight += 1;

    // Table Header
    addTableHeader({ doc: doc, config: pdfConfig });
    doc.line(
      10,
      pdfConfig.currentHeight,
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight
    );

    // Table Body
    doc.setFontSize(pdfConfig.fieldTextSize + 1);
    drawBody({ doc: doc, config: pdfConfig });
    doc.line(
      10,
      pdfConfig.currentHeight,
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight
    );

    // Invoice Tanda Terima
    doc.setFontSize(pdfConfig.fieldTextSize);
    pdfConfig.currentHeight += 21;
    doc.text(10, pdfConfig.currentHeight, "PENERIMA,");
    doc.line(
      10,
      pdfConfig.currentHeight + 15,
      50,
      pdfConfig.currentHeight + 15
    );
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight,
      "HORMAT KAMI,",
      "right"
    );
    doc.line(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight + 15,
      pdfConfig.docWidth - 50,
      pdfConfig.currentHeight + 15
    );
  };

  return { print };
};

export { PDF_SURAT_JALAN };
