import { jsPDF } from "jspdf";
import { PDF_TIKET } from "./invoice-types/PDF_TIKET";
import { PDF_RESMI } from "./invoice-types/PDF_RESMI";
import { PDF_TIDAK_RESMI } from "./invoice-types/PDF_TIDAK_RESMI";
import { PDF_SURAT_JALAN } from "./invoice-types/PDF_SURAT_JALAN";
import { InvoiceType } from "./GenerateInvoice.tpye";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { PDF_RESMI_SURAT_JALAN } from "./invoice-types/PDF_RESMI_SURAT_JALAN";
import { PDF_TIDAK_RESMI_SURAT_JALAN } from "./invoice-types/PDF_TIDAK_RESMI_SURAT_JALAN";
import { PDF_SIMPEL } from "./invoice-types/PDF_SIMPEL";

const OutputType = {
  Save: "save", //save pdf as a file
  DataUriString: "datauristring", //returns the data uri string
  DataUri: "datauri", //opens the data uri in current window
  DataUrlNewWindow: "dataurlnewwindow", //opens the data uri in new window
  Blob: "blob", //return blob format of the doc,
  ArrayBuffer: "arraybuffer", //return ArrayBuffer format
};

export { OutputType, jsPDF };

function generateZiped(data: any, type: InvoiceType) {
  const wrappedData = Array.isArray(data) ? data : [data];
  const zip = new JSZip();
  wrappedData.forEach((item) => {
    const doc: any = new jsPDF({
      compress: true,
      orientation: "portrait",
      unit: "mm",
      format: [215, 330],
    });
    doc.addFont("/pixelpro-2p.ttf", "Arial", "normal");
    doc.setFont("Arial");
    doc.setTextColor("#000000");
    switch (type) {
      case "Tiket":
        PDF_TIKET(item).print(doc);
        break;
      case "Simpel":
        PDF_SIMPEL(item).print(doc);
        break;
      case "Non-Resmi":
        PDF_TIDAK_RESMI(item).print(doc);
        break;
      case "Resmi":
        PDF_RESMI(item).print(doc);
        break;
      case "SuratJalan":
        PDF_SURAT_JALAN(item).print(doc);
        break;
      case "Resmi-SuratJalan":
        PDF_RESMI_SURAT_JALAN(item).print(doc);
        break;
      case "Non-Resmi-SuratJalan":
        PDF_TIDAK_RESMI_SURAT_JALAN(item).print(doc);
        break;
    }
    const pdfBlob = doc.output("blob");
    const filename = item.invoice_number.replaceAll("/", "-");
    zip.file(`Invoice-${filename}.pdf`, pdfBlob);
  });
  zip
    .generateAsync({ type: "blob" })
    .then(function (zipBlob) {
      // Save the Zip file
      saveAs(zipBlob, "invoices.zip");
    })
    .catch(function (error) {
      console.error("Error generating zip file:", error);
    });
}

function generateView(data: any, filename: string, type: InvoiceType) {
  const wrappedData = Array.isArray(data) ? data : [data];
  const doc: any = new jsPDF({
    compress: true,
    orientation: "portrait",
    unit: "mm",
    format: [215, 330],
  });
  wrappedData.forEach((item, index) => {
    doc.addFont("/pixelpro-2p.ttf", "Arial", "normal");
    doc.setFont("Arial");
    doc.setTextColor("#000000");
    switch (type) {
      case "Tiket":
        PDF_TIKET(item).print(doc);
        break;
      case "Simpel":
        PDF_SIMPEL(item).print(doc);
        break;
      case "Non-Resmi":
        PDF_TIDAK_RESMI(item).print(doc);
        break;
      case "Resmi":
        PDF_RESMI(item).print(doc);
        break;
      case "SuratJalan":
        PDF_SURAT_JALAN(item).print(doc);
        break;
      case "Resmi-SuratJalan":
        PDF_RESMI_SURAT_JALAN(item).print(doc);
        break;
      case "Non-Resmi-SuratJalan":
        PDF_TIDAK_RESMI_SURAT_JALAN(item).print(doc);
        break;
    }
    if (wrappedData.length > 1 && index < wrappedData.length - 1) {
      doc.addPage();
    }
  });
  doc.output("dataurlnewwindow", {
    filename,
  });
}

export { generateView, generateZiped };
