import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import {
  AutoCompleteSelect,
  InputAutocompleteOptions,
  NewAutoComplete,
} from "../../../components/input/input";
import { useGetCustomerType } from "../../produk/network/resolver";
import useGenerateOptions from "../../../components/input/hook/useGenerateOptions";
import { useForm, Controller, FieldValues } from "react-hook-form";
import ProductPrice from "../../invoice/component/ProductPrice2";
import { useCalculateInvoice } from "../../invoice/hooks/Calculate";
import { IAddedProduct } from "../../invoice/hooks/SubmitInvoice";
import { useEffect } from "react";
import { LoadingButton } from "../../../components/button/Button";
import { useGetDetailInvoiceRekap } from "../network/resolver";
import { useGenerateAddedProduct } from "../../../utils/useGenerateAddedProduct";
import Spinner from "../../../components/spinner/Spinner";

interface IModalForm {
  isAdd?: boolean;
  invoiceRekapID?: number;
  isLoading: boolean;
  onSubmit(formData: FieldValues): void;
}

type FormType = {
  customer_type_id?: InputAutocompleteOptions | null;
  invoice_recap_number: string;
  shop_name: string;
  customer_name: string;
  tax_percentage: number;
  pph_percentage: number;
  productList: IAddedProduct[];
};

export default function FormRekapInvoice({
  invoiceRekapID,
  isAdd,
  isLoading,
  onSubmit,
}: IModalForm) {
  const { control, watch, setValue, reset } = useForm<FormType>({
    defaultValues: {
      tax_percentage: 0,
      pph_percentage: 0,
      invoice_recap_number: "",
      shop_name: "",
      customer_name: "",
      customer_type_id: null,
    },
  });
  const { data: dataCustomerType } = useGetCustomerType({});
  const { data: optionCustomerType } = useGenerateOptions({
    dataSource: dataCustomerType?.data?.data?.data,
    idKey: "id",
    valueKey: "customer_type_name",
  });
  const { data: dataRawDetailRekap, isLoading: loadingGetDetailRekap } =
    useGetDetailInvoiceRekap({
      invoice_recap_id: invoiceRekapID,
    });
  const dataDetailRekap = dataRawDetailRekap?.data.data;
  const { data: dataProductAdded } = useGenerateAddedProduct(
    dataDetailRekap?.invoice_recap_detail
  );
  const { pph, grandTotal, tax } = useCalculateInvoice({
    listAddedProduct: watch("productList"),
    pph: watch("pph_percentage"),
    tax: watch("tax_percentage"),
    dataInvoice: dataDetailRekap,
  });

  useEffect(() => {
    if (isAdd) {
      reset();
    } else if (dataDetailRekap && !isAdd) {
      const target = optionCustomerType.find(
        (item) => item.id === dataDetailRekap.customer_type_id
      );
      setValue("customer_type_id", target);
      setValue("customer_name", dataDetailRekap.customer_name);
      setValue("invoice_recap_number", dataDetailRekap.invoice_recap_number);
      setValue("shop_name", dataDetailRekap.shop_name);
      setValue("tax_percentage", dataDetailRekap.tax_percentage || 0);
      setValue("pph_percentage", dataDetailRekap.pph_percentage || 0);
    }
  }, [dataDetailRekap, optionCustomerType, isAdd]);

  const handleSubmit = (form: any) => {
    if (!isAdd) {
      const body = form;
      body.productList.map((item: any, index: number) => {
        const temp = dataDetailRekap?.invoice_recap_detail[index];
        item.idRekap = temp?.id;
      });
      onSubmit(form);
      return;
    }
    onSubmit(form);
  };

  if (loadingGetDetailRekap) {
    return (
      <Box p={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      <Box marginTop={3}>
        <Controller
          name="customer_type_id"
          control={control}
          render={({ field }) => (
            <AutoCompleteSelect
              label="Tipe Customer"
              options={optionCustomerType}
              value={field.value}
              defaultValue={field.value}
              onChange={(e: any) => field.onChange(e)}
            />
          )}
        />
      </Box>
      <Box marginTop={3}>
        <Controller
          name="invoice_recap_number"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField
                label="Nomor Invoice Rekap"
                placeholder="Nomor Invoice Rekap"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                fullWidth
              />
              <FormHelperText>
                Pada nota ready, untuk nomor Pre Order yang sama nota akan
                digabung
              </FormHelperText>
            </FormControl>
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="shop_name"
          control={control}
          render={({ field }) => (
            <TextField
              label="Nama Toko"
              placeholder="Nama Toko"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="customer_name"
          control={control}
          render={({ field }) => (
            <TextField
              label="Nama Customer"
              placeholder="Nama Customer"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="tax_percentage"
          control={control}
          render={({ field }) => (
            <TextField
              label="Tax"
              placeholder="Tax"
              value={field.value}
              onChange={(e) => {
                const value = e.target.value ? parseInt(e.target.value) : 0;
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="pph_percentage"
          control={control}
          render={({ field }) => (
            <TextField
              label="Pph"
              placeholder="Pph"
              value={field.value}
              onChange={(e) => {
                const value = e.target.value ? parseInt(e.target.value) : 0;
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="productList"
          control={control}
          render={({ field }) => (
            <ProductPrice
              type={isAdd ? "Add" : "ConfirmRekap"}
              invoice={dataProductAdded}
              customerTypeId={watch("customer_type_id")?.id}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </Box>
      <Box
        marginTop={3}
        display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"}
      >
        <Box marginTop={1} marginBottom={1}>
          <Typography variant="h6">Tax : {tax}</Typography>
        </Box>
        <Box marginTop={1} marginBottom={1}>
          <Typography variant="h6">Pph : {pph}</Typography>
        </Box>
        <Box marginTop={1} marginBottom={1}>
          <Typography variant="h6">Total : {grandTotal}</Typography>
        </Box>
      </Box>
      {isAdd && (
        <Box display={"flex"} justifyContent={"flex-end"} marginTop={1}>
          <LoadingButton
            onClick={() => handleSubmit(control._formValues)}
            isLoading={isLoading}
            text={"Submit"}
          />
        </Box>
      )}
    </Box>
  );
}
