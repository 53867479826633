import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

export const SideBar = styled(Grid)`
  min-height: 100vh;
  background-color: black;
  @media (max-width: 900px) {
    display: none;
  }
`

export const BodyContentDrawer = styled(Box)`
  display: none;
  @media (max-width: 900px) {
    padding: 8px;
    display: inherit;
  }
`