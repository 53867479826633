import { useMutation, useQuery, useQueryClient } from "react-query";
import { HttpBaseRequest } from "../../../core/HttpRequest";
import {
  IPriceList,
  deleteCustomerPrice,
  deleteProduct,
  exportFromFile,
  getCustomerType,
  getProduct,
  getProductDetail,
  getProductHistory,
  importFromFile,
  patchCustomerPrice,
  patchProduct,
  patchStockProduct,
  postAddProduct,
  postCustomerPrice,
} from "./service";
import { useToast } from "../../../context/toast/ToastContext";
import { useNavigate } from "react-router";
import { GetCurrentDate } from "../../../utils/Helper";

export const useGetProduct = ({
  keyword,
  page,
  perPage,
  customer_type_id,
  enabledQuery,
  is_under_threshold,
}: HttpBaseRequest<{
  customer_type_id?: number;
  is_under_threshold: number;
}>) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: `product${page}${keyword}${customer_type_id}${is_under_threshold}${perPage}`,
    //keepPreviousData: true,
    queryFn: () =>
      getProduct({
        keyword,
        perPage,
        page,
        customer_type_id,
        is_under_threshold,
      }),
    enabled: enabledQuery,
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const useGetProductDetail = ({
  productId,
  enabled,
}: {
  productId?: number;
  enabled?: boolean;
}) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: `productDetail${productId}`,
    queryFn: () => getProductDetail({ productId: productId || 0 }),
    enabled,
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const useGetProductHistory = ({
  product_id,
  enabled,
  page,
  perPage,
}: {
  product_id?: number;
  enabled?: boolean;
  page: number;
  perPage: number;
}) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: `productHistory${product_id}${page}${perPage}`,
    queryFn: () =>
      getProductHistory({ product_id: product_id || 0, page, perPage }),
    enabled,
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const useGetCustomerType = ({ keyword }: HttpBaseRequest) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: `customerType${keyword}`,
    queryFn: () => getCustomerType({ keyword }),
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const usePostAddProduct = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      name,
      price,
      stock,
      unit_type,
      buying_price,
      priceList,
      stock_threshold,
    }: {
      name: string;
      price: number;
      stock: number;
      unit_type: string;
      buying_price: number;
      priceList?: IPriceList[];
      stock_threshold: number;
    }) =>
      postAddProduct({
        name,
        stock,
        price,
        unit_type,
        buying_price,
        priceList,
        stock_threshold,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil menambahkan produk baru");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePatchProduct = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      product_id,
      name,
      price,
      stock,
      unit_type,
      buying_price,
      priceList,
      stock_threshold,
    }: {
      product_id: number;
      name: string;
      price: number;
      stock: number;
      unit_type: string;
      buying_price: number;
      priceList?: IPriceList[];
      stock_threshold: number;
    }) =>
      patchProduct({
        product_id,
        name,
        stock,
        price,
        unit_type,
        buying_price,
        priceList,
        stock_threshold,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil update produk");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useDeleteProduct = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id_product }: { id_product: number }) =>
      deleteProduct({ id_product }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil hapus produk");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePatchProductStock = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      product_id,
      stock,
    }: {
      product_id: number;
      stock: number;
    }) => patchStockProduct({ product_id, stock }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil update stock produk");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostAddCustomerPrice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      amount,
      amount_type,
      customer_type_id,
      product_id,
    }: {
      product_id: number;
      customer_type_id: number;
      amount_type: number;
      amount: number;
    }) =>
      postCustomerPrice({
        amount,
        amount_type,
        customer_type_id,
        product_id,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil tambah customer price");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.data || "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePatchCustomerPrice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      product_price_id,
      amount,
      amount_type,
    }: {
      product_price_id: number;
      amount: number;
      amount_type: number;
    }) => patchCustomerPrice({ product_price_id, amount, amount_type }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil update harga khusus customer");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.data || "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useDeleteCustomerPrice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ product_price_id }: { product_price_id: number }) =>
      deleteCustomerPrice({ product_price_id }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil hapus harga khusus customer");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.data || "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useExportFromFile = () => {
  return useMutation({
    mutationFn: () => exportFromFile(),
    onSuccess: (e) => {
      const csv = e.data;
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;

      a.download = `data-product-${GetCurrentDate()}.csv`;
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
  });
};

export const useImportFromFile = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ file }: { file: File }) => importFromFile({ file }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil import file");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.data || "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};
