import { Box, Button, Grid, TextField } from "@mui/material";
import {
  IOptions,
  InputMoney,
  InputSelect,
} from "../../../components/input/input";
import { useEffect, useState } from "react";
import {
  useDeleteCustomerPrice,
  useGetCustomerType,
  usePatchCustomerPrice,
  usePostAddCustomerPrice,
} from "../network/resolver";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useToast } from "../../../context/toast/ToastContext";
import { IProductDetail } from "../Produk.type";
import SelectProductDiscount, {
  PRODUCT_DISCOUNT_OPTIONS,
} from "./SelectProductDiscount";
import { LoadingButton } from "../../../components/button/Button";

interface IHargaKhusus {
  onChange?(item: ICustomeList[]): void;
  product?: IProductDetail;
}

export interface ICustomeList {
  id: number;
  name: string;
  amount: number;
  discountType: number;
}

export default function HargaKhusus({ onChange, product }: IHargaKhusus) {
  const toast = useToast();
  const [keywordCustomerList, setKeywordCustomerList] = useState("");
  const [customerList, setCustomerList] = useState<ICustomeList[]>([]);
  const [amount, setAmount] = useState(0);
  const [discountType, setDiscountType] = useState<IOptions>(
    PRODUCT_DISCOUNT_OPTIONS[0]
  );
  const [optionsCustomerType, setOptionsCustomerType] = useState<IOptions[]>(
    []
  );
  const [selectedCustomer, setSelectedCustomer] = useState<IOptions | null>(
    null
  );
  const { mutate: mutateAdd } = usePostAddCustomerPrice();
  const { mutate: mutatePatch, isLoading: loadingPatch } =
    usePatchCustomerPrice();
  const { mutate: mutateDelete } = useDeleteCustomerPrice();
  const { data: dataRawCustomerType } = useGetCustomerType({
    keyword: keywordCustomerList,
  });
  const dataCustomerType = dataRawCustomerType?.data?.data?.data;

  useEffect(() => {
    if (dataCustomerType) {
      const temp: IOptions[] = [];
      dataCustomerType.map((item) => {
        temp.push({
          value: item.id,
          text: item.customer_type_name,
        });
      });
      if (product) {
        const tempList: ICustomeList[] = [];
        product.product_price.forEach((item) => {
          const customerTypeName = temp.find(
            (itemCus) => itemCus.value === item.customer_type_id
          );
          tempList.push({
            id: item.id,
            name: customerTypeName?.text || "",
            amount: item.price,
            discountType: item.amount_type,
          });
        });
        setCustomerList(tempList);
      }
      setOptionsCustomerType(temp);
    }
  }, [dataCustomerType]);

  useEffect(() => {
    if (onChange) {
      onChange(customerList);
    }
  }, [customerList]);

  function handleAddCustomerPrice() {
    if (!selectedCustomer) {
      toast.show("Mohon lengkapi field");
      return;
    }
    const alreadyExist = customerList.filter(
      (item) => item.id === selectedCustomer.value
    );
    if (alreadyExist.length > 0) {
      toast.show("Tipe customer ini sudah ada");
      return;
    }
    // Insert into DB, since API to add is separate from main
    if (product) {
      mutateAdd({
        amount: amount,
        amount_type: discountType.value,
        customer_type_id: selectedCustomer.value,
        product_id: product.id,
      });
    }
    const temp = [...customerList];
    temp.push({
      id: selectedCustomer.value,
      name: selectedCustomer.text,
      amount: amount,
      discountType: discountType.value,
    });
    setCustomerList(temp);
    setAmount(0);
  }

  function handleChangeCustomerPrice(
    customer: ICustomeList,
    value: number,
    type: "Type" | "Amount"
  ) {
    setCustomerList((prev) =>
      prev.map((item) => {
        if (item.id === customer.id) {
          if (type === "Amount") item.amount = value;
          else if (type === "Type") item.discountType = value;
        }
        return item;
      })
    );
  }

  function handleUpdatePrice(item: ICustomeList) {
    mutatePatch({
      product_price_id: item.id,
      amount: item.amount,
      amount_type: item.discountType,
    });
  }

  function handleDeletePrice(id: number) {
    const temp = customerList.filter((item) => item.id !== id);
    setCustomerList(temp);
    if (product) {
      if (confirm("Apakah anda yakin ?")) {
        mutateDelete({
          product_price_id: id,
        });
      }
    }
  }

  return (
    <Box>
      <Grid
        marginTop={1}
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        height={"100%"}
      >
        <Grid item xs={5}>
          <InputSelect
            label="Pilih Customer"
            options={optionsCustomerType}
            value={selectedCustomer?.value}
            onChange={(e, item) => setSelectedCustomer(item)}
            id={"customer-type-1"}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <SelectProductDiscount onChange={(e) => setDiscountType(e)} />
        </Grid> */}
        <Grid item xs={3}>
          <InputMoney
            label="Harga"
            value={amount}
            onChange={(e) => setAmount(e)}
          />
        </Grid>
        <Grid
          onClick={handleAddCustomerPrice}
          item
          xs={1}
          style={{ cursor: "pointer" }}
        >
          <AddCircleIcon fontSize={"large"} />
        </Grid>
      </Grid>
      {customerList.map((item, index) => {
        return (
          <Box marginTop={3} key={index}>
            <Grid container spacing={1} display={"flex"} alignItems={"center"}>
              <Grid item xs={5}>
                <TextField
                  label="Nama Customer"
                  placeholder="Nama Customer"
                  fullWidth
                  disabled
                  value={item.name}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <SelectProductDiscount
                  onChange={(e) =>
                    handleChangeCustomerPrice(item, e.value, "Type")
                  }
                  discountValue={item.discountType}
                />
              </Grid> */}
              <Grid item xs={3}>
                <InputMoney
                  label="Harga"
                  value={item.amount}
                  onChange={(e) => handleChangeCustomerPrice(item, e, "Amount")}
                />
              </Grid>
              <Grid item xs={1} style={{ cursor: "pointer" }}>
                <Box display={"flex"}>
                  {product && (
                    <CheckCircleIcon
                      fontSize={"large"}
                      color="success"
                      onClick={() => !loadingPatch && handleUpdatePrice(item)}
                    />
                  )}
                  <RemoveCircleIcon
                    fontSize={"large"}
                    color="error"
                    onClick={() => {
                      handleDeletePrice(item.id);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
}
