import { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import TableFC, { TableFCBodyProps } from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import { MainPadding } from "../../../styles/Main.styles";
import ExtraFilterDate from "../component/ExtraFilterDate";
import { useDeleteInvoice, useGetInvoice } from "../network/resolver";
import { useUserInformation } from "../../../context/userInformation/UserInformationContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  useGenerateInvoices,
  useGenerateZipedInvoices,
} from "../hooks/pdf/GenerateInvoice";
import { IInvoiceData } from "../Invoice.type";
import { ModalFC } from "../../../components/modal/Modal";
import FormPreOrder from "../form/FormPreOrder";
import useSubmitInvoice from "../hooks/SubmitInvoice";
import { FromStringToDate, getUrlQuery } from "../../../utils/Helper";
import { useLocation, useParams } from "react-router";
import { ACTION_URL } from "../../../Constant";

export default function PreOrderInvoice() {
  const { idInvoice: idInvoiceUrlParam } = useParams();
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedIdInvoice, setSelectedIdInvoice] = useState<
    number | undefined
  >();
  const [isSelectMultiple, setIsSelectMultiple] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceData[]>([]);
  const { data: dataRawInvoice, isLoading: loadingInvoice } = useGetInvoice({
    invoice_number: keyword,
    start_date: startDate,
    end_date: endDate,
    status: 2,
    page,
    perPage,
  });
  const dataInvoice = dataRawInvoice?.data?.data?.data;
  const { generate, loading: isLoadingGenerate } = useGenerateInvoices({
    invoice: selectedInvoice,
  });
  const { generate: generateZiped, loading: isLoadingGenerateZipped } =
    useGenerateZipedInvoices({
      invoice: selectedInvoice,
    });
  const { mutate: deleteInvoice } = useDeleteInvoice();
  const { addInvoice, splitInvoice, loading, isSuccess } = useSubmitInvoice();
  const userInformation = useUserInformation();
  const [_, setIsAdmin] = useState<boolean | undefined>(undefined);
  const [header, setHeader] = useState<string[]>([
    "No",
    "Nomor Invoice",
    "Nomor PO",
    "Nama Customer",
    "Nama Toko",
    "Tanggal",
    "Total",
  ]);
  const [bodyTable, setBodyTable] = useState<TableFCBodyProps[]>([
    { key: "invoice_number" },
    { key: "pre_order_number" },
    { key: "customer_name" },
    { key: "shop_name" },
    { key: "invoice_date", isDate: true },
    { key: "grand_total", isMoney: true },
  ]);

  useEffect(() => {
    if (idInvoiceUrlParam) {
      const action = getUrlQuery(location.search).get("action");
      if (action && action === ACTION_URL.openModal) {
        setSelectedIdInvoice(parseInt(idInvoiceUrlParam));
        setVisible(true);
      }
    }
  }, [idInvoiceUrlParam]);

  useEffect(() => {
    if (userInformation.get()) {
      const isAdmin = userInformation.get()?.role === 1;
      if (isAdmin) {
        setIsAdmin(true);
        setHeader((prev: any) => {
          if (prev.filter((item: any) => item === "Hapus").length > 0) {
            return prev;
          }
          return [...prev, "Hapus"];
        });
        setBodyTable((prev) => {
          if (prev.filter((item) => item.key === "action").length > 0) {
            return prev;
          }
          return [
            ...prev,
            {
              key: "action",
              show: isAdmin,
              component: (
                <Box marginLeft={1}>
                  <DeleteForeverIcon />
                </Box>
              ),
              onClick: (e) => {
                if (confirm("Apakah anda yakin ?")) {
                  deleteInvoice({ invoice_id: e.id });
                }
              },
            },
          ];
        });
      }
    }
  }, [userInformation.get()]);

  useEffect(() => {
    if (isSuccess) {
      setVisible(false);
    }
  }, [isSuccess]);

  const handleSubmit = async (form: any) => {
    if (isAdd) {
      await addInvoice({
        invoice_date: FromStringToDate(new Date().toISOString(), true),
        listAddedProduct: form.productList,
        customer_type_id: form.customer_type_id.id,
        tax_percentage: form.tax_percentage,
        customer_name: form.customer_name,
        shop_name: form.shop_name,
        is_pre_order: true,
        is_ticket: false,
        pph_percentage: form.pph_percentage,
        invoice_number: form.invoice_number,
      });
    } else {
      if (selectedIdInvoice) {
        const tempListAddedProduct = form.productList.map((item: any) => {
          item.quantity = item.input;
          return item;
        });
        await splitInvoice({
          invoice_id: selectedIdInvoice,
          listAddedProduct: tempListAddedProduct,
          dataInvoice: form.dataDetailInvoice,
        });
      }
    }
    setIsAdd(false);
    setVisible(false);
  };

  return (
    <MainPadding>
      <TableHeader
        title="List Invoice Pre-Order"
        filterLabel="Cari Invoice"
        searchBar
        onSearch={(e) => setKeyword(e)}
        //addButton
        onClickAdd={() => {
          setIsAdd(true);
          setVisible(true);
        }}
        renderFilter={
          <Box>
            <ExtraFilterDate
              onChange={(start, end) => {
                setStartDate(start);
                setEndDate(end);
              }}
            />
            {isSelectMultiple && (
              <Box marginTop={1.5} display={"flex"} gap={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => generate("Tiket")}
                  disabled={isLoadingGenerate}
                >
                  {isLoadingGenerate ? (
                    <CircularProgress size={14} />
                  ) : (
                    "Lihat Invoice"
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => generateZiped("Tiket")}
                  disabled={isLoadingGenerateZipped}
                >
                  {isLoadingGenerateZipped ? (
                    <CircularProgress size={14} />
                  ) : (
                    "Download Invoice"
                  )}
                </Button>
              </Box>
            )}
          </Box>
        }
      />
      <TableFC
        loading={loadingInvoice}
        data={dataInvoice}
        showIndex
        showCheckBox
        body={bodyTable}
        head={header}
        perColumns={perPage}
        totalRows={dataRawInvoice?.data?.data?.total}
        onPageChange={(e) => setPage(e)}
        onPerPageChange={(e) => setPerPage(e)}
        onCellClick={(e) => {
          setSelectedIdInvoice(e.id);
          setVisible(true);
        }}
        onChecked={(e) => {
          setIsSelectMultiple(e.length > 0);
          setSelectedInvoice(e.map((item) => item.value));
        }}
      />
      <ModalFC
        open={visible}
        onClose={() => {
          setSelectedIdInvoice(undefined);
          setIsAdd(false);
          setVisible(false);
        }}
      >
        <FormPreOrder
          id={selectedIdInvoice}
          isLoading={loading}
          onSubmit={(e) => handleSubmit(e)}
          type={isAdd ? "Add" : "Confirm"}
        />
      </ModalFC>
    </MainPadding>
  );
}
