import { IInvoiceData, IInvoiceDetail } from "../../Invoice.type";
import { useState } from "react";
import { generateView, generateZiped, jsPDF } from "./Print";
import { InvoiceType } from "./GenerateInvoice.tpye";
import { useGetDetailInvoices } from "../../network/resolver";
import { PDF_TAGIHAN_KODE } from "./invoice-types/PDF_TAGIHAN_KODE";
import { getDownloadExcel } from "../../network/service";
import { PDF_TAGIHAN_NON_KODE } from "./invoice-types/PDF_TAGIHAN_NON_KODE";

const useGenerateInvoice = ({ invoice }: { invoice?: IInvoiceDetail }) => {
  const [loading, setLoading] = useState(false);
  const generate = (type: InvoiceType) => {
    setLoading(true);
    generateView(invoice, `Invoice - ${invoice?.invoice_number}`, type);
    setLoading(false);
  };

  return {
    loading,
    generate,
  };
};

const useGenerateInvoices = ({ invoice }: { invoice: IInvoiceData[] }) => {
  const idInvoice = invoice.map((item) => item.id);
  const [loading, setLoading] = useState(false);

  const generate = async (type: InvoiceType) => {
    setLoading(true);
    const dataDetailInvoice = await useGetDetailInvoices({
      invoice_id: idInvoice,
    });
    generateView(dataDetailInvoice, "Grouped-Invoice", type);
    setLoading(false);
  };

  return {
    loading,
    generate,
  };
};

const useGenerateZipedInvoices = ({ invoice }: { invoice: IInvoiceData[] }) => {
  const idInvoice = invoice.map((item) => item.id);
  const [loading, setLoading] = useState(false);

  const generate = async (type: InvoiceType) => {
    setLoading(true);
    const dataDetailInvoice = await useGetDetailInvoices({
      invoice_id: idInvoice,
    });
    generateZiped(dataDetailInvoice, type);
    setLoading(false);
  };

  return {
    loading,
    generate,
  };
};

const useGenerateTagihan = () => {
  const [isLoading, setIsLoading] = useState(false);

  const generate = async ({
    ids,
    type,
  }: {
    ids: number[];
    type: "Kode" | "Non";
  }) => {
    setIsLoading(true);
    const dataTagihanRaw = await getDownloadExcel({ ids });
    const dataTagihan = dataTagihanRaw.data.data;
    const doc: any = new jsPDF({
      compress: true,
      orientation: "portrait",
      unit: "mm",
      format: [215, 330],
    });
    doc.addFont("/pixelpro-2p.ttf", "Arial", "normal");
    doc.setFont("Arial");
    doc.setTextColor("#000000");
    type === "Kode"
      ? PDF_TAGIHAN_KODE(dataTagihan).print(doc)
      : PDF_TAGIHAN_NON_KODE(dataTagihan).print(doc);
    const filename = `Tagihan`;
    //doc.output("dataurlnewwindow");
    doc.save(`${filename}.pdf`);
    setIsLoading(false);
  };

  return { isLoading, generate };
};

export {
  useGenerateInvoice,
  useGenerateInvoices,
  useGenerateZipedInvoices,
  useGenerateTagihan,
};
