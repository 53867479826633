import { useState } from "react";
import TableHeader from "../../components/table/TableHeader";
import { MainPadding } from "../../styles/Main.styles";
import { useProductPreOrder } from "./networks/resolver";
import TableFC, { TableFCBodyProps } from "../../components/table/Table";
import ModalProductPreOrder from "./components/Modal";
import { IProductPreOrderData } from "./ProdukPreOrder.type";
import { Box } from "@mui/material";
import { LoadingButton } from "../../components/button/Button";
import { BASE_URL } from "../../core/http-axios-react/constants/Constants";
import { useDownloadBlobAsFile } from "../../hooks/useDownloadBlobAsFile";

export default function ProdukPreOrder() {
  const { download, isLoading: loadingDownlaod } = useDownloadBlobAsFile();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<IProductPreOrderData | null>(null);
  const { data: dataRawProductPreOrder, isLoading: loading } =
    useProductPreOrder({
      keyword,
      page,
      perPage,
    });
  const dataProductPreOrder = dataRawProductPreOrder?.data.data.data;
  const [bodyTable] = useState<TableFCBodyProps[]>([
    { key: "product.name" },
    { key: "product.stock" },
    { key: "quantity" },
  ]);

  return (
    <MainPadding>
      <TableHeader
        title="List Produk Pre Order"
        searchBar
        onSearch={(e) => {
          setPage(1);
          setKeyword(e);
        }}
        filterLabel="Cari Nama Produk"
        renderRight={
          <Box display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              isLoading={loadingDownlaod}
              onClick={() => download(`${BASE_URL}/product/pre_order/export`)}
              text="Export"
            />
          </Box>
        }
      />
      <TableFC
        loading={loading}
        body={bodyTable}
        data={dataProductPreOrder}
        head={["No", "Nama Barang", "Jumlah Stock", "Jumlah"]}
        showIndex
        totalRows={dataRawProductPreOrder?.data?.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        pagginationAt={page}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          setSelectedProduct(e);
          setShowModal(true);
        }}
      />
      <ModalProductPreOrder
        product={selectedProduct}
        onClose={() => setShowModal(false)}
        visible={showModal}
      />
    </MainPadding>
  );
}
