import { useMutation, useQuery, useQueryClient } from "react-query";
import { HttpBaseRequest } from "../../../core/HttpRequest";
import {
  deleteInvoice,
  getDetailInvoice,
  getDownloadExcel,
  getInvoice,
  patchInvoiceStatus,
  postAddInvoice,
  postSplitInvoice,
} from "./service";
import { useToast } from "../../../context/toast/ToastContext";
import { useNavigate } from "react-router";
import { IInvoiceDetail } from "../Invoice.type";

export const useGetInvoice = ({
  invoice_number,
  page,
  perPage,
  start_date,
  end_date,
  status,
}: HttpBaseRequest<{
  invoice_number?: string;
  start_date?: string;
  end_date?: string;
  status?: number;
}>) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: `invoice${invoice_number}${page}${start_date}${end_date}${status}${perPage}`,
    queryFn: () =>
      getInvoice({
        invoice_number,
        page,
        start_date,
        end_date,
        perPage,
        status,
      }),
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const useGetDetailInvoice = ({
  invoice_id,
}: {
  invoice_id?: number;
}) => {
  return useQuery({
    queryKey: `invoiceDetail${invoice_id}`,
    queryFn: () => getDetailInvoice({ invoice_id: invoice_id || 0 }),
    enabled: invoice_id ? true : false,
  });
};

export const useGetDetailInvoices = async ({
  invoice_id,
}: {
  invoice_id: number[];
}) => {
  const results: IInvoiceDetail[] = [];
  for (let item of invoice_id) {
    const temp = await getDetailInvoice({ invoice_id: item });
    const res = temp?.data?.data;
    if (res) {
      results.push(res);
    }
  }
  return results;
};

export const usePostAddInvoie = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: `addInvoice`,
    mutationFn: async ({
      invoice_date,
      productList,
      customer_type_id,
      tax_percentage,
      customer_name,
      shop_name,
      is_pre_order,
      is_ticket,
      pph_percentage,
      invoice_number,
    }: {
      invoice_date: string;
      productList: string;
      customer_type_id: number;
      tax_percentage: number;
      customer_name: string;
      shop_name: string;
      is_ticket?: boolean;
      is_pre_order?: boolean;
      pph_percentage?: number;
      invoice_number: number;
    }) =>
      await postAddInvoice({
        invoice_date,
        productList,
        customer_type_id,
        tax_percentage,
        customer_name,
        shop_name,
        is_pre_order: true,
        is_ticket: false,
        pph_percentage,
        invoice_number,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil menambahkan invoice baru");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePatchStatusInvoice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      invoice_number,
      invoice_id,
      status,
    }: {
      invoice_number: string;
      invoice_id: number;
      status: number;
    }) => patchInvoiceStatus({ invoice_id, status, invoice_number }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil update status invoice");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostSplitInvoice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      invoice_id,
      available_list,
      all_available,
      all_unavailable,
    }: {
      invoice_id: number;
      available_list: string;
      all_available?: boolean;
      all_unavailable?: boolean;
    }) =>
      postSplitInvoice({
        available_list,
        invoice_id,
        all_available,
        all_unavailable,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        const msg =
          typeof e.data.data === "string"
            ? "Berhasil update invoice"
            : "Berhasil melakukan split invoice";
        toast.show(msg);
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useDeleteInvoice = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: "deleteInvoice",
    mutationFn: ({ invoice_id }: { invoice_id: number }) =>
      deleteInvoice({ invoice_id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      toast.show("Berhasil menghapus invoice");
    },
  });
};
