import { useEffect, useState } from "react";
import { MainPadding } from "../../../styles/Main.styles";
import TableHeader from "../../../components/table/TableHeader";
import {
  useGetInvoiceRekap,
  usePostConfirmInvoiceRekap,
  usePostDeleteRekap,
  usePostInvoiceRekap,
} from "../network/resolver";
import TableFC, { TableFCBodyProps } from "../../../components/table/Table";
import { FromStringToDate } from "../../../utils/Helper";
import { ModalFC } from "../../../components/modal/Modal";
import FormRekapInvoice from "../form/FormRekapInvoice";
import { IRekapData } from "../Rekap.type";
import { Box, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUserInformation } from "../../../context/userInformation/UserInformationContext";

export default function ListRekap() {
  const userInfo = useUserInformation();
  const isAdmin = userInfo.get()?.role === 1;
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isAdd, setIsAdd] = useState(false);
  const [showmodal, setShowModal] = useState(false);
  const [selectedRekap, setSelectedRekap] = useState<IRekapData>();
  const {
    mutateAsync: mutateAdd,
    isLoading: loadingPostRekap,
    isSuccess: successAdd,
  } = usePostInvoiceRekap();
  const {
    mutateAsync: mutateConfirm,
    isLoading: loadingConfirmPostRekap,
    isSuccess: successConfirm,
  } = usePostConfirmInvoiceRekap();
  const { mutateAsync: mutateDeleteRekap, isLoading: loadingDelete } =
    usePostDeleteRekap();
  const { data: dataRawRekap, isLoading: loadingRekap } = useGetInvoiceRekap({
    keyword,
    page,
    perPage,
  });
  const dataRekap = dataRawRekap?.data.data.data;
  const isLoading = loadingConfirmPostRekap || loadingPostRekap;
  const [header] = useState<string[]>([
    "No",
    "No Invoice Rekap",
    "Nama Customer",
    "Nama Toko",
    "Tanggal",
    "Total",
    "",
  ]);
  const [bodyTable] = useState<TableFCBodyProps[]>([
    { key: "invoice_recap_number" },
    { key: "customer_name" },
    { key: "shop_name" },
    { key: "invoice_recap_date", isDate: true },
    { key: "grand_total", isMoney: true },
    {
      key: "",
      show: isAdmin,
      newComponent: (e) => {
        return (
          <Box onClick={() => handleOnClick(e.id)}>
            {loadingDelete ? <CircularProgress size={15} /> : <DeleteIcon />}
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    if (successConfirm || successAdd) {
      setShowModal(false);
    }
  }, [successConfirm, successAdd]);

  const handleOnClick = (id: number) => {
    if (confirm("Apakah Anda Yakin ?")) {
      mutateDeleteRekap({ invoice_recap_id: id });
    }
  };

  const handleSubmit = async (form: any) => {
    const productList: any = [];
    form.productList.forEach((item: any) => {
      productList.push({
        idRekap: item.idRekap,
        product_id: item.product_id,
        quantity: item.quantity,
      });
    });
    if (isAdd) {
      await mutateAdd({
        invoice_recap_date: FromStringToDate(new Date().toISOString(), true),
        productList: JSON.stringify(productList),
        customer_type_id: form.customer_type_id.id,
        tax_percentage: form.tax_percentage,
        customer_name: form.customer_name,
        shop_name: form.shop_name,
        invoice_recap_number: form.invoice_recap_number,
        pph_percentage: form.pph_percentage,
      });
    } else {
      if (selectedRekap) {
        const rekapDetails = productList.map((item: any) => {
          return {
            id: item.idRekap,
            quantity: item.quantity,
          };
        });
        await mutateConfirm({
          invoice_recap_id: selectedRekap?.id,
          invoice_date: FromStringToDate(new Date().toISOString(), true),
          invoice_recap_details: JSON.stringify(rekapDetails),
        });
      }
    }
    setIsAdd(false);
    setShowModal(false);
  };

  return (
    <MainPadding>
      <TableHeader
        title="List Rekap"
        searchBar
        addButton={true}
        onClickAdd={() => {
          setIsAdd(true);
          setShowModal(true);
        }}
        onSearch={(e) => setKeyword(e)}
        filterLabel="Cari Rekap"
      />
      <TableFC
        loading={loadingRekap}
        data={dataRekap}
        showIndex
        body={bodyTable}
        head={header}
        totalRows={dataRawRekap?.data?.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          setSelectedRekap(e);
          setShowModal(true);
        }}
      />
      <ModalFC
        open={showmodal}
        onClose={() => {
          setSelectedRekap(undefined);
          setShowModal(false);
          setIsAdd(false);
        }}
      >
        <FormRekapInvoice
          isAdd={isAdd}
          invoiceRekapID={selectedRekap?.id}
          isLoading={isLoading}
          onSubmit={(e) => handleSubmit(e)}
        />
      </ModalFC>
    </MainPadding>
  );
}
