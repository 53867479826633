import { Box, Button } from "@mui/material";
import { IOptions, InputSelect } from "../../../components/input/input";
import { useState } from "react";
import { IInvoiceDetail } from "../Invoice.type";
import { useGenerateInvoice } from "../hooks/pdf/GenerateInvoice";

interface ISelectPrintType {
  invoice: IInvoiceDetail;
  invoicePrintOptions: IOptions[];
}

export default function SelectPrintType({
  invoice,
  invoicePrintOptions,
}: ISelectPrintType) {
  const [selected, setSelected] = useState(invoicePrintOptions[0]);
  const { generate: generatePdfInvoice, loading } = useGenerateInvoice({
    invoice,
  });

  function handleView() {
    switch (selected.value) {
      case 0:
        generatePdfInvoice("Tiket");
        break;
      case 1:
        generatePdfInvoice("Resmi");
        break;
      case 2:
        generatePdfInvoice("Non-Resmi");
        break;
      case 3:
        generatePdfInvoice("SuratJalan");
        break;
      case 4:
        generatePdfInvoice("Resmi-SuratJalan");
        break;
      case 5:
        generatePdfInvoice("Non-Resmi-SuratJalan");
        break;
      case 6:
        generatePdfInvoice("Simpel");
        break;
    }
  }

  return (
    <Box display={"flex"} gap={1}>
      <InputSelect
        id={"invoice-print"}
        label="Jenis Surat"
        options={invoicePrintOptions}
        value={selected.value}
        onChange={(e, item) => setSelected(item)}
      />
      <Button variant="text" onClick={handleView}>
        {loading ? "Load" : "Lihat"}
      </Button>
    </Box>
  );
}
