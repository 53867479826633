import { useEffect, useState } from "react";
import {
  usePatchStatusInvoice,
  usePostAddInvoie,
  usePostSplitInvoice,
} from "../network/resolver";
import { IInvoiceDetail } from "../Invoice.type";

export interface IAddedProduct {
  name: string;
  product_id: number;
  price: number;
  quantity: number | null;
}

const useSubmitInvoice = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    mutateAsync: mutateAddInvoice,
    isLoading: loadingAdd,
    isSuccess: successAdd,
  } = usePostAddInvoie();
  const {
    mutateAsync: mutatePatchStatus,
    isLoading: loaidngPatch,
    isSuccess: successPatch,
  } = usePatchStatusInvoice();
  const {
    mutateAsync: mutateSplitInvoice,
    isLoading: loadingSplit,
    isSuccess: successSplit,
  } = usePostSplitInvoice();
  const loadingGroup = loadingAdd || loadingSplit || loaidngPatch;
  const isSuccess = successAdd || successPatch || successSplit;

  useEffect(() => {
    setLoading(loadingGroup);
  }, [loadingGroup]);

  const addInvoice = async ({
    invoice_date,
    listAddedProduct,
    customer_type_id,
    tax_percentage,
    customer_name,
    shop_name,
    is_pre_order,
    is_ticket,
    pph_percentage,
    invoice_number,
  }: {
    invoice_date: string;
    listAddedProduct: any[];
    customer_type_id: number;
    tax_percentage: number;
    customer_name: string;
    shop_name: string;
    is_ticket?: boolean;
    is_pre_order?: boolean;
    pph_percentage?: number;
    invoice_number: number;
  }) => {
    return await mutateAddInvoice({
      invoice_date,
      productList: JSON.stringify(listAddedProduct),
      customer_type_id,
      tax_percentage,
      customer_name,
      shop_name,
      is_pre_order,
      is_ticket,
      pph_percentage,
      invoice_number,
    });
  };

  const updateStatus = async ({
    invoice_number,
    invoice_id,
    status,
  }: {
    invoice_number: string;
    invoice_id: number;
    status: number;
  }) => {
    return await mutatePatchStatus({
      invoice_number,
      invoice_id,
      status,
    });
  };

  const splitInvoice = async ({
    invoice_id,
    listAddedProduct,
    dataInvoice,
  }: {
    invoice_id: number;
    listAddedProduct: IAddedProduct[];
    dataInvoice: IInvoiceDetail;
  }) => {
    const temp: { invoice_detail_id: number; quantity: number }[] = [];
    listAddedProduct.forEach((item) => {
      const idInvoiceProduct = dataInvoice?.invoice_detail.filter(
        (itemDet) => itemDet.product_id === item.product_id
      );
      temp.push({
        invoice_detail_id: idInvoiceProduct ? idInvoiceProduct[0].id : 0,
        quantity: item.quantity || 0,
      });
    });
    // Check if split condition
    const tempTotalAdded = temp.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
    const tempTotalInvoice = dataInvoice.invoice_detail.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
    const allNotAvailable = tempTotalAdded === 0;
    const allAvailable = tempTotalAdded === tempTotalInvoice;
    return await mutateSplitInvoice({
      invoice_id,
      available_list: JSON.stringify(temp),
      all_available: allAvailable,
      all_unavailable: allNotAvailable,
    });
  };

  return {
    addInvoice,
    updateStatus,
    splitInvoice,
    loading,
    isSuccess,
  };
};

export default useSubmitInvoice;
