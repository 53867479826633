import { Box, TextField, Typography } from "@mui/material";
import {
  AutoCompleteSelect,
  InputAutocompleteOptions,
} from "../../../components/input/input";
import { useGetCustomerType } from "../../produk/network/resolver";
import useGenerateOptions from "../../../components/input/hook/useGenerateOptions";
import { useForm, Controller, FieldValues } from "react-hook-form";
import ProductPrice from "../../invoice/component/ProductPrice2";
import { useCalculateInvoice } from "../../invoice/hooks/Calculate";
import { IAddedProduct } from "../../invoice/hooks/SubmitInvoice";
import { useEffect } from "react";
import { LoadingButton } from "../../../components/button/Button";
import { useGenerateAddedProduct } from "../../../utils/useGenerateAddedProduct";
import Spinner from "../../../components/spinner/Spinner";
import { useGetDetailInvoice } from "../network/resolver";
import SelectPrintType from "../component/SelectPrintType";

interface IModalForm {
  id?: number;
  isLoading: boolean;
  type: "Add" | "Confirm" | "Purchase" | "ConfirmPurchase" | "FinishPurchase";
  onSubmit(formData: FieldValues): void;
}

type FormType = {
  customer_type_id?: InputAutocompleteOptions | null;
  invoice_number: string;
  shop_name: string;
  customer_name: string;
  tax_percentage: number;
  pph_percentage: number;
  productList: IAddedProduct[];
};

export default function FormPreOrder({
  id,
  type,
  isLoading,
  onSubmit,
}: IModalForm) {
  const { control, watch, setValue, reset } = useForm<FormType>({
    defaultValues: {
      tax_percentage: 0,
      pph_percentage: 0,
      invoice_number: "",
      shop_name: "",
      customer_name: "",
      customer_type_id: null,
    },
  });
  const { data: dataCustomerType } = useGetCustomerType({});
  const { data: optionCustomerType } = useGenerateOptions({
    dataSource: dataCustomerType?.data?.data?.data,
    idKey: "id",
    valueKey: "customer_type_name",
  });
  const { data: dataRawDetailInvoice, isLoading: loadingDetailInvoice } =
    useGetDetailInvoice({
      invoice_id: id,
    });
  const dataDetailInvoice = dataRawDetailInvoice?.data.data;
  const { data: dataProductAdded } = useGenerateAddedProduct(
    dataDetailInvoice?.invoice_detail
  );
  const { pph, grandTotal, tax } = useCalculateInvoice({
    listAddedProduct: watch("productList"),
    pph: watch("pph_percentage"),
    tax: watch("tax_percentage"),
    dataInvoice: dataDetailInvoice,
  });

  useEffect(() => {
    if (type === "Add") {
      reset();
    } else if (dataDetailInvoice) {
      const target = optionCustomerType.find(
        (item) => item.id === dataDetailInvoice.customer_type_id
      );
      setValue("customer_type_id", target);
      setValue("customer_name", dataDetailInvoice.customer_name);
      setValue("invoice_number", dataDetailInvoice.invoice_number);
      setValue("shop_name", dataDetailInvoice.shop_name);
      setValue("tax_percentage", dataDetailInvoice.tax_percentage || 0);
      setValue("pph_percentage", dataDetailInvoice.pph_percentage || 0);
    }
  }, [dataDetailInvoice, optionCustomerType]);

  const handleSubmit = (form: any) => {
    const bodyForm = form;
    if (dataDetailInvoice) {
      Object.assign(bodyForm, {
        dataDetailInvoice,
      });
    }
    onSubmit(form);
  };

  if (loadingDetailInvoice) {
    return (
      <Box p={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      {dataDetailInvoice && (
        <Box marginTop={3}>
          <SelectPrintType
            invoice={dataDetailInvoice}
            invoicePrintOptions={[{ text: "Tiket", value: 0 }]}
          />
        </Box>
      )}
      <Box marginTop={3}>
        <Controller
          name="customer_type_id"
          control={control}
          render={({ field }) => (
            <AutoCompleteSelect
              label="Tipe Customer"
              options={optionCustomerType}
              value={field.value}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </Box>
      <Box marginTop={3}>
        <Controller
          name="invoice_number"
          control={control}
          render={({ field }) => (
            <TextField
              label="Nomor Invoice Pre Order"
              placeholder="Nomor Invoice Pre Order"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="shop_name"
          control={control}
          render={({ field }) => (
            <TextField
              label="Nama Toko"
              placeholder="Nama Toko"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="customer_name"
          control={control}
          render={({ field }) => (
            <TextField
              label="Nama Customer"
              placeholder="Nama Customer"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="tax_percentage"
          control={control}
          render={({ field }) => (
            <TextField
              label="Tax"
              placeholder="Tax"
              value={field.value}
              onChange={(e) => {
                const value = e.target.value ? parseInt(e.target.value) : 0;
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="pph_percentage"
          control={control}
          render={({ field }) => (
            <TextField
              label="Pph"
              placeholder="Pph"
              value={field.value}
              onChange={(e) => {
                const value = e.target.value ? parseInt(e.target.value) : 0;
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="productList"
          control={control}
          render={({ field }) => (
            <ProductPrice
              type={type}
              invoice={dataProductAdded}
              customerTypeId={watch("customer_type_id")?.id}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </Box>
      <Box
        marginTop={3}
        display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"}
      >
        <Box marginTop={1} marginBottom={1}>
          <Typography variant="h6">Tax : {tax}</Typography>
        </Box>
        <Box marginTop={1} marginBottom={1}>
          <Typography variant="h6">Pph : {pph}</Typography>
        </Box>
        <Box marginTop={1} marginBottom={1}>
          <Typography variant="h6">Total : {grandTotal}</Typography>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} marginTop={1}>
        <LoadingButton
          onClick={() => handleSubmit(control._formValues)}
          isLoading={isLoading}
          text={"Submit"}
        />
      </Box>
    </Box>
  );
}
