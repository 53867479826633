import { Box, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "../../../components/button/Button";
import {
  InputSelect,
  InputMoney,
  IOptions,
} from "../../../components/input/input";
import { ICustomerTypeData } from "../../produk/Produk.type";
import { useEffect, useState } from "react";
import { useToast } from "../../../context/toast/ToastContext";
import {
  useAddCustomerType,
  useExportFromFileHargaKhusus,
  useImportFromFileHargaKhusus,
  usePatchCustomerType,
} from "../network/resolver";

interface IDetailCustomer {
  customer?: ICustomerTypeData;
  onComplete(): void;
}

export default function DetailCustomer({
  customer,
  onComplete,
}: IDetailCustomer) {
  const toast = useToast();
  const { mutateAsync: mutate, isLoading } = useAddCustomerType();
  const { mutateAsync: mutatePatch, isLoading: loadingPatch } =
    usePatchCustomerType();
  const loading = isLoading || loadingPatch;
  const amountTypeOptions: IOptions[] = [
    { text: "Fixed", value: 1 },
    { text: "Percentage", value: 2 },
  ];
  const [selectedAmountType, setSelectedAmountType] = useState<IOptions>(
    amountTypeOptions[0]
  );
  const [amount, setAmount] = useState(0);
  const [name, setName] = useState("");
  const { mutate: mutateImportFile, isLoading: loadingImport } =
    useImportFromFileHargaKhusus();
  const { mutate: mutateExport, isLoading: loadingExport } =
    useExportFromFileHargaKhusus({ customer_type_id: customer?.id || 0 });

  useEffect(() => {
    setAmount(customer?.amount || 0);
    setName(customer?.customer_type_name || "");
    const tempType = customer?.amount_type
      ? amountTypeOptions.filter(
          (item) => item.value === customer?.amount_type
        )[0]
      : amountTypeOptions[0];
    setSelectedAmountType(tempType);
  }, []);

  async function handleAdd() {
    if (!amount || !name) {
      toast.show("Mohon lengkapi field");
      return;
    }
    if (customer) {
      await mutatePatch({
        customer_type_id: customer.id,
        amount,
        amount_type: selectedAmountType.value,
        customer_type_name: name,
      });
    } else {
      await mutate({
        amount,
        amount_type: selectedAmountType.value,
        customer_type_name: name,
      });
    }
    onComplete();
  }

  return (
    <Box>
      <Box marginBottom={3}>
        <Typography variant="body1" fontWeight={"bold"}>
          {customer ? "Update" : "Tambah"} Customer
        </Typography>
      </Box>
      <Box marginTop={1}>
        <InputSelect
          id={"amount-type"}
          label="Tipe"
          options={amountTypeOptions}
          value={selectedAmountType.value}
          onChange={(e, item) => setSelectedAmountType(item)}
        />
      </Box>
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Nama"
        label="Nama"
        margin="normal"
        fullWidth
      />
      <Box marginTop={1}>
        <InputMoney
          label="Jumlah"
          value={amount}
          onChange={(e) => setAmount(e)}
        />
      </Box>
      <Box gap={2} marginTop={2} display={"flex"} justifyContent={"flex-end"}>
        <LoadingButton
          isLoading={loadingImport}
          isUpload
          accept=".csv"
          text="Upload"
          variant="contained"
          color="success"
          onUpload={(files) => {
            if (files) {
              mutateImportFile({ file: files[0] });
            }
          }}
        />
        <LoadingButton
          isLoading={loadingExport}
          variant="contained"
          color="secondary"
          text="Download"
          onClick={() => mutateExport()}
        />
        <LoadingButton text="Submit" onClick={handleAdd} isLoading={loading} />
      </Box>
    </Box>
  );
}
