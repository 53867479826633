import { Box, TextField, Typography } from "@mui/material";
import { useGetCustomerType } from "../../produk/network/resolver";
import useGenerateOptions from "../../../components/input/hook/useGenerateOptions";
import { useForm, Controller } from "react-hook-form";
import ProductPrice from "../../invoice/component/ProductPrice2";
import { useCalculateInvoice } from "../../invoice/hooks/Calculate";
import { IAddedProduct } from "../../invoice/hooks/SubmitInvoice";
import { useEffect } from "react";
import Spinner from "../../../components/spinner/Spinner";
import { useGenerateAddedProduct } from "../../../utils/useGenerateAddedProduct";
import { useGetDetailPurchaseInvoice } from "../network/resolver";
import {
  AutoCompleteSelect,
  InputAutocompleteOptions,
} from "../../../components/input/input";

interface IModalForm {
  id?: number;
  isLoading: boolean;
  type: "Add" | "Confirm" | "Purchase" | "ConfirmPurchase" | "FinishPurchase";
}

type FormType = {
  purchase_invoice_number: string;
  shop_name: string;
  seller_name: string;
  purchase_invoice_date: string;
  productList: IAddedProduct[];
  tax_percentage: number;
  pph_percentage: number;
  has_tax_invoice: InputAutocompleteOptions | null;
};

export default function FormFinishPurchaseInvoice({ id, type }: IModalForm) {
  const { control, watch, setValue, reset } = useForm<FormType>({
    defaultValues: {
      tax_percentage: 0,
      pph_percentage: 0,
      purchase_invoice_number: "",
      shop_name: "",
      seller_name: "",
      has_tax_invoice: null,
    },
  });
  const { data: dataCustomerType } = useGetCustomerType({});
  const { data: optionCustomerType } = useGenerateOptions({
    dataSource: dataCustomerType?.data?.data?.data,
    idKey: "id",
    valueKey: "customer_type_name",
  });
  const { data: dataRawDetailPurchase, isLoading: loadingGetDetailRekap } =
    useGetDetailPurchaseInvoice({
      purchase_invoice_id: id,
    });
  const dataDetailPurchase = dataRawDetailPurchase?.data.data;
  const { data: dataProductAdded } = useGenerateAddedProduct(
    dataDetailPurchase?.purchase_invoice_detail
  );
  const { pph, grandTotal, tax } = useCalculateInvoice({
    listAddedProduct: watch("productList"),
    pph: watch("pph_percentage"),
    tax: watch("tax_percentage"),
    dataInvoice: dataDetailPurchase,
  });
  const optionFaktur: InputAutocompleteOptions[] = [
    { id: true, label: "Ada" },
    { id: false, label: "Tidak Ada" },
  ];

  useEffect(() => {
    if (type === "Add") {
      reset();
    } else if (dataDetailPurchase) {
      const hasTaxInvoice =
        dataDetailPurchase.has_tax_invoice === 0 ||
        dataDetailPurchase.has_tax_invoice === null
          ? false
          : true;
      const selectedOption = optionFaktur.find(
        (item) => item.id === hasTaxInvoice
      );
      setValue("seller_name", dataDetailPurchase.seller_name);
      setValue(
        "purchase_invoice_number",
        dataDetailPurchase.purchase_invoice_number
      );
      setValue("shop_name", dataDetailPurchase.shop_name);
      setValue("tax_percentage", dataDetailPurchase.tax_percentage || 0);
      setValue("pph_percentage", dataDetailPurchase.pph_percentage || 0);
      setValue("has_tax_invoice", selectedOption || null);
    }
  }, [optionCustomerType, type, dataDetailPurchase]);

  if (loadingGetDetailRekap) {
    return (
      <Box p={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      <Box marginTop={3}>
        <Controller
          name="purchase_invoice_number"
          control={control}
          render={({ field }) => (
            <TextField
              disabled
              label="Nomor Invoice Pembelian"
              placeholder="Nomor Invoice Pembelian"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="shop_name"
          control={control}
          render={({ field }) => (
            <TextField
              disabled
              label="Nama Toko"
              placeholder="Nama Toko"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="seller_name"
          control={control}
          render={({ field }) => (
            <TextField
              disabled
              label="Nama Penjual"
              placeholder="Nama Penjual"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="tax_percentage"
          control={control}
          render={({ field }) => (
            <TextField
              disabled
              label="Tax"
              placeholder="Tax"
              value={field.value}
              onChange={(e) => {
                const value = e.target.value ? parseInt(e.target.value) : 0;
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
      </Box>
      <Box marginTop={2}>
        <Controller
          name="pph_percentage"
          control={control}
          render={({ field }) => (
            <TextField
              disabled
              label="Pph"
              placeholder="Pph"
              value={field.value}
              onChange={(e) => {
                const value = e.target.value ? parseInt(e.target.value) : 0;
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
      </Box>
      {type !== "Add" && (
        <Box marginTop={2}>
          <Controller
            name="has_tax_invoice"
            control={control}
            render={({ field }) => (
              <AutoCompleteSelect
                disabled
                label="Nomor Faktur"
                options={optionFaktur}
                value={field.value}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        </Box>
      )}
      <Box marginTop={2}>
        <Controller
          name="productList"
          control={control}
          render={({ field }) => (
            <ProductPrice
              type={type}
              invoice={dataProductAdded}
              customerTypeId={-1}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </Box>
      {type !== "Add" && (
        <Box>
          <Box
            marginTop={3}
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={"column"}
          >
            <Box marginTop={1} marginBottom={1}>
              <Typography variant="h6">Tax : {tax}</Typography>
            </Box>
            <Box marginTop={1} marginBottom={1}>
              <Typography variant="h6">Pph : {pph}</Typography>
            </Box>
            <Box marginTop={1} marginBottom={1}>
              <Typography variant="h6">Total : {grandTotal}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
