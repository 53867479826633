import { Box, Card, CircularProgress } from "@mui/material";
import { ContainerSpinner } from "./Spinner.styles";

export default function Spinner() {
  return (
    <ContainerSpinner>
      <Card>
        <Box padding={2} bgcolor={'#dedede'}>
          <CircularProgress size={30} />
        </Box>
      </Card>
    </ContainerSpinner>
  );
}
