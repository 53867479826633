import {
  Box,
  Collapse,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { BodyContentDrawer, SideBar } from "./Layout.styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import {
  Person,
  ExpandLess,
  ExpandMore,
  LogoutOutlined,
  ShoppingCart,
  Event,
  Inventory2,
  EditNote,
} from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router";
import Guard from "../../router/Guard";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import ReceiptIcon from "@mui/icons-material/Receipt";
import StoreIcon from "@mui/icons-material/Store";
import { useUserInformation } from "../../context/userInformation/UserInformationContext";

interface IpathExt {
  text: string;
  path: string;
  icon: ReactJSXElement;
  hide?: boolean;
}
interface IPath {
  text: string;
  path: string;
  icon: ReactJSXElement;
  expand?: boolean;
  hide?: boolean;
  ext?: IpathExt[];
}

export default function Layout() {
  const navigate = useNavigate();
  const userInformation = useUserInformation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [navigationPath, setNavigationPath] = useState<IPath[]>([]);

  useEffect(() => {
    if (userInformation.get()) {
      setNavigationPath([
        {
          text: "Note",
          path: "/note/list",
          icon: <EditNote style={{ color: "#aeaeae" }} />,
        },
        {
          text: "User",
          path: "/user",
          icon: <Person style={{ color: "#aeaeae" }} />,
          hide: userInformation.get()?.role !== 1,
        },
        {
          text: "Customer",
          path: "/customer",
          icon: <StoreIcon style={{ color: "#aeaeae" }} />,
        },
        {
          text: "Produk",
          path: "/produk",
          icon: <ShoppingCart style={{ color: "#aeaeae" }} />,
          ext: [
            {
              icon: <ShoppingCart style={{ color: "#aeaeae" }} />,
              path: "/produk",
              text: "List Produk",
            },
            {
              icon: <ShoppingCart style={{ color: "#aeaeae" }} />,
              path: "/produk/pre-order",
              text: "List Pre-Order",
            },
          ],
        },
        {
          text: "Rekap",
          path: "/rekap",
          icon: <Event style={{ color: "#aeaeae" }} />,
          ext: [
            {
              icon: <Event style={{ color: "#aeaeae" }} />,
              path: "/rekap",
              text: "List Rekap",
            },
          ],
        },
        {
          text: "Invoice",
          path: "/invoice",
          icon: <ReceiptIcon style={{ color: "#aeaeae" }} />,
          ext: [
            {
              icon: <ReceiptIcon style={{ color: "#aeaeae" }} />,
              path: "/invoice/pre-order",
              text: "Preparation",
            },
            {
              icon: <ReceiptIcon style={{ color: "#aeaeae" }} />,
              path: "/invoice/belum-lunas",
              text: "Ready",
            },
            {
              icon: <ReceiptIcon style={{ color: "#aeaeae" }} />,
              path: "/invoice/lunas",
              text: "Delivered",
            },
          ],
        },
        {
          text: "Pembelian",
          path: "/pembelian",
          icon: <Inventory2 style={{ color: "#aeaeae" }} />,
          ext: [
            {
              icon: <Inventory2 style={{ color: "#aeaeae" }} />,
              path: "/pembelian/belum-konfirmasi",
              text: "Belum Konfirmasi",
            },
            {
              icon: <Inventory2 style={{ color: "#aeaeae" }} />,
              path: "/pembelian/sudah-konfirmasi",
              text: "Sudah Konfirmasi",
              hide: userInformation.get()?.role !== 1,
            },
            {
              icon: <Inventory2 style={{ color: "#aeaeae" }} />,
              path: "/pembelian/selesai",
              text: "Selesai",
              hide: userInformation.get()?.role !== 1,
            },
          ],
        },
      ]);
    }
  }, [userInformation.get()]);

  function handleOnClick(path: string, ext?: any) {
    if (ext) {
      setNavigationPath((prev) =>
        prev.map((item) => {
          if (item.path === path) {
            item.expand = !item.expand;
          }
          return item;
        })
      );
      return;
    }
    navigate(path);
    setOpenDrawer(false);
  }

  function handleLogout() {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  }

  const ListItemMenu = () => {
    return (
      <List component="nav">
        {navigationPath.map((item, index) => {
          return item.hide ? null : (
            <div key={index}>
              <ListItemButton
                style={{ color: "#aeaeae" }}
                onClick={() => handleOnClick(item.path, item.ext)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                <div>
                  {item.ext ? (
                    item.expand ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </div>
              </ListItemButton>
              {item.ext && item.expand && (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.ext.map((itemExt, indexExt) => {
                      return itemExt.hide ? null : (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={indexExt + index}
                          onClick={() => handleOnClick(itemExt.path)}
                        >
                          <ListItemIcon>{itemExt.icon}</ListItemIcon>
                          <ListItemText
                            style={{ color: "#aeaeae" }}
                            primary={itemExt.text}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
        <ListItemButton style={{ color: "#aeaeae" }} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined style={{ color: "#aeaeae" }} />,
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    );
  };

  return (
    <Guard>
      <Grid container sx={{ backgroundColor: "#ededed" }} minHeight={"100vh"}>
        <SideBar item xs={0} md={3} lg={2} xl={2}>
          <ListItemMenu />
        </SideBar>
        <Grid item xs={12} md={9} lg={10} xl={10}>
          <Box
            bgcolor="white"
            padding={2}
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <BodyContentDrawer>
              <Box
                onClick={() => setOpenDrawer(true)}
                style={{ cursor: "pointer" }}
              >
                <MenuIcon />
              </Box>
            </BodyContentDrawer>
            <Box>
              <Typography variant="h5" fontWeight="bold">
                Welcome, {userInformation.get()?.name}
              </Typography>
            </Box>
          </Box>
          <Outlet />
        </Grid>
        <Drawer
          anchor={"left"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box width={280} bgcolor={"black"} height={"100%"}>
            <ListItemMenu />
          </Box>
        </Drawer>
      </Grid>
    </Guard>
  );
}
