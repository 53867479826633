import { Box } from "@mui/material";
import { IOptions, InputSelect } from "../../../components/input/input";
import { useState } from "react";

interface ISelectPrintType {
  invoicePrintOptions: IOptions[];
  onChange(options: IOptions): void;
}

export default function SelectPrintOptions({
  invoicePrintOptions,
  onChange,
}: ISelectPrintType) {
  const [selected, setSelected] = useState(invoicePrintOptions[0]);

  return (
    <Box display={"flex"} gap={1}>
      <InputSelect
        id={"invoice-print"}
        label="Jenis Surat"
        options={invoicePrintOptions}
        value={selected.value}
        onChange={(e, item) => {
          setSelected(item);
          onChange(item);
        }}
      />
    </Box>
  );
}
