import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { postLogin } from "./service";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { useToast } from "../../../context/toast/ToastContext";
import { ENV_LISTS } from "../../../core/EnvTranspiler";
import { TokenStorage } from "../../../core/http-axios-react/http/Token";

export const useAuthenticate = () => {
  const toast = useToast();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: "login",
    mutationFn: ({ email, password }: { email: string; password: string }) =>
      postLogin({ email, password }),
    onSuccess: async (e) => {
      if (!e.data.data) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else {
        TokenStorage.set({
          value: e.data.data.token,
        });
        TokenStorage.set({
          key: ENV_LISTS.KEY_USER_INFO,
          value: e.data.data.user,
        });
        navigate("/note/list");
        window.location.reload();
      }
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.error_message ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
