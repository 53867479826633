import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { IPurchaseDetailData, IPurchaseInvoice } from "../pembelian.type";

export const getPurchaseInvoice = async ({
  customer_type_id,
  page,
  perPage,
  start_date,
  end_date,
  keyword,
  status,
}: HttpBaseRequest<{
  customer_type_id?: number;
  start_date?: string;
  end_date?: string;
  keyword?: string;
  status: number;
}>) => {
  const results = await Http.get<HttpBaseResponse<IPurchaseInvoice>>({
    url: "/invoice/purchase",
    params: {
      customer_type_id,
      page,
      perPage,
      start_date,
      end_date,
      keyword,
      status,
    },
  });
  return results;
};

export const getDetailPurchaseInvoice = async ({
  purchase_invoice_id,
}: {
  purchase_invoice_id: number;
}) => {
  const results = await Http.get<HttpBaseResponse<IPurchaseDetailData>>({
    url: "invoice/purchase/detail",
    params: {
      purchase_invoice_id,
    },
  });
  return results;
};

export const postPurchaseInvoice = async ({
  pph_percentage,
  productList,
  purchase_invoice_date,
  purchase_invoice_number,
  seller_name,
  shop_name,
  tax_percentage,
}: {
  purchase_invoice_date: string;
  productList: string;
  tax_percentage: number;
  seller_name: string;
  shop_name: string;
  purchase_invoice_number: string;
  pph_percentage: number;
}) => {
  const results = await Http.post({
    url: "invoice/purchase/create",
    data: {
      pph_percentage,
      productList,
      purchase_invoice_date,
      purchase_invoice_number,
      seller_name,
      shop_name,
      tax_percentage,
    },
  });
  return results;
};

export const postConfirmPurchaseInvoice = async ({
  purchase_invoice_id,
  purchase_invoice_detail,
  has_tax_invoice,
  pph_percentage,
  tax_percentage,
}: {
  purchase_invoice_id: number;
  purchase_invoice_detail: string;
  has_tax_invoice?: boolean;
  tax_percentage: number;
  pph_percentage: number;
}) => {
  const results = await Http.post({
    url: "/invoice/purchase/approve",
    useFormMultiPart: false,
    data: {
      purchase_invoice_id,
      purchase_invoice_detail,
      has_tax_invoice,
      pph_percentage,
      tax_percentage,
    },
  });
  return results;
};

export const postFinishPurchaseInvoice = async ({
  purchase_invoice_id,
  has_tax_invoice,
}: {
  purchase_invoice_id: number;
  has_tax_invoice?: boolean;
}) => {
  const results = await Http.post({
    url: "/invoice/purchase/finish",
    useFormMultiPart: false,
    data: {
      purchase_invoice_id,
      has_tax_invoice,
    },
  });
  return results;
};

export const postDeletePurchaseInvoice = async ({
  purchase_invoice_id,
}: {
  purchase_invoice_id: number;
}) => {
  const results = await Http.post({
    url: "/invoice/purchase/delete",
    useFormMultiPart: false,
    data: {
      purchase_invoice_id,
    },
  });
  return results;
};
