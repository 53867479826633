import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteNote, getNotes, postNotes, updateNotes } from "./service";
import { useNavigate } from "react-router";
import { useToast } from "../../../context/toast/ToastContext";

export const useGetNote = ({
  page,
  due_date,
  keyword,
  status,
  perPage,
}: {
  page: number;
  keyword: string;
  due_date: string;
  status: number;
  perPage: number;
}) => {
  const navigate = useNavigate();

  return useQuery({
    queryKey: ["note", page, due_date, keyword, status, perPage],
    queryFn: async () =>
      await getNotes({ page, due_date, keyword, status, perPage }),
    onSuccess: (e: any) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const usePostNotes = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      due_date,
      message,
      targeted_user,
    }: {
      message: string;
      due_date: string;
      targeted_user: number;
    }) => postNotes({ due_date, message, targeted_user }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil menambahkan Notes baru");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useUpdateNotes = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ note_id, status }: { note_id: number; status: number }) =>
      updateNotes({ note_id, status }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil update Notes");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const useDeleteNote = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ note_id }: { note_id: number }) => deleteNote({ note_id }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil delete Note");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      } else if (e.data.statusCode === 101) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};
