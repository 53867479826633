import { Modal } from "@mui/material";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import { ICustomerTypeData } from "../../produk/Produk.type";
import DetailCustomer from "./DetailCustomer";

interface IModalCustomer {
  customer?: ICustomerTypeData;
  visible: boolean;
  onClose(): void;
}

export default function ModalCustomer({
  customer,
  visible,
  onClose,
}: IModalCustomer) {
  return (
    <Modal open={visible} onClose={onClose}>
      <MainContainerModal minWidth={600}>
        <MainContainerWrapper>
          <DetailCustomer
            onComplete={() => onClose()}
            customer={customer}
          ></DetailCustomer>
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
