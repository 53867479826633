import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getDetailInvoiceRekap,
  getInvoiceRekap,
  postConfirmInvoiceRekap,
  postDeleteRekap,
  postInvoiceReakp,
} from "./service";
import { useNavigate } from "react-router";
import { useToast } from "../../../context/toast/ToastContext";

export const useGetInvoiceRekap = ({
  page,
  customer_type_id,
  end_date,
  keyword,
  start_date,
  perPage,
}: {
  page?: number;
  customer_type_id?: number;
  start_date?: string;
  end_date?: string;
  keyword?: string;
  perPage: number;
}) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: `invoice-rekap-${customer_type_id}-${page}-${keyword}-${perPage}`,
    queryFn: () =>
      getInvoiceRekap({
        customer_type_id,
        end_date,
        keyword,
        start_date,
        page,
        perPage,
      }),
    onSuccess: (e) => {
      if (e.data.error_message === "Token has expired!") {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
    },
  });
};

export const useGetDetailInvoiceRekap = ({
  invoice_recap_id,
}: {
  invoice_recap_id?: number;
}) => {
  return useQuery({
    queryKey: ["detail-rekap", invoice_recap_id],
    queryFn: () =>
      getDetailInvoiceRekap({ invoice_recap_id: invoice_recap_id || 0 }),
    enabled: invoice_recap_id !== undefined,
  });
};

export const usePostConfirmInvoiceRekap = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: `confirmInvoiceRekap`,
    mutationFn: ({
      invoice_date,
      invoice_recap_details,
      invoice_recap_id,
    }: {
      invoice_recap_id: number;
      invoice_date: string;
      invoice_recap_details: string;
    }) =>
      postConfirmInvoiceRekap({
        invoice_date,
        invoice_recap_details,
        invoice_recap_id,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil confirm invoice rekap");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostInvoiceRekap = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: `invoiceRekap`,
    mutationFn: ({
      customer_name,
      customer_type_id,
      invoice_recap_date,
      invoice_recap_number,
      pph_percentage,
      productList,
      shop_name,
      tax_percentage,
    }: {
      invoice_recap_date: string;
      productList: string;
      customer_type_id: number;
      tax_percentage: number;
      customer_name: string;
      shop_name: string;
      invoice_recap_number: string;
      pph_percentage: number;
    }) =>
      postInvoiceReakp({
        customer_name,
        customer_type_id,
        invoice_recap_date,
        invoice_recap_number,
        pph_percentage,
        productList,
        shop_name,
        tax_percentage,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil menambahkan invoice rekap");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};

export const usePostDeleteRekap = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: `deleteInvoiceRekap`,
    mutationFn: ({ invoice_recap_id }: { invoice_recap_id: number }) =>
      postDeleteRekap({
        invoice_recap_id,
      }),
    onSuccess: (e) => {
      if (e.data.statusCode === 200) {
        toast.show("Berhasil delete invoice rekap");
        queryClient.invalidateQueries();
      } else if (e.data.statusCode === 400) {
        const msg =
          e.data.error_message ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      }
    },
  });
};
