import { Box } from "@mui/material";
import { IOptions, InputSelect } from "../../../components/input/input";
import { useEffect, useState } from "react";
import { IInvoiceDetail } from "../Invoice.type";

// status tiket -> split
// status pre-order -> split
// status blm lunas -> lunas
// split buat tiket-pre jadi blm lunas

// tiket
// pre-order
// blm lunas
// lunas

interface ISelectStatusInvoice {
  invoice?: IInvoiceDetail;
  onChange(item: IOptions): void;
}

export const STATUS_INVOICE_OPTIONS: IOptions[] = [
  { text: "Langsung", value: 1 },
  { text: "Pre-Order", value: 2 },
  { text: "Belum Lunas", value: 3 },
  { text: "Lunas", value: 4 },
  { text: "Split", value: 5 },
];

export default function SelectStatusInvoice({
  invoice,
  onChange,
}: ISelectStatusInvoice) {
  const [selected, setSelected] = useState(STATUS_INVOICE_OPTIONS[0]);
  const [dataOptions, setDataOptions] = useState<IOptions[]>(
    STATUS_INVOICE_OPTIONS
  );

  useEffect(() => {
    if (!invoice) {
      setDataOptions((prev) => prev.filter((item) => item.value <= 2));
    } else {
      setDataOptions(STATUS_INVOICE_OPTIONS);
      const temp = STATUS_INVOICE_OPTIONS.filter(
        (item) => item.value === invoice.status
      );
      setSelected(temp[0]);
    }
  }, [invoice]);

  return (
    <Box>
      <InputSelect
        id={"status-invoice"}
        label="Tipe Nota"
        options={dataOptions}
        value={selected.value}
        onChange={(e, item) => {
          setSelected(item);
          onChange(item);
        }}
      />
    </Box>
  );
}
