import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { LoginAuthProps } from "../Login.type";

export const postLogin = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const result = await Http.post<HttpBaseResponse<LoginAuthProps>>({
    url: "/login",
    data: {
      email,
      password,
    },
  });
  return result;
};
