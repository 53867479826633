import { ENV_LISTS } from "../../EnvTranspiler";
import { LOCAL_STORAGE_KEY } from "../constants/Constants";

const get = (key?:string) => {
  let value = null;
  const keyStorage = key || LOCAL_STORAGE_KEY;
  const temp = localStorage.getItem(keyStorage);
  if (temp) {
    value = JSON.parse(temp);
  }
  return value;
};

const set = ({ value, key }: { value: any; key?: string }) => {
  const keyStorage = key || ENV_LISTS.KEY_TOKEN;
  localStorage.setItem(keyStorage, JSON.stringify(value));
};

const remove = (key:string) => {
  localStorage.removeItem(key);
};

const clear = () => {
  localStorage.clear();
};

export const TokenStorage = {
  get,
  set,
  remove,
  clear,
};
