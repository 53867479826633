import { useEffect, useState } from "react";
import TableFC, { TableFCBodyProps } from "../../../components/table/Table";
import { MainPadding } from "../../../styles/Main.styles";
import {
  useGetPurchaseInvoice,
  usePostConfirmPurchaseInvoice,
  usePostDeletePurchaseInvoice,
  usePostPurchaseInvoice,
} from "../network/resolver";
import TableHeader from "../../../components/table/TableHeader";
import { ModalFC } from "../../../components/modal/Modal";
import FormPembelian from "../form/FormPurchaseInvoice";
import { IPurchaseInvoiceData } from "../pembelian.type";
import { FromStringToDate } from "../../../utils/Helper";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ListPembelianBelumKonfirmasi() {
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isAdd, setIsAdd] = useState(false);
  const [showmodal, setShowModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<IPurchaseInvoiceData>();
  const { data: dataRawPurchase, isLoading: loadingPurchase } =
    useGetPurchaseInvoice({ keyword, page, status: 1, perPage });
  const dataPurchase = dataRawPurchase?.data.data.data;
  const {
    mutate: mutateAdd,
    isLoading: loadingAdd,
    isSuccess: successAdd,
  } = usePostPurchaseInvoice();
  const {
    mutate: mutateConfirm,
    isLoading: loadingConfirm,
    isSuccess: successConfirm,
  } = usePostConfirmPurchaseInvoice();
  const { mutateAsync: mutateDelete } = usePostDeletePurchaseInvoice();
  const [header] = useState<string[]>([
    "No",
    "No Invoice Pembelian",
    "Nama Penjual",
    "Nama Toko",
    "Tanggal",
    "Total",
    "Hapus",
  ]);
  const [bodyTable] = useState<TableFCBodyProps[]>([
    { key: "purchase_invoice_number" },
    { key: "seller_name" },
    { key: "shop_name" },
    { key: "purchase_invoice_date", isDate: true },
    { key: "grand_total", isMoney: true },
    {
      key: "",
      newComponent: (item: IPurchaseInvoiceData) => {
        return (
          <Box
            onClick={() => {
              handleDelete(item.id);
            }}
          >
            <DeleteIcon />
          </Box>
        );
      },
    },
  ]);
  const isLoading = loadingPurchase || loadingAdd || loadingConfirm;

  useEffect(() => {
    if (successConfirm || successAdd) {
      setShowModal(false);
    }
  }, [successConfirm, successAdd]);

  async function handleDelete(id: number) {
    if (confirm("Apakah anda yakin ?")) {
      await mutateDelete({
        purchase_invoice_id: id,
      });
    }
  }

  const handleSubmit = (form: any) => {
    const productList: any = [];
    setIsAdd(false);
    form.productList.forEach((item: any) => {
      productList.push({
        product_id: item.product_id,
        quantity: item.quantity,
      });
    });
    if (isAdd) {
      mutateAdd({
        purchase_invoice_date: FromStringToDate(new Date().toISOString(), true),
        productList: JSON.stringify(productList),
        tax_percentage: form.tax_percentage,
        seller_name: form.seller_name,
        shop_name: form.shop_name,
        purchase_invoice_number: form.purchase_invoice_number,
        pph_percentage: form.pph_percentage,
      });
    } else if (selectedInvoice) {
      const purchaseInvoiceDetail = form.productList.map((item: any) => {
        return {
          id: item.idPurchase,
          price: item.price,
        };
      });
      const bodyForm = {
        purchase_invoice_id: selectedInvoice?.id,
        purchase_invoice_detail: JSON.stringify(purchaseInvoiceDetail),
        tax_percentage: form.tax_percentage,
        pph_percentage: form.pph_percentage,
      };
      const hasFaktur =
        form?.has_tax_invoice !== null && form?.has_tax_invoice?.id !== null;
      if (hasFaktur) {
        Object.assign(bodyForm, {
          has_tax_invoice: form.has_tax_invoice.id,
        });
      }
      mutateConfirm(bodyForm);
    }
  };

  return (
    <MainPadding>
      <TableHeader
        title="List Pembelian"
        searchBar
        addButton={true}
        onClickAdd={() => {
          setIsAdd(true);
          setShowModal(true);
        }}
        onSearch={(e) => setKeyword(e)}
        filterLabel="Cari Rekap"
      />
      <TableFC
        loading={loadingPurchase}
        data={dataPurchase}
        showIndex
        showCheckBox
        body={bodyTable}
        head={header}
        totalRows={dataRawPurchase?.data?.data?.total}
        perColumns={perPage}
        onPerPageChange={(e) => setPerPage(e)}
        onPageChange={(e) => setPage(e)}
        onCellClick={(e) => {
          setSelectedInvoice(e);
          setShowModal(true);
        }}
      />
      <ModalFC
        open={showmodal}
        onClose={() => {
          setSelectedInvoice(undefined);
          setShowModal(false);
          setIsAdd(false);
        }}
      >
        <FormPembelian
          type={isAdd ? "Purchase" : "ConfirmPurchase"}
          id={selectedInvoice?.id}
          isLoading={isLoading}
          onSubmit={(e) => handleSubmit(e)}
        />
      </ModalFC>
    </MainPadding>
  );
}
