export const HISTORY_STATUS = {
  rejected: -1,
  waiting: 0,
  process: 1,
  sending: 2,
  completed: 3,
};

export const USER_TYPE = {
  user: {
    name: "user",
    code: 80,
  },
  admin: {
    name: "admin",
    code: 90,
  },
};

export const STATUS_USER = {
  active: {
    name: "active",
    code: 0,
  },
  suspend: {
    name: "suspend",
    code: -1,
  },
};

export const ACTION_URL = {
  openModal: "open_modal",
};
