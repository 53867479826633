import React, { useState } from "react";

interface IUserLogin {
  id: number;
  name: string;
  username: string;
  email: string;
  role: number;
  status: number;
}

interface UserInformationContextProps {
  get: () => IUserLogin | undefined;
  set: (param: IUserLogin) => void;
}

const userInformationContextDefault: UserInformationContextProps = {
  get: () => {
    return {} as IUserLogin;
  },
  set: () => {},
};

export const UserInformationStateContext =
  React.createContext<UserInformationContextProps>(
    userInformationContextDefault
  );

const UserInformationContextFC: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [data, setData] = useState<IUserLogin>();

  function set(param: IUserLogin) {
    setData(param);
  }

  function get() {
    return data;
  }

  return (
    <UserInformationStateContext.Provider value={{ set, get }}>
      {children}
    </UserInformationStateContext.Provider>
  );
};

export const useUserInformation = () =>
  React.useContext(UserInformationStateContext);

export default UserInformationContextFC;
