import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Pagination,
  Box,
  Card,
  Typography,
  CircularProgress,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ContainerPaggination } from "../../features/user/style/User.styles";
import { FromStringToDate, ToMoneyFormat } from "../../utils/Helper";
import ImageCover from "../image/Image";
import { SearchOff } from "@mui/icons-material";

interface IIsMap {
  from: any;
  to: any;
}
export interface TableFCBodyProps {
  key: string;
  isImage?: boolean;
  isMoney?: boolean;
  isDate?: boolean;
  isMap?: IIsMap[];
  component?: React.JSX.Element;
  newComponent?(item: any): React.JSX.Element;
  show?: boolean;
  onClick?(item: any): void;
}

export interface TableHeadProps {
  title: string;
  show: boolean;
}

interface TableFCProps {
  head: string[] | TableHeadProps[];
  body2?(item: any): TableFCBodyProps[];
  body: TableFCBodyProps[];
  data: any[] | undefined | null;
  showIndex?: boolean;
  showCheckBox?: boolean;
  totalRows?: number;
  perColumns?: number;
  loading?: boolean;
  children?: JSX.Element | JSX.Element[];
  pagginationAt?: number;
  onPageChange?(page: number): void;
  onCellClick?(item: any): void;
  onChecked?(item: any[]): void;
  onPerPageChange?(perPage: number): void;
}

const TableFC = ({
  body,
  body2,
  head,
  data,
  showIndex,
  showCheckBox,
  totalRows,
  perColumns = 10,
  pagginationAt,
  children,
  loading,
  onPageChange,
  onCellClick,
  onChecked,
  onPerPageChange,
}: TableFCProps) => {
  const [perColumn, setPerColumn] = useState(10);
  const [pagginationLength, setPagginationLength] = useState(0);
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState<{ index: number; value: any }[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const getChecked = useCallback(
    (index: number) => {
      const isExist = checked.find((item) => item.index === index);
      return isExist !== undefined;
    },
    [checked, allChecked]
  );

  useEffect(() => {
    if (pagginationAt) {
      setPage(pagginationAt);
    }
  }, [pagginationAt]);

  useEffect(() => {
    if (totalRows) {
      const calculateRows =
        totalRows > 0 ? Math.ceil(totalRows / perColumns) : 0;
      setPagginationLength(calculateRows);
    }
  }, [totalRows]);

  useEffect(() => {
    onChecked && onChecked(checked);
  }, [checked]);

  function handleClickCell(item: TableFCBodyProps, value: any) {
    if (item.onClick) {
      item.onClick(value);
    } else if (onCellClick) {
      onCellClick(value);
    }
  }

  function handleClickPaggination(index: number) {
    setPage(index);
    if (onPageChange) {
      onPageChange(index);
    }
  }

  function getIndex(index: number) {
    if (page === 1) {
      return index;
    }
    return index + (page - 1) * perColumns;
  }

  function accessNestedObject(obj: any, path: string) {
    const keys = path.split(".");
    let current = obj;

    for (const key of keys) {
      if (current && current.hasOwnProperty(key)) {
        current = current[key];
      } else {
        return "-"; // Property does not exist
      }
    }

    return current;
  }

  function renderText(condition: TableFCBodyProps, key: string, data: any) {
    const text = accessNestedObject(data, key);
    if (text) {
      if (condition.isDate) {
        return FromStringToDate(text);
      } else if (condition.isMoney) {
        const temp = parseInt(text);
        return ToMoneyFormat(temp);
      } else if (condition.isMap) {
        const temp = condition.isMap.filter((item) => item.from === text);
        const value = (temp && temp[0].to) || text;
        return value;
      }
    }
    return text;
  }

  function renderCell(itemChild: any, item: any) {
    if (itemChild.key === "") return null;
    const results = renderText(itemChild, itemChild.key, item);
    return (
      <TableCell onClick={() => handleClickCell(itemChild, item)}>
        {results}
      </TableCell>
    );
  }

  function renderHead(itemHeads: string[] | TableHeadProps[]) {
    const temp: any = [];
    itemHeads.forEach((item, index) => {
      const isString = typeof item === "string";
      const title = isString ? item : item.title;
      if (isString || (!isString && item.show)) {
        temp.push(<TableCell key={index}>{title}</TableCell>);
      } else if (!isString && item.show !== false) {
        temp.push(<TableCell key={index}>{title}</TableCell>);
      }
    });
    return temp;
  }

  function handleOnChecked(index: number, value: any) {
    let temp = [...checked];
    const isExist = temp.find((item) => item.index === index);
    if (isExist) {
      temp = temp.filter((item) => item.index !== index);
      setChecked(temp);
      return;
    }
    temp.push({
      index,
      value,
    });
    setChecked(temp);
  }

  function handleAllChecked(value: boolean) {
    const temp: { index: number; value: any }[] = [];
    if (value) {
      data?.forEach((item, index) => {
        temp.push({ index: index, value: item });
      });
    }
    setChecked(temp);
    setAllChecked(value);
  }

  if (data && data.length === 0) {
    return (
      <Card>
        <Box
          display={"flex"}
          justifyContent={"center"}
          padding={2}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <SearchOff fontSize={"large"} />
          <Box marginTop={2}>
            <Typography variant="body1">
              Tidak dapat menemukan data yang dicari
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {showCheckBox && (
                <TableCell padding="normal">
                  <Checkbox
                    color="primary"
                    value={allChecked}
                    onChange={(e, v) => handleAllChecked(v)}
                  />
                </TableCell>
              )}
              {renderHead(head)}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item, index) => {
                return (
                  <TableRow key={index} style={{ cursor: "pointer" }}>
                    {showCheckBox && (
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={getChecked(index)}
                          onChange={() => handleOnChecked(index, item)}
                        />
                      </TableCell>
                    )}
                    {showIndex && (
                      <TableCell
                        onClick={() => handleClickCell({ key: "index" }, item)}
                      >
                        {getIndex(index + 1)}
                      </TableCell>
                    )}
                    {body.map((itemChild, indexChild) => {
                      return (
                        <Fragment key={indexChild}>
                          {itemChild.show !== false && itemChild.isImage && (
                            <TableCell
                              onClick={() => handleClickCell(itemChild, item)}
                            >
                              <div style={{ width: 150, height: 150 }}>
                                <ImageCover src={item[itemChild.key]} />
                              </div>
                            </TableCell>
                          )}
                          {itemChild.show !== false && itemChild.component && (
                            <TableCell
                              onClick={() => handleClickCell(itemChild, item)}
                            >
                              {itemChild.component}
                            </TableCell>
                          )}
                          {itemChild.show !== false &&
                            itemChild.newComponent && (
                              <TableCell>
                                {itemChild.newComponent(item)}
                              </TableCell>
                            )}
                          {itemChild.show !== false &&
                            !itemChild.component &&
                            !itemChild.isImage &&
                            renderCell(itemChild, item)}
                        </Fragment>
                      );
                    })}
                    {children && <TableCell>{children}</TableCell>}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {loading && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            padding={1}
          >
            <CircularProgress size={20} />
          </Box>
        )}
      </TableContainer>
      {pagginationLength > 0 && (
        <ContainerPaggination>
          <Pagination
            onChange={(e, index) => {
              handleClickPaggination(index);
              setAllChecked(false);
              setChecked([]);
            }}
            count={pagginationLength}
            variant="outlined"
            shape="rounded"
          />
          <Box position={"absolute"} right={"0%"} top={"10%"} paddingRight={2}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                variant="standard"
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={perColumn}
                onChange={(e: any) => {
                  const temp = e.target.value ? parseInt(e.target.value) : 10;
                  if (temp) {
                    setPerColumn(temp);
                    onPerPageChange && onPerPageChange(temp);
                  }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </ContainerPaggination>
      )}
    </div>
  );
};

export default TableFC;
