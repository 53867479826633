import styled from "@emotion/styled";

export const ContainerImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`